export const colors = {
    lightBlue : "#74c7ce",
    coreBlue : "#3ba9b6",
    mediumBlue : "#29818c",
    darkBlue : "#19666e",
    lightGreen : "#c1dc7f",
    coreGreen : "#a5cf4c",
    navy : "#3468a8",
    red : "#eb6f53",
    orange : "#f68b1e",
    yellow : "#ffd200",
    white : "#ffffff",
    lightGray1 : "#e6e6e6",
    lightGray2 : "#d2d2d2",
    lightGray3 : "#c2c2c2",
    mediumGray1 : "#b2b2b2",
    mediumGray2 : "#939393",
    mediumGray3 : "#828282",
    mediumGray4 : "#737373",
    darkGrey1 : "#505050",
    darkGrey2 : "#414141",
    gradFoot : "#01618D",
    dark : "#033049"

}

export const fonts = {
    'family' : `
        font-family: 'Montserrat', sans-serif;
    `
}
import { h, Component } from 'preact';
import style from './MobileMap.css';
import { navigation } from './../data';

export default class MobileMenu extends Component {
	
	constructor(){
        super();
        this.clickHandler = this.clickHandler.bind(this);
		this.state = {
			
		}
	}

	componentWillMount(){
	
	}

	componentDidMount(){
        
	}	


    clickHandler(stateSelection){
        //throttle this to prevent double taps
        let curTime = Date.now();
		if(this.lastTrigger){
			if(curTime - this.lastTrigger  < 300){
				this.lastTrigger = curTime;
				return;
			} 
		}
        this.lastTrigger = curTime;
        
        if(this.props.selectedState==stateSelection){
            this.props.selectState('')
        } else {
            this.props.selectState(stateSelection)
        }
    }
	
	render() {
        


        const renderMenu = ()=>{
            return navigation.routes.map((route)=>{

                const menuItemInner = ()=>{
                    let c = [style.menuItemInner];
                    if(this.props.selectedState === route.url){ c.push(style.menuItemInnerOpen) };
                    return c.join(" ");
                }

                const arrow = ()=>{
                    let c = [style.arrow];
                    if(this.props.selectedState === route.url){ c.push(style.arrowUp) };
                    return c.join(" ");
                }

                const renderSubMenu = ()=>{
                    return route.routes.map((r)=>{
                        return <a class={style.subMenuItem} href={r.url} target="_self"  onClick={()=>{if(MAP_ANALYTICS_CALL){ MAP_ANALYTICS_CALL(r.ref) }}} >{r.title}</a>
                    })
                }
                return (
                    <div class={style.menuItem}>
                        <div class={menuItemInner()}>
                            <div class={style.menuTop} onTouchStart={(e)=>{e.preventDefault(); this.clickHandler(route.url)}} onClick={(e)=>{e.preventDefault(); this.clickHandler(route.url)}}>
                                <span style="margin-right:10px;">{route.title}</span>

                                <div class={arrow()}>
                                    <svg class={style.arrowSvg}  viewBox="0 0 12 20">
                                    <path  d="M11.37,9.52,2.55.7a.7.7,0,0,0-1,0l-.95,1a.67.67,0,0,0,0,1L8,10,.63,17.38a.69.69,0,0,0,0,1l1,.95a.67.67,0,0,0,.49.2.65.65,0,0,0,.48-.2l8.82-8.81a.7.7,0,0,0,.2-.49A.67.67,0,0,0,11.37,9.52Z" fill="#fff" style="stroke: rgb(255, 255, 255); stroke-width: 2;"></path></svg>
                                </div>
                            </div>
                            <div class={style.menuBottom}>
                                <div class="sub-menu-wrap">
                                    {renderSubMenu()}
                                </div>
                                <div class="content"></div>
                            </div>
                        </div>
                    </div>
                )
            })
        }

		return (
            <div class={style.menu}>
                {renderMenu()}
            </div>
		);
	}
}

import { h, Component, Fragment } from 'preact';
import classNames from 'classnames';
import style from './style.css';
import gsap from 'gsap';
import {navigation} from './../data';

export default class ToolTip extends Component {
	
	constructor(){
        super();
        this.setRef = this.setRef.bind(this);
	
		this.state = {
			selected : null,
			activeTrigger : null
        }
        this.content = {};
	}

	componentWillMount(){
        navigation.routes.map((route)=>{
            route.routes.map((subRoute)=>{
                this.content[subRoute.ref] = {
                        parent : route.ref,
                        content : subRoute.content,
                        x : (subRoute.pos) ? subRoute.pos[0] : 0,
                        y : (subRoute.pos) ? subRoute.pos[1] : 0,
                        toolTipPlacement : subRoute.toolTipPlacement
                }
            })
        });
	}

	componentDidMount(){
        
        // setTimeout(()=>{
        //     this.setRef('backhaulMillimeter')
        // }, 100)
        
	}	

	componentWillReceiveProps(newProps){

	}

	

	componentDidUpdate(){
		
	}

    setRef(trigger){
        // console.log('toolip', trigger, this.content[trigger]);


        this.setState({
            selected : this.content[trigger] || null
        })
        
    }


	
	render() {

        if(!this.state.selected) {
            return null;
        }
        
        let s = this.state.selected;

        const getLocalStyles = ()=>{
            if(!this.state.selected) return { left : '0%', top: '0%'};

            if(!this.state.selected.x){
                return { left : '50%', top: '50%'};
            }


            let lStyles = {
                left : ((this.state.selected.x /1660 ) * 100)+"%",
                top : ((this.state.selected.y /780) * 100)+"%",
            };
            
            return {...lStyles}
        }

        

        const getClasses = ()=>{
            let classes = [
                style.ToolTip
                
            ];
            // console.log('this.state.selected', this.state.selected);

            if(this.state.selected.toolTipPlacement=='right'){
                classes.push(style.ToolTipRight)
            }


            if(this.state.selected.toolTipPlacement=='left'){
                classes.push(style.ToolTipLeft)
            }


            if(this.state.selected.toolTipPlacement=='aboveLeft'){
                classes.push(style.ToolTipAboveLeft)
            }

            if(this.state.selected.toolTipPlacement=='aboveRight'){
                classes.push(style.ToolTipAboveRight)
            }

            if(this.state.selected.toolTipPlacement=='belowLeft'){
                classes.push(style.ToolTipBelowLeft)
            }
            
            

            // if(this.state.selected.x > 1000) {
            //     classes.push(style.ToolTipRight)
            // } else {
            //     classes.push(style.ToolTipLeft)
            // }

            // if(this.state.selected.y > 350) {
            //     classes.push(style.ToolTipTop)
            // } else {
            //     classes.push(style.ToolTipBottom)
            // }
           
            if(this.state.selected && this.state.selected.parent){
                classes.push(style[this.state.selected.parent]);
            }
            return classes.join(" ");
        }
		return (
        <div class={style.tooltipWrap}>
            <div class={getClasses()} style={getLocalStyles()} innerHTML={this.state.selected.content} >   
                
            </div>
        </div>
		)



	}
}

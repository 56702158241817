import * as PIXI from 'pixi.js';
import gsap from 'gsap';
import PixiPlugin from 'gsap/PixiPlugin';
import MotionPathPlugin from 'gsap/MotionPathPlugin'
import parse from 'parse-svg-path';
import abs from 'abs-svg-path';
import normalize from 'normalize-svg-path';

import { navigation } from '../data';
gsap.registerPlugin(PixiPlugin, MotionPathPlugin);
window.PIXI = PIXI


export const drawPath = (path, graphicsObject, color, width, start, end, reverse, close)=>{
    let s = start || 0;
    let e = end || 1;
    let w = width || 4;
    // let f = MotionPathPlugin.stringToRawPath(path);


    // console.log('f', f[0]);

    // let fb = f.reverse();
    // console.log('f=re', fb[0]);
    let rawPathArray = MotionPathPlugin.sliceRawPath(MotionPathPlugin.stringToRawPath(path), s, e);
    
    // drawPath(animatable.path, localRoutes ,blue, animatable.width, 0, animatable.progress, animatable.reverse );
   
    // MotionPathPlugin.getPositionOnPath(path, e, true);


    // if(s < 0.1){
    //     console.log
    //     let pos = MotionPathPlugin.getPositionOnPath(path, e, false);
    //     console.log('pos', pos);
    // }

    if(rawPathArray.length){
        let points = rawPathArray[0];
        graphicsObject.lineStyle(width, color, 1);

        let startX = points.shift();
        let startY = points.shift();
        graphicsObject.moveTo(startX, startY);
       
        while(points.length){
            graphicsObject.bezierCurveTo(points.shift(), points.shift(), points.shift(), points.shift(), points.shift(), points.shift());
            // graphicsObject.lineTo(points.shift(), points.shift());
        }

       
        
      
        graphicsObject.endFill();
    }

    return graphicsObject;
}


export const drawPathCanvas = (path, graphicsObject, color, width, start, end, reverse, close)=>{
    let s = start || 0;
    let e = end || 1;
    let w = width || 4;
    // let f = MotionPathPlugin.stringToRawPath(path);

    
    // console.log('f', f[0]);

    // let fb = f.reverse();
    // console.log('f=re', fb[0]);
    let rawPathArray = MotionPathPlugin.sliceRawPath(MotionPathPlugin.stringToRawPath(path), s, e);
    
    let point = MotionPathPlugin.getPositionOnPath(rawPathArray, e, true);
    graphicsObject.beginFill(0xe74c3c);
    graphicsObject.drawCircle(point.x, point.y, width/2); //

    // drawPath(animatable.path, localRoutes ,blue, animatable.width, 0, animatable.progress, animatable.reverse );
   
    // MotionPathPlugin.getPositionOnPath(path, e, true);


    // if(s < 0.1){
    //     console.log
    //     let pos = MotionPathPlugin.getPositionOnPath(path, e, false);
    //     console.log('pos', pos);
    // }

    // if(rawPathArray.length){
    //     let points = rawPathArray[0];
    //     graphicsObject.lineStyle(width, color, 1);

    //     let startX = points.shift();
    //     let startY = points.shift();
    //     graphicsObject.moveTo(startX, startY);
       
    //     while(points.length){
    //         graphicsObject.bezierCurveTo(points.shift(), points.shift(), points.shift(), points.shift(), points.shift(), points.shift());
    //         // graphicsObject.lineTo(points.shift(), points.shift());
    //     }

       
        
      
    //     graphicsObject.endFill();
    // }
    graphicsObject.endFill();
    return graphicsObject;
}



export const drawSmoothPath = (path, graphicsObject, color, width, start, end, reverse, close)=>{
    let s = start || 0;
    let e = end || 1;
    let w = width || 4;
    // let f = MotionPathPlugin.stringToRawPath(path);
    // console.log('f', f[0]);

    // let fb = f.reverse();
    // console.log('f=re', fb[0]);
    let rawPathArray = MotionPathPlugin.sliceRawPath(MotionPathPlugin.stringToRawPath(path), s, e);
    
    if(rawPathArray.length){
        let points = rawPathArray[0];
        graphicsObject.lineStyle(width, color, 1);

        let startX = points.shift();
        let startY = points.shift();
        graphicsObject.moveTo(startX, startY);
       
        while(points.length){
            graphicsObject.bezierCurveTo(points.shift(), points.shift(), points.shift(), points.shift(), points.shift(), points.shift());
        }

        if(close){
            graphicsObject.lineTo(startX, startY);
        };
        
      
        graphicsObject.endFill();
    }

    return graphicsObject;
}

export const drawPathHole = (path, graphicsObject, close)=>{
   
    let rawPathArray = MotionPathPlugin.sliceRawPath(MotionPathPlugin.stringToRawPath(path), 0, 1);
    
    if(rawPathArray.length){
        let points = rawPathArray[0];
        graphicsObject.moveTo(points.shift(), points.shift());
        while(points.length){
            graphicsObject.bezierCurveTo(points.shift(), points.shift(), points.shift(), points.shift(), points.shift(), points.shift());
            // graphicsObject.quadraticCurveTo(points.shift(), points.shift(), points.shift(), points.shift());
        }
    }
    graphicsObject.closePath();

    return graphicsObject;
}




const elementMap = {
    tower1 : { sprite : 'tower-1.png', xOffset : 2, yOffset : 2, rayLeft : true , rayRight : true , pingScale : 0.4, rayLeftLargeYOffset :-15, rayRightLargeYOffset : -15, rayRightYOffset : -12, rayLeftYOffset : -12},
    tower2 : { sprite : 'Tower2.png', xOffset : 0, yOffset : 0, rayL : 0, pingScale : 0.4, rayRight : true, rayRightYOffset : -4, rayRightLargeYOffset : -9,  rayLeftYOffset : -4, rayLeftLargeYOffset : -9  },
    tower3 : { sprite : 'Tower3.png', xOffset : 0, yOffset : 0, rayL : 0, pingScale : 0.4, rayLeft : true, rayLeftYOffset : -7, rayLeftLargeYOffset : -10, rayRightYOffset : -7, rayRightLargeYOffset : -10 },
    tower4 : { sprite : 'Tower4.png', xOffset : 0, yOffset : 0, rayR : 0, pingScale : 0.4, rayRight : true, rayRightLargeYOffset : -8, rayRightYOffset : -8, rayLeftLargeYOffset : -8, rayLeftYOffset : -8 },
    tower5 : { sprite : 'Tower5.png', xOffset : 6, yOffset : 3, pingScale : 0.4, rayRightLargeYOffset : -8, rayLeftLargeYOffset : -8, rayRightYOffset : -5, rayLeftYOffset : -5 },
    tower6 : { sprite : 'Tower6.png', xOffset : 0, yOffset : 0, rayR : 0, pingScale : 0.4, rayLeft : true,rayRightYOffset : -5, rayLeftYOffset : -5, rayLeftLargeYOffset : -8, rayRightLargeYOffset : -8 },
    tower7 : { sprite : 'Tower7v.png', xOffset : 0, yOffset : 0, pingScale : 0.4, rayRight : true, rayRightYOffset : -4, rayLeftYOffset: -4, rayRightLargeYOffset : -8, rayLeftLargeYOffset : -8  },
    tower8 : { sprite : 'Tower8.png', xOffset : 0, yOffset : 0, pingScale : 0.4, rayLeft: true, rayLeftLargeYOffset : -8, rayRightLargeYOffset : -8, rayLeftYOffset: -6, rayRightYOffset : -6 },
    tower9 : { sprite : 'Tower9.png', xOffset : 0, yOffset : 0, pingOffsetY : 5, pingScale : 0.4, rayLeft : true, rayRight : true, rayLeftYOffset : 10, rayRightYOffset : 10, rayLeftLargeYOffset : 10, rayRightLargeYOffset : 10 },
    sNode : { sprite : 'single-node.png', xOffset : -1, yOffset : 5 },
    stNode : { sprite : 'stacked-node.png', xOffset : -8, yOffset : 1, spriteXOffset : 8, spriteYOffset : 2 },
    cShop : { sprite : 'CoffeeShop.png', xOffset : 2, yOffset : 5, pingOffsetY : -15, pingOffsetX : -3 },
    bOne : { sprite : 'Building1.png', xOffset : 1, yOffset : 2, },
    bTwo : { sprite : 'server-room.png', xOffset : -6, yOffset : -3 },
    bThree : { sprite : 'Building3.png', xOffset : -2, yOffset : 10, pingOffsetY : -20, pingOffsetX : -10, pingScale : 0.8 },
    satellite : { sprite : 'satellite.png', xOffset : 0, yOffset : 0, pingOffsetX : 25, pingOffsetY : -30 },
    cloud : { sprite : 'cloud.png', xOffset : 0, yOffset : 0 },
    satelliteDish : { sprite : 'SatelliteDish.png', xOffset : 0, yOffset : 0 },
    radioMast : { sprite : 'RadioMast.png', xOffset : 0, yOffset : 0, rayLeft : true, rayRight : true, rayLeftLargeYOffset : 18, rayRightLargeYOffset : 5, rayLeftYOffset : 20, rayRightYOffset : 10 },
    coreTower : { sprite : null, xOffset : 0, yOffset : 0, pingScale : 0.3 },
    coreSNode : { sprite : null,  xOffset : 0, yOffset : 0, spriteXOffset : 0, spriteYOffset : 6, pingScale : 0.3 },
    coreStNode : { sprite : null,  xOffset : 0, yOffset : 0, spriteXOffset : 10, spriteYOffset : 3, pingScale : 0.3 },
    coreTower9 : { sprite : null,  xOffset : -4, yOffset : -4, spriteXOffset : 0, spriteYOffset : -3, pingScale : 0.3 },
    ping : { sprite : null,  xOffset : -4, yOffset : -4, spriteXOffset : 0, spriteYOffset : -3, pingScale : 0.3 },
    coreRadioMast : { sprite : null,  xOffset : 0, yOffset : 0, rayL : 0 , rayR : 0 ,spriteXOffset : 0, pingOffsetY:2,  pingOffsetX : 2, spriteYOffset : 0, pingScale : 0.3 },
    
}

export const createElement = (sheet, name, x, y, texture)=>{

    if(!elementMap[name]){
        return console.error(`No element on the map for ${name}`);

    }

    switch(name){
        case 'ping':
            return createPing(sheet,  elementMap[name], x, y)
        case 'satellite':
            return createSatelitte(sheet, elementMap[name], x, y, texture);
        case 'cloud':
            return createCloud(sheet, elementMap[name], x, y, texture);
        case 'coreRadioMast':
            return createStartupRadioMast(sheet, elementMap[name], x, y, texture);
        case 'cShop':
            return createCShop(sheet, elementMap[name], x, y, texture);
        default:
            return createTower(sheet, elementMap[name], x, y, texture);
    }
    

}

export const createPing = (sheet, settingsInput,  inX, inY)=>{
    
    let settings = {
        pingOffsetY : 0,
        pingOffsetX : 0,
        pingScale: 0.5,
        ...settingsInput
    }
    const container = new PIXI.Container();
    let x = inX+settings.xOffset;
    let y = inY+settings.yOffset;

    let  midPulse = new PIXI.AnimatedSprite(sheet.animations.midPulse);
    let  endPulse = new PIXI.AnimatedSprite(sheet.animations.endPulse);
    endPulse.tint =  PIXI.utils.rgb2hex([255/255, 166/255, 0/255])

    midPulse.loop = false;
    midPulse.x= x + settings.pingOffsetX;
    midPulse.y=y-5 + settings.pingOffsetY;
    midPulse.anchor.x = 0.5;
    midPulse.anchor.y = 0.5;
    midPulse.scale.y = settings.pingScale;
    midPulse.scale.x = settings.pingScale;


    endPulse.loop = false;
    endPulse.x= x + settings.pingOffsetX;
    endPulse.y=y-5 + settings.pingOffsetY;
    endPulse.anchor.x = 0.5;
    endPulse.anchor.y = 0.5;
    endPulse.scale.y = settings.pingScale;
    endPulse.scale.x = settings.pingScale;

    container.addChild(midPulse);
    container.addChild(endPulse);

    container.reset = ()=>{
        midPulse.gotoAndStop(0);    
        endPulse.gotoAndStop(0);
    }


    container.playLarge = ()=>{
        endPulse.gotoAndPlay(0);
    }

    container.play = ()=>{
        midPulse.gotoAndPlay(0);
    }

    container.ping = (large)=>{
        
        let tl = gsap.timeline()
        tl.add(container.reset, 0)
        if(large){
            tl.add(container.playLarge,0.1)
        } else {
            tl.add(container.play, 0.1)
        }

        return tl;

    }
    return container;
}

export const createTower = (sheet, settingsInput, inX, inY, texture)=>{
    
    let settings = {
        
        rayRight : false,
        rayLeft : false,
        rayLeftYOffset : 0,
        rayRightYOffset : 0,
        rayLeftLargeYOffset : 0,
        rayRightLargeYOffset : 0,
        spriteXOffset : 0,
        spriteYOffset : 0,
        pingOffsetY : 0,
        pingOffsetX : 0,
        pingScale: 0.5,
        ...settingsInput
    }
    

    let name = settings.sprite;
    let x = inX+settings.xOffset;
    let y = inY+settings.yOffset;




    // console.log('rayLActive', rayLActive, 'rayRActive', rayRActive);

    const container = new PIXI.Container();
    let baseSprite = new PIXI.Sprite((texture)? texture : sheet.textures[name]);
    let  midPulse = new PIXI.AnimatedSprite(sheet.animations.midPulse);
    let  endPulse = new PIXI.AnimatedSprite(sheet.animations.endPulse);
    endPulse.tint =  PIXI.utils.rgb2hex([255/255, 166/255, 0/255])

    let rayLeft = new PIXI.AnimatedSprite(sheet.animations.ray);;
    let rayLeftLarge = new PIXI.AnimatedSprite(sheet.animations.rayLarge);
  
    
    let rayRight = new PIXI.AnimatedSprite(sheet.animations.ray);
    let rayRightLarge = new PIXI.AnimatedSprite(sheet.animations.rayLarge);
    

    let scale = 0.8162650602;

    baseSprite.x=x + settings.spriteXOffset;
    baseSprite.y= y + settings.spriteYOffset;
    baseSprite.anchor.x = 0.5;
    baseSprite.anchor.y = 1;
    baseSprite.scale.x = 0.5 * scale;
    baseSprite.scale.y = 0.5 * scale;
   

    midPulse.loop = false;
    midPulse.x= x + settings.pingOffsetX;
    midPulse.y=y-5 + settings.pingOffsetY;
    midPulse.anchor.x = 0.5;
    midPulse.anchor.y = 0.5;
    midPulse.scale.y = settings.pingScale;
    midPulse.scale.x = settings.pingScale;


    endPulse.loop = false;
    endPulse.x= x + settings.pingOffsetX;
    endPulse.y=y-5 + settings.pingOffsetY;
    endPulse.anchor.x = 0.5;
    endPulse.anchor.y = 0.5;
    endPulse.scale.y = settings.pingScale;
    endPulse.scale.x = settings.pingScale;

    rayLeft.loop = false;
    rayLeft.x= x;
    rayLeft.y=y+settings.rayLeftYOffset;
    rayLeft.anchor.x = 1;
    rayLeft.anchor.y = 1;
    rayLeft.scale.y = 0.5;
    rayLeft.scale.x = 0.5;
    
    

    rayLeftLarge.loop = false;
    rayLeftLarge.x= x;
    rayLeftLarge.y=y +settings.rayLeftLargeYOffset;
    rayLeftLarge.anchor.x = 1;
    rayLeftLarge.anchor.y = 1;
    rayLeftLarge.scale.y = 0.5;
    rayLeftLarge.scale.x = 0.5;

    


    rayRight.loop = false;
    rayRight.x= x;
    rayRight.y=y+settings.rayRightYOffset;
    rayRight.anchor.x = 1;
    rayRight.anchor.y = 1;
    rayRight.scale.y = 0.5;
    rayRight.scale.x = -0.5;

    rayRightLarge.loop = false;
    rayRightLarge.x= x;
    rayRightLarge.y=y +settings.rayRightLargeYOffset;
    rayRightLarge.anchor.x = 1;
    rayRightLarge.anchor.y = 1;
    rayRightLarge.scale.y = 0.5;
    rayRightLarge.scale.x = -0.5;
   
    
    //add to containers
    container.addChild(midPulse);
    container.addChild(endPulse);
    container.addChild(rayLeft);
    container.addChild(rayLeftLarge);
    container.addChild(rayRight);
    container.addChild(rayRightLarge);
    
    //add basesprite on top
    container.addChild(baseSprite)

    
    container.reset = ()=>{
        // gsap.killTweensOf(baseSprite);
        gsap.set(baseSprite, { pixi : { scale : 0.5 * scale }, ease : 'out' })
        rayLeft.gotoAndStop(0);
        rayLeftLarge.gotoAndStop(0);
        rayRight.gotoAndStop(0);
        rayRightLarge.gotoAndStop(0);
        midPulse.gotoAndStop(0);    
        endPulse.gotoAndStop(0);
    }

    container.playLarge = ()=>{
        endPulse.gotoAndPlay(0);
       
        
        if(settings.rayRight){
            rayRightLarge.gotoAndPlay(0);
        }
        if(settings.rayLeft){
            rayLeftLarge.gotoAndPlay(0);
        }
    }

    container.play = ()=>{
        midPulse.gotoAndPlay(0);
        if(settings.rayRight){
            rayRight.gotoAndPlay(0);
        }
        if(settings.rayLeft){
            rayLeft.gotoAndPlay(0);
        }
        
       
    }

    container.accessPing = (large)=>{
        let upScale = (large) ? 0.7 : 0.6;
        
        gsap.killTweensOf(baseSprite);
        let tl = gsap.timeline()
        tl.add(container.reset, 0)
        if(large){
            tl.add(()=>{
                endPulse.gotoAndPlay(0);
                rayLeftLarge.gotoAndPlay(0);
                rayRightLarge.gotoAndPlay(0);
            },0.05)
            tl.to(baseSprite, { duration:0.1,  pixi : { scale : upScale * scale }, ease : 'in' }, 0)
            tl.to(baseSprite, { duration:1,  pixi : { scale : 0.5 * scale }, ease : 'out' }, 0.1)
           
        } else {
            tl.add(()=>{
                rayLeft.gotoAndPlay(0);
                rayRight.gotoAndPlay(0);
            },0.05)
            tl.to(baseSprite, { duration:0.1,  pixi : { scale : upScale * scale }, ease : 'in' }, 0)
            tl.to(baseSprite, { duration:1,  pixi : { scale : 0.5 * scale }, ease : 'out' }, 0.1)
        }
        
    }
    
    container.ping = (large)=>{
        
        //ditch any running tweens
        gsap.killTweensOf(baseSprite);

        let upScale = (large) ? 0.7 : 0.6;
        
        let tl = gsap.timeline()
        tl.add(container.reset, 0)
        if(large){
            tl.add(container.playLarge,0.1)
        } else {
            tl.add(container.play, 0.1)
        }

        
        //add baseSprite Animation
        tl.to(baseSprite, { duration:0.1,  pixi : { scale : upScale * scale }, ease : 'in' }, 0)
        tl.to(baseSprite, { duration:1,  pixi : { scale : 0.5 * scale }, ease : 'out' }, 0.1)

        return tl;

    }
    // baseSprite.animationSpeed = gsap.utils.random(0.5, 1)
    return container;
}

export const createCShop = (sheet, settingsInput, inX, inY, texture)=>{
    
    let settings = {
        
        rayRight : true,
        rayLeft : true,
        rayLeftYOffset : 0,
        rayRightYOffset : 0,
        rayLeftLargeYOffset : 0,
        rayRightLargeYOffset : 0,
        spriteXOffset : 0,
        spriteYOffset : 0,
        pingOffsetY : 0,
        pingOffsetX : 0,
        pingScale: 0.5,
        ...settingsInput
    }
    

    let name = settings.sprite;
    let x = inX+settings.xOffset;
    let y = inY+settings.yOffset;




    // console.log('rayLActive', rayLActive, 'rayRActive', rayRActive);

    const container = new PIXI.Container();
    let baseSprite = new PIXI.Sprite((texture)? texture : sheet.textures[name]);
    let  midPulse = new PIXI.AnimatedSprite(sheet.animations.midPulse);
    let  endPulse = new PIXI.AnimatedSprite(sheet.animations.endPulse);
    endPulse.tint =  PIXI.utils.rgb2hex([255/255, 166/255, 0/255])

    let rayLeft = new PIXI.AnimatedSprite(sheet.animations.ray);;
    let rayLeftLarge = new PIXI.AnimatedSprite(sheet.animations.rayLarge);
  
    
    let rayRight = new PIXI.AnimatedSprite(sheet.animations.ray);
    let rayRightLarge = new PIXI.AnimatedSprite(sheet.animations.rayLarge);
    

    let scale = 0.8162650602;

    baseSprite.x=x + settings.spriteXOffset;
    baseSprite.y= y + settings.spriteYOffset;
    baseSprite.anchor.x = 0.5;
    baseSprite.anchor.y = 1;
    baseSprite.scale.x = 0.5 * scale;
    baseSprite.scale.y = 0.5 * scale;
   

    midPulse.loop = false;
    midPulse.x= x + settings.pingOffsetX;
    midPulse.y=y-5 + settings.pingOffsetY;
    midPulse.anchor.x = 0.5;
    midPulse.anchor.y = 0.5;
    midPulse.scale.y = settings.pingScale;
    midPulse.scale.x = settings.pingScale;


    endPulse.loop = false;
    endPulse.x= x + settings.pingOffsetX;
    endPulse.y=y-5 + settings.pingOffsetY;
    endPulse.anchor.x = 0.5;
    endPulse.anchor.y = 0.5;
    endPulse.scale.y = settings.pingScale;
    endPulse.scale.x = settings.pingScale;

    rayLeft.loop = false;
    rayLeft.x= x+5;
    rayLeft.y=y+-10;
    rayLeft.anchor.x = 1;
    rayLeft.anchor.y = 1;
    rayLeft.scale.y = 0.5;
    rayLeft.scale.x = 0.5;
    
    

    rayLeftLarge.loop = false;
    rayLeftLarge.x= x+5;
    rayLeftLarge.y=y-10;
    rayLeftLarge.anchor.x = 1;
    rayLeftLarge.anchor.y = 1;
    rayLeftLarge.scale.y = 0.5;
    rayLeftLarge.scale.x = 0.5;

    


    rayRight.loop = false;
    rayRight.x= x+5;
    rayRight.y=y-10;
    rayRight.anchor.x = 1;
    rayRight.anchor.y = 1;
    rayRight.scale.y = 0.5;
    rayRight.scale.x = -0.5;

    rayRightLarge.loop = false;
    rayRightLarge.x= x+5;
    rayRightLarge.y=y +settings.rayRightLargeYOffset;
    rayRightLarge.anchor.x = 1;
    rayRightLarge.anchor.y = 1;
    rayRightLarge.scale.y = 0.5;
    rayRightLarge.scale.x = -0.5;
   
    
    //add to containers
    container.addChild(midPulse);
    container.addChild(endPulse);
    
    
    //add basesprite on top
    container.addChild(baseSprite)
    
    container.addChild(rayLeft);
    container.addChild(rayLeftLarge);
    container.addChild(rayRight);
    container.addChild(rayRightLarge);

    
    container.reset = ()=>{
        // gsap.killTweensOf(baseSprite);
        gsap.set(baseSprite, { pixi : { scale : 0.5 * scale }, ease : 'out' })
        rayLeft.gotoAndStop(0);
        rayLeftLarge.gotoAndStop(0);
        rayRight.gotoAndStop(0);
        rayRightLarge.gotoAndStop(0);
        midPulse.gotoAndStop(0);    
        endPulse.gotoAndStop(0);
    }

    container.playLarge = ()=>{
        endPulse.gotoAndPlay(0);
       
        
        if(settings.rayRight){
            rayRightLarge.gotoAndPlay(0);
        }
        if(settings.rayLeft){
            rayLeftLarge.gotoAndPlay(0);
        }
    }

    container.play = ()=>{
        midPulse.gotoAndPlay(0);
        if(settings.rayRight){
            rayRight.gotoAndPlay(0);
        }
        if(settings.rayLeft){
            rayLeft.gotoAndPlay(0);
        }
        
       
    }

    container.accessPing = (large)=>{
        let upScale = (large) ? 0.7 : 0.6;
        
        gsap.killTweensOf(baseSprite);
        let tl = gsap.timeline()
        tl.add(container.reset, 0)
        if(large){
            tl.add(()=>{
                endPulse.gotoAndPlay(0);
                rayLeftLarge.gotoAndPlay(0);
                rayRightLarge.gotoAndPlay(0);
            },0.05)
            tl.to(baseSprite, { duration:0.1,  pixi : { scale : upScale * scale }, ease : 'in' }, 0)
            tl.to(baseSprite, { duration:1,  pixi : { scale : 0.5 * scale }, ease : 'out' }, 0.1)
           
        } else {
            tl.add(()=>{
                rayLeft.gotoAndPlay(0);
                rayRight.gotoAndPlay(0);
            },0.05)
            tl.to(baseSprite, { duration:0.1,  pixi : { scale : upScale * scale }, ease : 'in' }, 0)
            tl.to(baseSprite, { duration:1,  pixi : { scale : 0.5 * scale }, ease : 'out' }, 0.1)
        }
        
    }
    
    container.ping = (large)=>{
        
        //ditch any running tweens
        gsap.killTweensOf(baseSprite);

        let upScale = (large) ? 0.7 : 0.6;
        
        let tl = gsap.timeline()
        tl.add(container.reset, 0)
        if(large){
            tl.add(container.playLarge,0.1)
        } else {
            tl.add(container.play, 0.1)
        }

        
        //add baseSprite Animation
        tl.to(baseSprite, { duration:0.1,  pixi : { scale : upScale * scale }, ease : 'in' }, 0)
        tl.to(baseSprite, { duration:1,  pixi : { scale : 0.5 * scale }, ease : 'out' }, 0.1)

        return tl;

    }
    // baseSprite.animationSpeed = gsap.utils.random(0.5, 1)
    return container;
}



export const createStartupRadioMast = (sheet, settingsInput, inX, inY, texture)=>{
    
    let settings = {
        spriteXOffset : 0,
        spriteYOffset : 0,
        pingOffsetY : 0,
        pingOffsetX : 0,
        pingScale: 0.5,
        ...settingsInput
    }

    let name = settings.sprite;
    let x = inX+settings.xOffset;
    let y = inY+settings.yOffset;



    let rayLActive = (typeof settings.rayL !== 'undefined');
    let rayRActive = (typeof settings.rayR !== 'undefined');

    // console.log('rayLActive', rayLActive, 'rayRActive', rayRActive);

    const container = new PIXI.Container();
    let baseSprite = new PIXI.Sprite((texture)? texture : sheet.textures[name]);
    let  midPulse = new PIXI.AnimatedSprite(sheet.animations.midPulse);
    let  endPulse = new PIXI.AnimatedSprite(sheet.animations.endPulse);
    endPulse.tint =  PIXI.utils.rgb2hex([255/255, 166/255, 0/255])

    let  rayLeft = {};
    let rayLeftLarge = {};
    if(rayLActive){
        rayLeft = new PIXI.AnimatedSprite(sheet.animations.ray);
        rayLeftLarge = new PIXI.AnimatedSprite(sheet.animations.rayLarge);
    }
    
    let rayRight = {};
    let rayRightLarge = {};
    if(rayRActive){
        rayRight = new PIXI.AnimatedSprite(sheet.animations.ray);
        rayRightLarge = new PIXI.AnimatedSprite(sheet.animations.rayLarge);
    }

    let scale = 1.5;

    baseSprite.x=x + settings.spriteXOffset;
    baseSprite.y= y + settings.spriteYOffset;
    baseSprite.anchor.x = 0.5;
    baseSprite.anchor.y = 1;
    baseSprite.scale.x = 1 * scale;
    baseSprite.scale.y = 1 * scale;
   

    midPulse.loop = false;
    midPulse.x= x + settings.pingOffsetX;
    midPulse.y=y-5 + settings.pingOffsetY;
    midPulse.anchor.x = 0.5;
    midPulse.anchor.y = 0.5;
    midPulse.scale.y = settings.pingScale;
    midPulse.scale.x = settings.pingScale;


    endPulse.loop = false;
    endPulse.x= x + settings.pingOffsetX;
    endPulse.y=y-5 + settings.pingOffsetY;
    endPulse.anchor.x = 0.5;
    endPulse.anchor.y = 0.5;
    endPulse.scale.y = settings.pingScale;
    endPulse.scale.x = settings.pingScale;

    if(rayLActive){   
        rayLeft.loop = false;
        rayLeft.x= x;
        rayLeft.y=y-20;
        rayLeft.anchor.x = 1;
        rayLeft.anchor.y = 1;
        rayLeft.scale.y = 0.5;
        rayLeft.scale.x = 0.5;

        rayLeftLarge.loop = false;
        rayLeftLarge.x= x;
        rayLeftLarge.y=y-20;
        rayLeftLarge.anchor.x = 1;
        rayLeftLarge.anchor.y = 1;
        rayLeftLarge.scale.y = 0.5;
        rayLeftLarge.scale.x = 0.5;
    }

    if(rayRActive){
        rayRight.loop = false;
        rayRight.x= x;
        rayRight.y=y-20;
        rayRight.anchor.x = 1;
        rayRight.anchor.y = 1;
        rayRight.scale.y = 0.5;
        rayRight.scale.x = -0.5;

        rayRightLarge.loop = false;
        rayRightLarge.x= x;
        rayRightLarge.y=y -20;
        rayRightLarge.anchor.x = 1;
        rayRightLarge.anchor.y = 1;
        rayRightLarge.scale.y = 0.5;
        rayRightLarge.scale.x = -0.5;
        baseSprite.scale.x = 0.5;
    }
   
    
    //add to containers
    container.addChild(midPulse);
    container.addChild(endPulse);
    if(rayLActive){
        container.addChild(rayLeft);
        container.addChild(rayLeftLarge);
    }

    if(rayRActive){
        container.addChild(rayRight);
        container.addChild(rayRightLarge);
    }
    
    //add basesprite on top
    container.addChild(baseSprite)

    
    container.reset = ()=>{
        // gsap.killTweensOf(baseSprite);
        gsap.set(baseSprite, { pixi : { scale : 0.5 * scale }, ease : 'out' })
        if(rayLActive){
            rayLeft.gotoAndStop(0);
            rayLeftLarge.gotoAndStop(0);
        }

        if(rayRActive){
            rayRight.gotoAndStop(0);
            rayRightLarge.gotoAndStop(0);
        }
        midPulse.gotoAndStop(0);    
        endPulse.gotoAndStop(0);
    }

    container.playLarge = ()=>{
        endPulse.gotoAndPlay(0);

        if(rayLActive){
            rayLeft.gotoAndPlay(0);
            rayLeftLarge.gotoAndPlay(0);
        }

        if(rayRActive){
            rayRight.gotoAndPlay(0);
            rayRightLarge.gotoAndPlay(0);
        }
    }

    container.play = ()=>{
        midPulse.gotoAndPlay(0);

        if(rayLActive){
            rayLeft.gotoAndPlay(0);
        }

        if(rayRActive){
            rayRight.gotoAndPlay(0);
        }
    }

    
    container.ping = (large)=>{
        
        //ditch any running tweens
        gsap.killTweensOf(baseSprite);

        let upScale = (large) ? 0.7 : 0.6;
        
        let tl = gsap.timeline()
        tl.add(container.reset, 0)
        if(large){
            tl.add(container.playLarge,0.1)
        } else {
            tl.add(container.play, 0.1)
        }

        
        //add baseSprite Animation
        tl.to(baseSprite, { duration:0.1,  pixi : { scale : upScale * scale }, ease : 'in' }, 0)
        tl.to(baseSprite, { duration:1,  pixi : { scale : 0.5 * scale }, ease : 'out' }, 0.1)

        return tl;

    }
    // baseSprite.animationSpeed = gsap.utils.random(0.5, 1)
    return container;
}



export const createSatelitte = (sheet, settingsInput, inX, inY, texture)=>{
    
    let settings = {
        spriteXOffset : 0,
        spriteYOffset : 0,
        pingOffsetY : 0,
        pingOffsetX : 0,
        pingScale: 0.5,
        ...settingsInput
    }

    let name = settings.sprite;
    let x = inX+settings.xOffset;
    let y = inY+settings.yOffset;



  
    const container = new PIXI.Container();
    let baseSprite = new PIXI.Sprite((texture)? texture : sheet.textures[name]);
    let  endPulse = new PIXI.AnimatedSprite(sheet.animations.endPulse);

    
    let scale = 0.8162650602;

    baseSprite.x=x + settings.spriteXOffset;
    baseSprite.y= y + settings.spriteYOffset;
    baseSprite.anchor.x = 0.5;
    baseSprite.anchor.y = 1;
    baseSprite.scale.x = 0.5 * scale;
    baseSprite.scale.y = 0.5 * scale;
    


    endPulse.loop = false;
    endPulse.x= x + settings.pingOffsetX;
    endPulse.y=y-5 + settings.pingOffsetY;
    endPulse.anchor.x = 0.5;
    endPulse.anchor.y = 0.5;
    endPulse.scale.y = settings.pingScale;
    endPulse.scale.x = settings.pingScale;
    endPulse.angle = 90
   
    //add basesprite on top
    container.addChild(baseSprite)

    //add to containers
    container.addChild(endPulse);
    
    

    
    container.reset = ()=>{
        // gsap.killTweensOf(baseSprite);
        gsap.set(baseSprite, { pixi : { scale : 0.5 * scale }, ease : 'out' })
     
        endPulse.gotoAndStop(0);
    }

    container.playLarge = ()=>{
        endPulse.gotoAndPlay(0);
    }

    container.play = ()=>{
        endPulse.gotoAndPlay(0);
    }

    
    container.ping = (large)=>{
        
        //ditch any running tweens
        gsap.killTweensOf(baseSprite);

        let upScale = (large) ? 0.7 : 0.6;
        
        let tl = gsap.timeline()
        tl.add(container.reset, 0)
        if(large){
            tl.add(container.playLarge,0.1)
        } else {
            tl.add(container.play, 0.1)
        }

        
        //add baseSprite Animation
        tl.to(baseSprite, { duration:0.1,  pixi : { scale : upScale * scale }, ease : 'in' }, 0)
        tl.to(baseSprite, { duration:1,  pixi : { scale : 0.5 * scale }, ease : 'out' }, 0.1)

        return tl;

    }
    // baseSprite.animationSpeed = gsap.utils.random(0.5, 1)
    return container;
}


export const createCloud = (sheet, settingsInput, inX, inY, texture)=>{
    
    let settings = {
        spriteXOffset : 0,
        spriteYOffset : 0,
        pingOffsetY : 0,
        pingOffsetX : 0,
        pingScale: 0.5,
        ...settingsInput
    }

    let name = settings.sprite;
    let x = inX+settings.xOffset;
    let y = inY+settings.yOffset;



  
    const container = new PIXI.Container();
    let baseSprite = new PIXI.Sprite((texture)? texture : sheet.textures[name]);

    
    let scale = 0.8162650602;

    baseSprite.x=x + settings.spriteXOffset;
    baseSprite.y= y + settings.spriteYOffset;
    baseSprite.anchor.x = 0.5;
    baseSprite.anchor.y = 1;
    baseSprite.scale.x = 0.5 * scale;
    baseSprite.scale.y = 0.5 * scale;
    


    
    //add basesprite on top
    container.addChild(baseSprite)


    

    
    container.reset = ()=>{
        // gsap.killTweensOf(baseSprite);
        gsap.set(baseSprite, { pixi : { scale : 0.5 * scale }, ease : 'out' })
    
    }

    container.ping = (large)=>{
        
        //ditch any running tweens
        gsap.killTweensOf(baseSprite);

        
        let tl = gsap.timeline()
        tl.add(container.reset, 0)
        //add baseSprite Animation
        tl.to(baseSprite, { duration:0.1,  pixi : { alpha : 0.5 }, ease : 'in' }, 0)
        tl.to(baseSprite, { duration:1,  pixi : { alpha : 1 }, ease : 'out' }, 0.1)

        return tl;

    }
    return container;
}


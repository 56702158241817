import { h, Component, Fragment } from 'preact';
import classNames from 'classnames';
import style from './style.css';
import gsap from 'gsap';
import {navigation} from './../data';

export default class Menu extends Component {
	
	constructor(){
		super();
		this.respondToChange = this.respondToChange.bind(this);
		this.respondToHover = this.respondToHover.bind(this);
		this.respondToResize = this.respondToResize.bind(this);
		this.triggerBackground = this.triggerBackground.bind(this);
		this.handleFirstLevelClick = this.handleFirstLevelClick.bind(this);
		this.state = {
			selected : null,
			activeTrigger : null
		}
		this.lastTrigger = null;
	}

	componentWillMount(){
	
	}

	componentDidMount(){
		
		this.respondToChange(this.props);
		window.addEventListener('resize', this.respondToResize );

	}	

	componentWillReceiveProps(newProps){

		if(!this.state.selected || newProps.selectedState !== this.state.selected.url){
			let selection = navigation.routes.filter((r)=>{
				return r.url===newProps.selectedState;
			});

			if(selection.length){
				this.setState({
					selected : selection[0]
				})
				
			}
		}
	}

	respondToResize(){

		let props = this.props;
		gsap.killTweensOf([this.menu,this.menuWrap ])		
		if(props.selectedState){

			gsap.timeline()
			.set(this.menuWrap, { duration : 0.3, height : this.secondLevel.getBoundingClientRect().height+20 }, 0.3)
		} else {
			gsap.timeline()
			.set(this.menuWrap, { duration : 0.3, height : this.topLevel.getBoundingClientRect().height })
		}
	}

	componentWillUnmount(){
		window.removeEventListener('resize', this.respondToResize );
	}
	

	respondToChange(props){

		if(!props) { props = this.props };

		gsap.killTweensOf([this.menu,this.menuWrap ])
			
		if(props.selectedState){

			gsap.timeline()
			.add(()=>{
				if(this.menu){
					this.menu.style.transform="translateX(-50%)"
				}
			}, 0.01)
			.to(this.menuWrap, { duration : 0.3, height : this.secondLevel.getBoundingClientRect().height+20 }, 0.3)
		} else {
			gsap.timeline()
			.to(this.menuWrap, { duration : 0.15, height : this.topLevel.getBoundingClientRect().height })
			.add(()=>{
				if(this.menu){
					this.menu.style.transform="translateX(0%)"
				}
			}, 0.15)
		}
	}

	componentDidUpdate(){
		// console.log(this.topLevel.getBoundingClientRect(), this.secondLevel.getBoundingClientRect())
		this.respondToChange(this.props);
	}




	respondToHover(trigger){
		if ("ontouchstart" in document.documentElement) {
			return;
		}

		// if(this.state.activeTrigger==trigger.url) return;
		
		this.setState({
			activeTrigger : trigger.url
		})
		this.props.onHover(trigger.url+'Hover');
		this.props.onSubHover(trigger.ref, true, true);

	
	}


	triggerBackground(dimmerRef){

		// if(dimmerRef && dimmerRef.ref && !dimmerRef.noDimmer){
		// 	document.getElementById("TechInfraProjectMap").classList.add('dimmer')
		// } else {
		// 	document.getElementById("TechInfraProjectMap").classList.remove('dimmer')
		// }
	}

	handleFirstLevelClick(url){
		//throttle clicks
		let curTime = Date.now();
		if(this.lastTrigger){
			if(curTime - this.lastTrigger  < 300){
				this.lastTrigger = curTime;
				return;
			} 
		}
		
		this.lastTrigger = curTime;
		this.props.selectState(url)
	}
	
	render() {

		

		const renderSub = ()=>{
			const getClasses = ()=>{
				// console.log('this.state.selected', this.state.selected)
				let c = [style.secondLevelSumMenuItem];
				if(this.state.selected){
					c.push(style[this.state.selected.ref]);
				}
				
				return c.join(" ");
			}
			// console.log('renderSub', this.state.selected);
			if(!this.state.selected) return null;
			
			return this.state.selected.routes.map((r)=>{
				return <li  onMouseEnter={()=>{this.props.onSubHover(r.ref, r.noDimmer ); this.triggerBackground(r)}}  onMouseLeave={()=>{this.props.onSubHover(); this.triggerBackground()}} class={getClasses()} >
					<a onClick={()=>{if(MAP_ANALYTICS_CALL){ MAP_ANALYTICS_CALL(r.ref) }}} href={r.url} >{r.title} 
					
				</a>
				<svg viewBox="0 0 12 20">
						<path  d="M11.37,9.52,2.55.7a.7.7,0,0,0-1,0l-.95,1a.67.67,0,0,0,0,1L8,10,.63,17.38a.69.69,0,0,0,0,1l1,.95a.67.67,0,0,0,.49.2.65.65,0,0,0,.48-.2l8.82-8.81a.7.7,0,0,0,.2-.49A.67.67,0,0,0,11.37,9.52Z" fill="#fff"/>
				</svg>
				
				</li>
			})
		}
		const renderSecondLevel = ()=>{
			const getClasses = ()=>{
				// console.log('this.state.selected', this.state.selected)
				let c = [style.secondLevelSumMenuItem];
				
				return c.join(" ");
			}
			return (
				<Fragment>
					<li class={getClasses()} onClick={()=>{this.handleFirstLevelClick(null)}} style={{backgroundColor : (this.state.selected)? this.state.selected.fill : "#ccc"}} ><div class={style.menuListFirstItemPad}>{(this.state.selected) ? this.state.selected.title : 'Home' }</div>
					
					<svg viewBox="0 0 20 20">
                    <path d="M12,10l7.38-7.38a.68.68,0,0,0,0-1L18.43.7a.68.68,0,0,0-1,0L10,8.16,2.54.7a.68.68,0,0,0-1,0l-.95,1a.68.68,0,0,0,0,1L8,10,.62,17.38a.67.67,0,0,0-.2.48.7.7,0,0,0,.2.49l1,.95a.71.71,0,0,0,.49.2.69.69,0,0,0,.48-.2L10,11.84l7.46,7.46a.69.69,0,0,0,.48.2.71.71,0,0,0,.49-.2l.95-.95a.7.7,0,0,0,.2-.49.67.67,0,0,0-.2-.48Z" fill="#fff"/>
                </svg>
					</li>
					<li>
						<ul>
							{renderSub()}
						</ul>
					</li>
				</Fragment>
			)
		}
		const renderTopLevel = ()=>{
			return (
				<Fragment>
					{ 
						navigation.routes.map((r)=>{
						return <li 
								onMouseEnter={()=>{
									this.respondToHover(r);
																	}} 
								onMouseLeave={()=>{
									if(!this.props.selectedState){
										this.respondToHover('');
									}
								}}
								class={style.menuListFirstItem} 
								onTouchStart={(e)=>{
									e.stopPropagation();
									e.preventDefault();
									this.handleFirstLevelClick(r.url)
								}} 
								onClick={(e)=>{
									e.stopPropagation();
									e.preventDefault();
									// if ("ontouchstart" in document.documentElement) {
									// 	return;
									// }
									this.handleFirstLevelClick(r.url)}} 
								style={{backgroundColor : r.fill}}>
									<div  class={style.menuListFirstItemPad}>{r.title}</div>
						<svg viewBox="0 0 12 20">
                    <path  d="M11.37,9.52,2.55.7a.7.7,0,0,0-1,0l-.95,1a.67.67,0,0,0,0,1L8,10,.63,17.38a.69.69,0,0,0,0,1l1,.95a.67.67,0,0,0,.49.2.65.65,0,0,0,.48-.2l8.82-8.81a.7.7,0,0,0,.2-.49A.67.67,0,0,0,11.37,9.52Z" fill="#fff"/>
                </svg>
						</li>
						})
					}
				</Fragment>
			)
		}

		
		return (
			<div onMouseLeave={()=>{
				// this.respondToHover('');
			}} ref={(node)=>this.menuWrap=node} class={()=>{
				return style.menuWrap;
			}}>
				<div ref={(node)=>this.menu=node}  class={classNames(style.menuInner, (this.props.selectedState) ? style.menuInnerActive : null)}>
					<div class={classNames(style.menuList, style.menuListFirst)} >
						<ul ref={(node)=>this.topLevel=node}>
							{renderTopLevel()}
						</ul>
					</div>
					<div class={classNames(style.menuList, style.menuListSecond)} >
						<ul ref={(node)=>this.secondLevel=node}>
							{renderSecondLevel()}
						</ul>
					</div>
				</div>

			</div>
		)



	}
}

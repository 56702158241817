
import { colors } from '../assets/theme';


export const navigation = {
    routes : [
        {
            title : "Access",
            url : "access",
            fill: colors.darkBlue,
            subFill : colors.coreBlue,
            subFill : "#5d9ea5",
            subFill : "#82cbd3",
            ref : "access",
            highlight:"access",
            vb : [199,107,1970,910],
            routes : [
                {
                    "type" : "link",
                    "title" : "CrowdCell",
                    "ref" : "accessCrowdcell",
                    "highlight": "accessCrowdcell",
                    "content" : `<p><span>CrowdCell Project Group</span> is developing LTE relay architectures to extend indoor coverage with reduced CAPEX and OPEX investment.</p>`,
                    "url" : "/crowdcell/",
                    "side": "left",
                    "pos": [1335, 390],
                    "toolTipPlacement" : "aboveLeft"
                },
                {
                    "type" : "link",
                    "title" : "OpenCellular",
                    "ref" : "accessOpenCellular",
                    "highlight": "accessOpenCellular",
                    "content" : `<p>The goal of the <span>OpenCellular Project Group</span> is to connect the unconnected in the rural areas, by empowering communities with tools to build and operate sustainable community cellular infrastructure, using and building open-source technologies and open ecosystem.</p>`,
                    "url" : "/opencellular-wireless-access-platform-design/",
                    "pos" :[705, 265],
                    "toolTipPlacement" : "right"
                },
                {
                    "type" : "link",
                    "title" : "OpenRAN",
                    "ref" : "accessOpenRAN",
                    "highlight": "accessOpenRAN",
                    "content" : `<p><span>OpenRAN Project Group</span> is an initiative to define and build 2G, 3G and 4G RAN solutions based on a general-purpose vendor-neutral hardware and software-defined technology.</p>`,
                    "url" : "/openran/",
                    "pos" :[1270, 480],
                    "toolTipPlacement" : "aboveLeft"
                },
               
                {
                    "type" : "link",
                    "title" : "vRAN Fronthaul",
                     "ref" : "accessFronthaul",
                     "highlight" : "accessFronthaul",
                     "content" : `<p>The <span>vRAN Fronthaul project group</span> focuses on developing virtualized RAN solutions that can be deployed and operated over non-ideal fronthaul between virtualized Baseband Units (vBBU) and Remote Radio Units (RRU).</p>`,
                     "url": "/vran/",
                     "pos" :[1040, 316],
                     "toolTipPlacement" : "belowLeft"
                },
                {
                    "type" : "link",
                    "title" : "OpenRAN 5G NR",
                     "ref" : "accessOpenRAN5GNR",
                     "highlight": "accessOpenRAN5GNR",
                     "content" : `<p>The <span>OpenRAN 5G NR Project Group</span> will collaborate via TIP to define a whitebox platform for a 5G NR access point that is easy to configure and deploy.</p>`,
                     "url" : "/5gnr/",
                     "side" : "left",
                     "xOffset" : 30,
                     "pos" :[1270, 480],
                     "toolTipPlacement" : "aboveLeft"
                },
                {
                    "type" : "link",
                    "title" : "WiFi",
                     "ref" : "accessWiFi",
                     "highlight": "accessWiFi",
                     "content" : `<p>The goal of the <span>WiFi Project Group</span> is to foster collaboration, exploration, and standardization among organizations deploying WiFi, infrastructure vendors, and service providers.</p>`,
                     "url": "/wifi/",
                     "side" : "left",
                     "pos" :[1295, 360],
                     "toolTipPlacement" : "belowLeft"
                }
            ]
        },
        {
            title : "Transport",
            url : "backhaul",
            fill : colors.lightBlue,
            ref : "backhaul",
            highlight:"backhaul",
            content : ``,
            vb : [199,107,1970,910],
            routes  : [
                {
                    "type" : "link",
                    "title" : "Wireless Backhaul",
                    "ref" : "backhaulMicrowave",
                    "highlight" : "backhaulMicrowave",
                    "content" : `<p>The goal of the <span>Wireless Backhaul Project Group</span> is to define and build the next generation of modular wireless backhaul systems for 3G/4G and upcoming 5G networks.</p>`,
                    "url":"/wirelessbackhaul/",
                    "yOffset": 30,
                    "pos" :[890, 310],
                    "toolTipPlacement" : "right"
                },
                {
                    "type" : "link",
                    "title" : "Millimeter Wave (mmWave) Networks",
                    "ref" : "backhaulMillimeter",
                    "highlight" : "backhaulMillimeter",
                    "content" : `<p>The goal of the <span>mmWave Project Group</span> is to define and advance wireless networking solutions that utilize millimeter-wave spectrum (~30 to 300 GHz) to address the constantly growing demand for bandwidth by delivering gigabits of capacity more quickly, easily and cost-effectively compared to deploying fiber.</p>`,
                    "url": "/mmwave/",
                    "side" : "left",
                    "pos" :[1210, 290],
                    "toolTipPlacement" : "left"
                },
                {
                    "type" : "link",
                    "title" : "Open Optical & Packet Transport",
                    "ref" : "backhaulOpen",
                    "highlight" : "backhaulOpen",
                    "content" : `<p>The goal of the <span>Open Optical & Packet Transport Project Group</span> is to accelerate innovation in optical and IP networks and ultimately help operators provide better connectivity for communities all around the world.</p>`,
                    "url":"/oopt/",
                    "pos" :[890, 354],
                    "toolTipPlacement" : "aboveRight"
                    
                }
                
                
            ]
        },
        {
            title : "Core & Services",
            url : "core",
            fill : colors.coreBlue,
            ref : "core",
            "highlight": "core",
            content : ``,
            vb : [0,10,532.85,246.14],
            routes  : [
                {
                    "type" : "link",
                    "title" : "Edge Application Developer",
                    "ref" : "coreEdge",
                    "highlight" : "coreEdge",
                    "content" : `<p>The purpose of the <span>Edge Application Developer Project Group</span> is to create open APIs and SDKs that empower developers to build the next generation of mobile applications running on operators edge infrastructure.</p>`,
                    "url": "/ead/",
                    "side" : "left",
                    "pos" :[875, 330],
                    "noDimmer" : true,
                    "toolTipPlacement" : "aboveLeft"
                },
                {
                    "type" : "link",
                    "title" : "End-to-End Network (E2E-NS)",
                    "ref" : "coreEnd",
                    "highlight" : "coreEnd",
                    "content" : `<p>The <span>End-to-End Network Slicing Project Group</span> is aimed at developing a commercially viable End-to-End Network Slicing ecosystem that can be deployed over fixed and mobile operator networks. </p>`,
                    "url": "/e2ens/",
                    "pos" :[940, 315],
                    "noDimmer" : true,
                    "toolTipPlacement" : "aboveRight"
                }
            ]
        },

        
        {
            title : "Startups",
            url : "startups",
            fill : colors.coreGreen,
            ref : "startups",
            "highlight": "startups",
            "content" : ``,
            vb : [9,1,532.85,246.14],
            routes  : [
                {
                    "type" : "link",
                    "title" : "TEAC – TIP Ecosystem Acceleration Centers",
                    "ref" : "startupsHighlight",
                    "highlight" : "startupsHighlight",
                    "content"  :`<span>TEACs</span> are global centers for startups, investors and operators to work together to produce breakthrough technologies that reimagine telecom infrastructure.`,
                    "url": "/teac/",
                    "side" : "left",
                    "pos" :[1111, 290],
                    "noDimmer" : true,
                    "toolTipPlacement" : "aboveLeft"
                }
            ]
        },
        {
            title : "Labs",
            url : "labs",
            fill : colors.navy,
            subFill : "#84abdd",
            ref : "labs",
            "highlight": "labs",
            "content" : ``,
            vb : [0,-10,490.46,226.56],
            routes  : [
                {
                    "type" : "link",
                    "title" : "TIP Community Labs",
                    "ref" : "labsTip",
                    "highlight" : "labsTip",
                    "content" : `<p><span>TIP Community Labs</span> accelerate innovative telecom solutions by utilizing an open, collaborative approach to test and incubate technologies that address service provider use cases and can get deployed at scale. </p>`,
                    "url": "/clabs/",
                    "side" : "right",
                    "pos" :[1000, 330],
                    "noDimmer" : true,
                    "toolTipPlacement" : "aboveRight"
                }
            ]
        }
    ]
}


export const towerSequences = {
    access : [
        { n : 'tOne1', params :  { mark : 'start' , side : 'both', size : "big" }},
        { n : 'cShop1', params :  { side : 'both', size : "big" }},
        { n : 'radioMast', params :  { side : 'both', size : "big" }},
        { n : 'tOne2', params :  { side : 'both', size : "big" }},
        { n : 'tOne3', params :  { side : 'both', size : "big" }},
        { n : 'tOne6', params :  { side : 'both', size : "big" }},
        { n : 'tOne7', params :  { mark : 'start' , side : 'both', size : "big" }},
        { n : 'tTwo1', params :  { side : 'both', size : "big" }},
        { n : 'tThree1', params :  { side : 'both', size : "big" }},
        { n : 'tFour1', params :  { side : 'both', size : "big" }},
        { n : 'tSix2', params :  { side : 'both', size : "big" }},
        { n : 'tFive1', params :  { side : 'both', size : "big" }},
        { n : 'tSeven1', params :  { side : 'both', size : "big" }},
        { n : 'tSix1', params :  { side : 'both', size : "big" }},
        { n : 'tEight1', params :  { mark : 'start' , side : 'both', size : "big" }},
        { n : 'tOne9', params :  { mark : 'start' , side : 'both', size : "big" }}
    ]
}

export const routePaths = {
    sequences : {
        main : ['main1', 'main2', 'main3', 'main4', 'main5', 'main6', 'main7', 'main8', 'main9', 'main10'],
        backhaulPing : ['backhaulPing1', 'backhaulPing2', 'backhaulPing3', 'backhaulPing4', 'backhaulPing5', 'backhaulPing6', 'backhaulPing7', 'backhaulPing8', ],
        backhaul : ['backhaul1', 'backhaul2', 'backhaul3', 'backhaul4', 'backhaul5', 'backhaul6', 'backhaul7', 'backhaul8',  'backhaul9'],
        core : ['core1', 'core2', 'core3', 'core2', 'core5', 'core2', 'core7', 'core8', 'core2'],
        labs : ['labs1', 'labs2', 'labs3', 'labs2', 'labs5', 'labs6'],
        mobile : ['mobile1', 'mobile2', 'mobile3', 'mobile4', 'mobile5', 'mobile6', 'mobile7']
        // labs : ['labs1', 'labs1']
    },
    paths : {
        main1 : {
            path : "M1411.33,252.71v8.54c.47,5.44-3.07,7-3.07,7s-48.36,28.47-49,28.48l-138.23,81.46-15.57.19a9.65,9.65,0,0,0-4.69,1.28l-127.37,72.93-.8-6.7-17.1-147.31-5.74-3.05-46.12-45.85V242.18",
            start : 0,
            end : 1,
            width : 6,
            length : 789.594,
            pings : [
                { tower : 'tOne1', progress :  0.01},
                { tower : 'sNode1', progress :  0.132},
                { tower : 'stNode1', progress :  0.36},
                { tower : 'sNode3', progress :  0.609},
                { tower : 'sNode6', progress :  0.844},
                { tower : 'tOne7', progress :  0.952},
            ]
        },
        main2 : {
            path : "M1273.47,507v20l-11.94-42.83,22.38,13.44v-9.87l128.32-73.4-67.92-39.73,58.19-31-41.87-46.89h-1.35l-81.6,48-32.25-18.69a1.15,1.15,0,0,1,0-2l6.91-3.93,8.47-4.8h7.38",
            start : 1,
            end : 0,
            width : 6,
            length : 721.329,
            pings : [
                { tower : 'tOne3', progress :  0.01},
                { tower : 'sNode2', progress :  0.109},
                { tower : 'tNine7', progress :  0.156},
                { tower : 'stNode2', progress :  0.622},
                { tower : 'sNode1', progress :  0.726},
                { tower : 'cShop1', progress : 0.978},
            ]
        },
        main3 : {
            path : "M532.05,558.31v4.93H522V557.3l-1.69-4.23L456.81,110.56h0l305.52,176,1.67,3.87.23,5.72h10.39l.29,81.16,132.66,78.83L965,455l52-1.47,55.91-2.9.17,90.39,77.15-46.47,4.7,2.28v7.77a6.36,6.36,0,0,0,3.35,6.08c3.76,1.9,45.47,25.63,45.47,25.63a7.13,7.13,0,0,1,3.89,6.29v23.06l-24.28,13.76,26.21,13.75,5.72-3.12,69.87,42.35a27.88,27.88,0,0,0,14.42,4l228.45-.06",
            start : 0,
            end : 1,
            width : 6,
            length : 2374.208,
            pings : [
                { tower : 'tFour1', progress :  0},
                { tower : 'satellite', progress :  0.248},
                { tower : 'tFive1', progress :  0.461},
                { tower : 'sNode8', progress :  0.515},
                { tower : 'sNode7', progress :  0.584},
                { tower : 'sNode3', progress :  0.663},
                { tower : 'sNode4', progress :  0.714},
                { tower : 'stNode3', progress :  0.755},
                { tower : 'bThree1', progress :  0.798},
                { tower : 'cloud', progress : 0.987},
                
            ]
        },
        main4 : {
            path : "M543.45,308.13l112.8-37.59",
            start : 0,
            end : 1,
            width : 6,
            length : 139.14,
            pings : [
                { tower : 'tEight1', progress :  0},
                { tower : 'tSeven1', progress :  0.938}
            ]
        },
        main5 : {
            // path : "M669.9,541.67l194.3,46.17v12.44h5.51a42.06,42.06,0,0,1,9.79,1c.93.23,2.76.85,2.76.85l1.23.37,1.81.33,3.77.69c5.54.64,8.58-1.78,8.58-1.78L907,596.4l22.56-13.85,33.51-21.44c5.56-4,7.67-10.93,7.67-10.93L975.18,536a11.22,11.22,0,0,1,1.06-2.47A68.58,68.58,0,0,1,985,522.14l2.15-2.38a3.55,3.55,0,0,0-.58-5.57l-11-6.89a1.25,1.25,0,0,1-.57-1v-.86a1.23,1.23,0,0,0-.47-1l-56.55-44.12-7.26-5.71L958.26,380,878,320.81v-23",
            path : "M878,297.83v23L958.26,380l-47.59,74.64,7.26,5.71,56.55,44.12a1.23,1.23,0,0,1,.47,1v.86a1.25,1.25,0,0,0,.57,1l11,6.89a3.55,3.55,0,0,1,.58,5.57L985,522.14a68.58,68.58,0,0,0-8.75,11.34,11.22,11.22,0,0,0-1.06,2.47l-4.44,14.23s-2.11,6.93-7.67,10.93l-33.51,21.44L907,596.4l-9.35,5.29s-3,2.42-8.58,1.78l-3.77-.69-1.81-.33-1.23-.37s-1.83-.62-2.76-.85a42.06,42.06,0,0,0-9.79-1H864.2V587.84L669.9,541.67",
            start : 0,
            end : 1,
            width : 6,
            length : 803.509,
            pings : [
                { tower : 'tSix2', progress :  0},
                { tower : 'sNode5', progress :  0.173},
                { tower : 'sNode7', progress :  0.288},
                { tower : 'tOne9', progress :  0.404},
                { tower : 'tTwo1', progress :  0.677},
                { tower : 'tThree1', progress :  0.977},
            ]
        },
        main6 : {
            path : "M1458.3,363.8v15.34l-6.75-7.66-43.35-24.2-6.67-2.91-177.61,34.82L1151,497.7,1072.8,452,960.66,379.61l-187.79-3.32-117-87.57-1-22.64c-27.71-15.87-67.78-24.84-94.51-29.39-16.74-2.85-28.25-4-28.25-4",
            start : 0,
            end : 1,
            width : 6,
            length : 1312.498,
            pings : [
                { tower : 'tOne2', progress :  0},
                { tower : 'stNode2', progress :  0.082},
                { tower : 'stNode1', progress :  0.237},
                { tower : 'stNode3', progress :  0.361},
                { tower : 'sNode3', progress :  0.442},
                { tower : 'sNode5', progress :  0.56},
                { tower : 'sNode8', progress :  0.723},
                { tower : 'tSeven1', progress :  0.864},
                { tower : 'tSix1', progress :  0.994},
            ]
        },
        main7 : {
            path : "M1416.06,557.78,1349.71,522l66-38.07-61.91-36.05L1285.46,408l-12.58,7.11V372.13l-2.85-1.66,5.33-3.55-3.75-4.66",
            start : 0,
            end : 1,
            width : 6,
            length : 439.069,
            pings : [
                { tower : 'tNine5', progress :  0},
                { tower : 'tNine6', progress :   0.184},
                { tower : 'tNine10', progress :   0.394},
                { tower : 'tNine11', progress :   0.594},
                { tower : 'tNine12', progress :   0.805},
                { tower : 'radioMast', progress :   0.988},                
            ]
        },
        main8 : {
            path : "M1535.72,486.28l-56.81,34.11-63.23-36.5-66,38.06-134.11-74.6",
            start : 0,
            end : 1,
            width : 6,
            length : 431.511,
            pings : [
                { tower : 'tNine13', progress :  0},
                { tower : 'tNine9', progress :  0.18},
                { tower : 'tNine10', progress : 0.374},
                { tower : 'tNine6', progress : 0.584},
                { tower : 'tNine7', progress : 0.778},
                { tower : 'tNine8', progress : 0.984}
            ]
        },
        main9 : {
            path : "M1215.61,447.35,1349.71,522l66-38.07,63.24,36.5,57.32-33.88",
            start : 0,
            end : 1,
            width : 6,
            length : 431.904,
            pings : [
                { tower : 'tNine8', progress :  0},
                { tower : 'tNine7', progress :  0.199},
                { tower : 'tNine6', progress :  0.409},
                { tower : 'tNine10', progress :  0.612},
                { tower : 'tNine9', progress :  0.805},
                { tower : 'tNine13', progress :  0.976},
                
            ]
        },
        main10 : {
            path : "M784.7,459.79v26.87l115.51-29.28,7.12-1.7.57.37,4.37-.16,61.88,48,3.15,1.87,4.06.86L1070,542.93l80.27-48.42,1.94,1.19,71.34-116,.58-3-51.92-59.92V291.25",
            start : 0,
            end : 1,
            width : 6,
            length : 795.834,
            pings : [
                { tower : 'tOne10', progress :  0},                
                { tower : 'sNode7', progress :  0.221},    
                { tower : 'tOne9', progress :  0.326}, 
                { tower : 'sNode4', progress :  0.488},    
                { tower : 'stNode3', progress :  0.63},
                { tower : 'stNode1', progress :  0.832},  
                { tower : 'tOne5', progress :  0.945},    
            ]
        },
        backhaulPing1 : {
            path : "M1072.85,451l-162.07,5v-1.38l48.11-75.23-6.49-3.27-74.32-55.48L878,297.86l-18.5-5.2-82-19.55",
            width : 6,
            length : 795.834,
            pings : [
                { tower : 'sNode7', progress :  0.31},
                { tower : 'sNode5', progress :  0.52},
                { tower : 'tSix2', progress :  0.716},
                { tower : 'tFive1', progress :  0.993},
            ]
        },
        backhaulPing2 : {
            path : "M1072.23,451l-53.81,2.75-106,2.17,60.48,47.75a3.07,3.07,0,0,0,1.45,3.19l12.43,7.62a3.07,3.07,0,0,1,1,4.22,3.14,3.14,0,0,1-.39.51l-1.52,1.63-6.71,8.08a6,6,0,0,0-.38.5l-2.3,3.47a8.53,8.53,0,0,0-1,2.2l-2.57,8.3s-1.95,6.52-2.31,7.46a23.22,23.22,0,0,1-9.75,11.61l-58,36s-5.54,3.49-6.22,3.78a16.8,16.8,0,0,1-8.54.91l-3.77-.68A45.54,45.54,0,0,0,870,600.27h-6.05V587.81l-191.76-45.9",
            width : 6,
            length : 795.834,
            pings : [
                { tower : 'sNode7', progress :  0.244},
                { tower : 'tOne9', progress :  0.347},
                { tower : 'tTwo1', progress :  0.647},
                { tower : 'tThree1', progress :  0.99},
            ]
        },
        backhaulPing3 : {
            path : "M1072.7,452l78.12,45.63,72.88-118.42,177.48-34.79",
            width : 6,
            length : 795.834,
            pings : [
                { tower : 'stNode3', progress :  0.192},
                { tower : 'stNode1', progress :  0.536},
                { tower : 'stNode2', progress :  0.955},
            ]
        },
        backhaulPing4 : {
            path : "M1072.7,452,960.64,379.58,773,376.26l-116.93-87.5V266.62l-2.77-1.46",
            width : 6,
            length : 795.834,
            pings : [
                { tower : 'sNode5', progress :  0.269},
                { tower : 'sNode8', progress :  0.622},
                { tower : 'tSeven1', progress :  0.928},
            ]
        },
        backhaulPing5 : {
            path : "M1073.29,452.47l-.81-6.7L1055.4,298.58l-1.07.41-94.41,80.13-1.06.2-48.08,75.23,62.15,49.07,3.52.85",
            width : 6,
            length : 795.834,
            pings : [
                { tower : 'sNode6', progress :  0.327},
                { tower : 'sNode5', progress :  0.594},
                { tower : 'sNode7', progress :  0.795},
                { tower : 'tOne9', progress :  0.956},
            ]
        },
        backhaulPing6 : {
            path : "M1072.85,450.51l.18,90.32,77.08-46.44",
            width : 6,
            length : 795.834,
            pings : [
                { tower : 'sNode4', progress :  0.457},
                { tower : 'stNode3', progress :  0.95},
            ]
        },
        backhaulPing7 : {
            path : "M1072.85,450.51,907.6,456,775.05,377.26l-.3-81.09h-10.4l-.22-5.72-1.71-3.87",
            width : 6,
            length : 795.834,
            pings : [
                { tower : 'sNode7', progress :  0.355},
                { tower : 'sNode8', progress :  0.724},
                { tower : 'tFive1', progress :  0.969},
            ]
        },
        backhaulPing8 : {
            path : "M1073.32,452.47,1200.6,379.6a9.64,9.64,0,0,1,4.68-1.28l15.56-.19L1359,296.73l1.35.06,41.86,46.85-58.14,31,67.87,39.71-128.26,73.38v9.83l-24.77-14.88",
            width : 6,
            length : 795.834,
            pings : [
                { tower : 'stNode1', progress :  0.219},
                { tower : 'sNode1', progress :  0.436},
                { tower : 'stNode2', progress :  0.521},
                { tower : 'tNine16', progress :  0.625},
                { tower : 'tNine15', progress :  0.728},
                { tower : 'tNine11', progress :  0.827},
                { tower : 'tNine7', progress :  0.935},
                { tower : 'sNode2', progress :  0.99},
            ]
        },
        backhaul1 : {
            path : "M521.48,563.27v-5.94l-1.69-4.22L456.21,108.44l21.39,14.12L761.77,286.64l1.68,3.86.22,5.72h10.39l.3,81.14L907,456.17l165.34-5.52.17,90.36,77.13-46.45",
            width : 6,
            length : 1415.027,
            pings : [
                { tower : 'tFour1', progress :  0},
                { tower : 'satellite', progress :  0.300},
                { tower : 'tFive1', progress :  0.573},
                { tower : 'sNode8', progress :  0.639},
                { tower : 'sNode7', progress :  0.749},
                { tower : 'sNode3', progress :  0.866},
                { tower : 'sNode4', progress :  0.931},
                { tower : 'stNode3', progress :  0.993},
            ]
        },
        backhaul2 : {
            path : "M776.83,273.15l82.07,19.57,18.51,5.2.06,22.73,74.34,55.5,6.49,3.27-48.13,75.27,1.32,1.1,60.87,48a3.07,3.07,0,0,0,1.45,3.2l12.44,7.61a3.08,3.08,0,0,1,.64,4.74L985.36,521l-6.71,8.08a4.82,4.82,0,0,0-.38.51L976,533a8.9,8.9,0,0,0-1.05,2.2l-2.6,8.3s-2,6.53-2.32,7.47a23.25,23.25,0,0,1-9.75,11.61l-58,36s-5.55,3.49-6.22,3.79a16.93,16.93,0,0,1-8.55.91l-3.77-.69a45.5,45.5,0,0,0-14.44-2.17h-6V588L671.42,542.09",
            width : 6,
            length : 789.105,
            pings : [
                { tower : 'tFive1', progress :  0},
                { tower : 'tSix2', progress :  0.119},
                { tower : 'sNode5', progress :  0.269},
                { tower : 'sNode7', progress :  0.379},
                { tower : 'tOne9', progress :  0.477},
                { tower : 'tTwo1', progress :  0.723},
                { tower : 'tThree1', progress :  0.99},
            ]
        },
        backhaul3 : {
            path : "M1358.58,296.79l-138.2,81.44-15.56.19a9.65,9.65,0,0,0-4.69,1.28l-127.34,72.91-.8-6.7L1054.9,298.65",
            width : 6,
            length : 482.618,
            pings : [
                { tower : 'sNode1', progress :  0},
                { tower : 'stNode1', progress :  0.28},
                { tower : 'tOne6', progress :  0.467},
                { tower : 'sNode3', progress :  0.649},
                { tower : 'sNode6', progress :  0.968},
            ]
        },
        backhaul4 : {
            path : "M1072.55,544.05l-96.67-39.41-3.52-.86-62.19-49.09,48.13-75.27,1.08-.2,94.45-80.17",
            width : 6,
            length : 401.576,
            pings : [
                { tower : 'sNode4', progress :  0},
                { tower : 'tOne9', progress :  0.217},
                { tower : 'sNode7', progress :  0.433},
                { tower : 'sNode5', progress :  0.651},
                { tower : 'sNode6', progress :  0.969},
            ]
        },
        backhaul5 : {
            path : "M1400.82,344.45l-177.56,34.81-72.92,118.48-78.17-45.65L960.06,379.68l-187.75-3.32-117-87.54V266.66l-2.76-1.46c-45-25.79-121-32.37-121-32.37",
            width : 6,
            length : 1029.272,
            pings : [
                { tower : 'stNode2', progress :  0},
                { tower : 'stNode1', progress :  0.16},
                { tower : 'stNode3', progress :  0.29},
                { tower : 'sNode3', progress :  0.386},
                { tower : 'sNode5', progress :  0.519},
                { tower : 'sNode8', progress :  0.694},
                { tower : 'tSeven1', progress :  0.839},
                { tower : 'tSix1', progress :  0.987},
            ]
        },
        backhaul6 : {
            path : "M1359.94,296.85l41.85,46.88-58.17,31,67.9,39.72-128.29,73.38v9.88l-24.79-14.89",
            width : 6,
            length : 394.013,
            pings : [
                { tower : 'sNode1', progress :  0},
                { tower : 'stNode2', progress :  0.105},
                { tower : 'tNine16', progress :  0.303},
                { tower : 'tNine15', progress :  0.514},
                { tower : 'tNine11', progress :  0.689},
                { tower : 'tNine7', progress :  0.885},
                { tower : 'tNine7', progress :  0.885},
                { tower : 'sNode2', progress :  0.99},
            ]
        },
        backhaul7 : {
            path : "M1415.35,557.81,1349,522l66-38.05-61.9-36.05L1284.77,408l59.3-33.53",
            width : 6,
            length : 370.462,
            pings : [
                { tower : 'tNine5', progress :  0},
                { tower : 'tNine6', progress :  0.181},
                { tower : 'tNine10', progress :  0.402},
                { tower : 'tNine11', progress :  0.574},
                { tower : 'tNine12', progress :  0.796},
                { tower : 'tNine16', progress :  0.987},
            ]
        },
        backhaul8 : {
            path : "M1535.49,486.55l-57.31,33.88L1415,483.94,1349,522l-134.07-74.58",
            width : 6,
            length : 369.141,
            pings : [
                { tower : 'tNine13', progress :  0},
                { tower : 'tNine9', progress :  0.164},
                { tower : 'tNine10', progress :  0.359},
                { tower : 'tNine6', progress :  0.564},
                { tower : 'tNine7', progress :  0.77},
                { tower : 'tNine8', progress :  0.971},
            ]
        },
        backhaul9 : {
            path : "M1214.94,457.15v-9.74L1284.77,408l68.74,40,58-33.52,123.58,71.43",
            width : 6,
            length : 379.182,
            pings : [
                { tower : 'tNine8', progress :  0},
                { tower : 'tNine12', progress :  0.222},
                { tower : 'tNine11', progress :  0.428},
                { tower : 'tNine15', progress :  0.608},
                { tower : 'tNine14', progress :  0.8},
                { tower : 'tNine13', progress :  0.99},
            ]
        },
        core1 : {
            path : "M486.18,259.21c14.13-8.54,56.18-33.83,75.56-45s49-29.9,57.16-34.17,50.26-29.23,61.76-36.13,40.73-23.66,42.7-24.64S736.5,114,740.77,116s5.92,4.93,6.25,10.19,2.3,9.52,2.3,14.78,0,17.41,7.55,23,51.25,28.9,56.83,31.86,34.5,19.38,43,24,29.24,15.77,37.78,21,22.66,9.52,24,19.71.65,23,1.31,41.39",
            width : 15,
            length : 577.533,
            pings : [
                { tower : 'coreSNode1', progress :  0.1},
                { tower : 'coreStNode1', progress :  0.452},
                { tower : 'corePing1', progress : 0.9},
                
            ]
        },
        core2 : {
            path : "M845.55,360c9.21,5.58,137.39,87,148.83,93.66s29.59,18.14,43,26,32.1,19.26,47.45,28.75,74.81,45.22,85.41,51.64,17,12.28,43.27,13.4,90.72.55,113,.55,114.72.84,153.8.14,188.69-.42,204.6.28",
            width : 20,
            length : 774.861,
            pings : [
                { tower : 'corePing3', progress : 0.01},
            ]
        },
        core3 : {
            path : "M1311.56,366c-23.37-3.23-119.31-17.23-122.73-17.65s-11.7.85,7.61-10.07L1286,287.64l80.49-45.5c4.52-2.55,4.31-2.51,8.81-3.31,3.19-.57,11.94-2.17,15.55-3,1.5-.35,2.12-.57,1.12-.53-3.41.12-18.52.28-44.32.81s-183.6,3.7-193.88,3.64c-15.72-.11-13.68-3-26.8,5.33s-123.73,81.22-123.73,81.22",
            width : 15,
            length : 781.913,
            pings : [
                { tower : 'coreTowerNine2', progress :  0.155},
                { tower : 'coreTowerNine4', progress :  0.43},
                { tower : 'coreTower2', progress :  0.79},
                { tower : 'corePing2', progress : 0.95},
            ]
        },
        core5 : {
            path : "M1593.51,317.86c-22.34-12.8-138.4-74.65-167.27-91s-123.69-68.11-147.67-80.37S1180.94,92.76,1175,89.84s-6.2-5.46-5.42,3l2.3,24.83c.31,3.31,3.66,9.29-7,2.79s-62-35.78-63.87-36.32-8.31-8.33-5.33,2.37l39.5,141.72c2.78,10,5.7,8.07-8.54,17.35S998,324.13,988.16,333.39",
            width : 20,
            length : 947.206,
            pings : [
                { tower : 'coreTowerNine7', progress :  0.01},
                { tower : 'coreTowerNine5', progress :  0.25},
                { tower : 'coreTowerNine6', progress :  0.501},
                { tower : 'coreSNode2', progress :  0.638},
                { tower : 'coreTower2', progress :  0.801},
                { tower : 'corePing2', progress : 0.95},
                
            ]
        },
        core7 : {
            path : "M1366.9,448.23c7-10,7.81-12.6,15-19.14s20.28-19.36,29.19-28.82l35-37.1a24.6,24.6,0,0,1,8.6-5.47c8.67-2.89,44.72-12.33,55.29-15.36l28.48-8.17c3.16-.91,8.84-2.43,2.44-5.64s-80.72-44.56-89.43-49-59-31.1-62.68-33.15l-7.09-3.91c-1-.55-2-.8.06-3.09s3.13-3.27,3.39-3.52.33-.6-1.13-.54-14.64.31-24.06.49c-13.86.26-67.63,2.42-82.26,2.13s-120.68,1.71-127.2,1.83-11.06-.21-12.45-.14-1.79.32-3.76,1.58l-75.47,48.41c-9.12,5.85-64.86,42.89-67.95,44",
            width : 15,
            length : 836.631,
            pings : [
                { tower : 'coreTowerNine1', progress :  0.01},
                { tower : 'coreTowerNine3', progress :  0.253},
                { tower : 'coreTowerNine4', progress :  0.474},
                { tower : 'coreTower2', progress :  0.764},
                { tower : 'corePing2', progress : 0.95},
            ]
        },
        core8 : {
            path : "M818-10.83C807.6,6.31,769.75,66.09,763,76.7L746.5,102.81c-2.06,3.25-2.19,2.92-1.25,5.3s3.77,9.74,4.15,10.55.39,1.46.17,3.18-1.57,14.4-1.3,22.74,1.29,13.34,6,17.26,16.32,10.28,19.84,12.24l14.73,8.17c10.27,5.73,51.29,28.68,59.37,33.2s44.67,25.16,49.55,27.81,13.48,5.84,16.12,9.66,5.75,7.32,5.75,14.91.22,34.7-.48,39.6",
            width : 15,
            length : 436.334,
            pings : [
                { tower : 'coreStNode1', progress :  0.246},
                { tower : 'corePing1', progress : 0.95},
            ]
        },
        labs1 : {
            path : "M636.11,105.72v47.4L453.64,260.33l-1.92,8.77c118.95,136.22,120.71,136,125.75,141s21.08,32.07,27.85,32.07H739.14",
            width : 15,
            length : 557.196,
            pings : [
                { tower : 'labTower2', progress :  0.01},
                { tower : 'labSNode1', progress :  0.385},
                { tower : 'labStNode1', progress :  0.689},
                { tower : 'labPing1', progress :  0.91},
            ]
        },
        labs2 : {
            path : "M972.67,455.27s15,7.39,53.07,29.55S1135.21,546.39,1150,555s22.16,13.55,23.39,46.79,0,147.77,0,165,.55,15.44.55,15.44",
            width : 20,
            length : 481.257,
            pings : [
                { tower : 'labPing2', progress :  0.01},
            ]
        },
        labs3 : {
            path : "M750.52,546.88v8l-166-101.38,23.55-12.87,147,1.52",
            width : 15,
            length : 339.506,
            pings : [
                { tower : 'labTower1', progress :  0.01},
                { tower : 'labStNode1', progress :  0.514},
                { tower : 'labPing1', progress :  0.86},
            ]
        },
        labs5 : {
            path : "M-3.59,558.75l25-3.2c24.94-3.2,212.35-39.66,232.82-44.13s221.65-44.34,240.09-46.71c19.2-2.47,95.27-21.87,113.83-24.06,19.59-2.32,137.28,2.75,137.3-1.21",
            width : 15,
            length : 725.706,
            pings : [
                { tower : 'labStNode1', progress :  0.757},
                { tower : 'labPing1', progress :  0.945},
            ]
        },
        labs6 : {
            path : "M1173.38,784.05s.2-31,.2-48.22,1-100.78-.2-134S1164.77,563.63,1150,555s-86.2-48-124.25-70.19-53.07-29.55-53.07-29.55",
            width : 20,
            length : 481.245,
            pings : [
                { tower : 'labPing2', progress :  0.945},
            ]
        },
        mobile1: {
            path : "M145.5,166.44v6.86L100.38,183a56.08,56.08,0,0,0-8.11,3.12L45.89,212.8c-.45.26-3,1.38-3.28,1.82-.83,1.43-.21,2.09,1.21,2.92l99.39,54.55c2.17,1.23,5.45,1.74,5.46.12l19.44-17.86,4.5-.17,25.51,18.64c1.87,1.4,4.89,5.14,6.93,4l95.25-47.39c1.78-1,1.36-1.37,1.34-3.4l.61-11.78,3.16-3.65,47.29-11.68",
            width : 4,
            length : 488.914,
            pings : [
                { tower : 'tSix1', progress :  0},
                { tower : 'tEight1', progress :  0.537},
                { tower : 'tSeven1', progress :  0.873},
                { tower : 'sNode1', progress :  0.982},
            ]
        },
        mobile2: {
            path : "M592.4,177.74l-.2,6.65L627.52,222l-2.47,1.2-89.12,44.49-3.57-.38L365.23,201.52l-12.05-.15-11.89,12L318.17,265,312.58,275l-10.72,6.24-40.74,34.35-8.89,6,0-2.71",
            width : 4,
            length : 519.903,
            pings : [
                { tower : 'tOne2', progress :  0.019},
                { tower : 'stNode1', progress :  0.085},
                { tower : 'sNode3', progress :  0.294},
                { tower : 'sNode1', progress :  0.661},
                { tower : 'tSix2', progress :  0.834},
                { tower : 'tThree1', progress :  0.964},
            ]
        },
        mobile3: {
            path : "M889.5,304.26l-.73,6.74-8.93-10.57-62.42-7.84-9.75-49.84L808.2,210,707.72,186.21l-9.57-2-4.57,3.48-61.1,30.63-6.43,3L509.58,211.07l-1.26-27.68-9.72-6.16-85.16-63.84",
            width : 4,
            length : 623.856,
            pings : [
                { tower : 'tOne3', progress :  0.035},
                { tower : 'bThree1', progress :  0.121},
                { tower : 'stNode3', progress :  0.256},
                { tower : 'stNode2', progress :  0.431},
                { tower : 'stNode1', progress :  0.557},
                { tower : 'tTen1', progress :  0.751},
                { tower : 'tFive1', progress :  0.977},
            ]
        },
        mobile4: {
            path : "M488.91,277.52l-.61,5.63L525.41,271l11.25-4,89.72-44.42,12.79,3.77L766.25,266,785,276.66l-54.67,35",
            width : 4,
            length : 389.704,
            pings : [
                { tower : 'tOne1', progress :  0.035},
                { tower : 'sNode3', progress :  0.095},
                { tower : 'stNode1', progress :  0.375},
                { tower : 'bThree1', progress :  0.763},
                { tower : 'cloud', progress :  0.954},
            ]
        },
        mobile5: {
            path : "M1266.05,161.49l-.76,4-11.42,57.46-126.41,66.44-26.71-14.64-40.8-23a7.36,7.36,0,0,0-3.49.08l-7.49,5.58.18-12.43-2.45.06L967,289.61l-78.15-40.94,86.25-46.61-68.21-37.72",
            width : 4,
            length : 666.028,
            pings : [
                { tower : 'tOne4', progress :  0.0},
                { tower : 'sNode4', progress :  0.01},
                { tower : 'stNode4', progress :  0.283},
                { tower : 'tNine6', progress :  0.436},
                { tower : 'tNine3', progress :  0.594},
                { tower : 'tNine2', progress :  0.729},
                { tower : 'tNine5', progress :  0.876},
                { tower : 'tNine4', progress :  0.995-0.1},
            ]
        },
        mobile6: {
            path : "M1305.4,227.54l-43.56-3.36-8.66-2.15,12.52-55.25-1.09-3.53",
            width : 4,
            length : 112.958,
            pings : [
                { tower : 'tNine8', progress :  0.01},
                { tower : 'sNode4', progress :  0.377},
                { tower : 'tOne4', progress :  0.925},
            ]
        },
        mobile7: {
            path : "M1460.22,204.72v2l-19-8.13-10,26.7-.13-11.3-59.67,33.64-.54,3.27-1.64.93-64.56-34.71",
            width : 4,
            length : 209.476,
            pings : [
                { tower : 'tOne5', progress :  0.01},
                { tower : 'tNine10', progress :  0.224},
                { tower : 'tNine9', progress :  0.603},
                { tower : 'tNine8', progress :  0.992},
            ]
        }
        
    }
}


export const startupsPaths = [
    {
        d : "M699.14,506.22,869.8,415.15",
        start : 0,
        end : 0
    },
    {
        d : "M1129,405.91,1310.1,508.05",
        start : 0,
        end : 0
    },
    {
        d : "M1310.1,508.05,1129,405.91",
        start : 0,
        end : 0
    },
    {
        d : "M869.8,415.15,699.14,506.22",
        start : 0,
        end : 0
    }
]
// labPing1
// labPing2
export const highlights = {
    accessCrowdcell : {
        paths : [
            "M1287,406.46v69.07l-15.13,8-15.42-8.63V405.84l15.13-8Z",
            "M1329.1,509.7v-51l5.35-3.22.12-2.38,5.7-3.32,2.15,1.25,2.07-1.3,16.09,9.3v51l-14.94,9.14Z"
        ],
        fills : [
            "M1271.86,485a1.47,1.47,0,0,1-.73-.19l-15.42-8.63a1.48,1.48,0,0,1-.77-1.31V405.84a1.48,1.48,0,0,1,.8-1.32l15.13-8a1.51,1.51,0,0,1,1.43,0l15.43,8.63a1.51,1.51,0,0,1,.76,1.31v69.07a1.5,1.5,0,0,1-.79,1.33l-15.14,8A1.45,1.45,0,0,1,1271.86,485Zm-13.92-11,13.94,7.81,13.61-7.21V407.34l-13.94-7.8-13.61,7.21Z",
            "M1345.61,520.34a3.57,3.57,0,0,1-1.76-.47l-14.47-8.28a3.55,3.55,0,0,1-1.78-3.07V459.85a3.55,3.55,0,0,1,1.71-3l3.44-2.08a.54.54,0,0,0,.26-.43v-.2a3.53,3.53,0,0,1,1.74-2.87l3.73-2.17a3.55,3.55,0,0,1,3.55,0,.58.58,0,0,0,.62,0,3.5,3.5,0,0,1,3.64-.06l14,8.1a3.54,3.54,0,0,1,1.77,3.06v48.68a3.56,3.56,0,0,1-1.69,3l-12.94,7.92A3.56,3.56,0,0,1,1345.61,520.34Zm-5.36-68.73a.54.54,0,0,0-.25.06l-3.72,2.18a.52.52,0,0,0-.27.43v.2a3.54,3.54,0,0,1-1.7,2.84l-3.44,2.08a.52.52,0,0,0-.26.45v48.67a.54.54,0,0,0,.27.46l14.48,8.29a.55.55,0,0,0,.54,0l12.93-7.92a.51.51,0,0,0,.26-.45V460.21a.57.57,0,0,0-.27-.47l-14-8.1a.54.54,0,0,0-.55,0,3.53,3.53,0,0,1-3.64.07A.68.68,0,0,0,1340.25,451.61Z"
        ]
    },
    accessOpenCellular : {
        paths : [
            "M528.4,383.38a15.84,15.84,0,0,1-8-2.15L406.81,315.09a10.23,10.23,0,0,1-4.25-8.58v-7.62a10.92,10.92,0,0,1,5.37-8.76L556.3,204A17.62,17.62,0,0,1,574,204l119.68,69.42a9.78,9.78,0,0,1,0,16.93L536.35,381.25A15.85,15.85,0,0,1,528.4,383.38ZM565.2,204.6a14.62,14.62,0,0,0-7.38,2h0L409.44,292.73a7.87,7.87,0,0,0-3.88,6.2v7.58a7.29,7.29,0,0,0,2.82,6l113.56,66.1a12.89,12.89,0,0,0,12.91,0l157.36-90.93a6.79,6.79,0,0,0,0-11.74L572.53,206.56A14.62,14.62,0,0,0,565.2,204.6Z",
        ]
    },
    accessOpenRAN : {
        paths : [
            "M1291.84,527.77v2.47a.62.62,0,0,1-.31.6l-5.68,3.28a1.27,1.27,0,0,1-1.29,0L1281,532l-2,1.3a2.93,2.93,0,0,1,.62,1.74c0,2.28-3,4.13-6.61,4.13a9.62,9.62,0,0,1-3.68-.7l-32.67,21.41,4.51,4.13V565c7.79,4.83,9.05,12.74-2.12,18.11l-24.13,13.06a25.73,25.73,0,0,1-24.48.13l-17.17-8.92c-10.14-4.79-10.61-13.9-1.42-18.58.46-.23,25.39-14.07,25.39-14.07a33.47,33.47,0,0,1,4.56-2.23l8.77-4.9,15.89,2.88,8.53,7.81,32.36-21.2a3,3,0,0,1-1-2.12,3.12,3.12,0,0,1,1-2.19l-2.25,1.3a1.27,1.27,0,0,1-1.29,0l-5.56-3.31a.6.6,0,0,1-.3-.62v-2.42h0s0-.06,0-.09a.54.54,0,0,1,0-.22V525h0a.63.63,0,0,1,.3-.59l5.68-3.28a1.29,1.29,0,0,1,1.29,0l3.88,2.31,1.55-10.6-1.93-1.23v-9.51l0-.07,2.4-1.47.91.52,0,5.21.19-.09v-15.4h.3v15.22l.48-.29h.19l.43.24V496h.33v10.19l.35.19v-1.22l0-.1v-3.83l0-.05,1-.56,2.33,1.37v7.7l-.06.1v2l-2,1.25L1278.5,531l.38-.25a.59.59,0,0,1-.18-.53v-2.41h0a.6.6,0,0,1,.29-.59l5.68-3.28a1.27,1.27,0,0,1,1.29,0l5.57,3.31a.63.63,0,0,1,.3.55Z",
        ]
    },
    accessFronthaul : {
        paths : [
            "M1088.81,284l-10.54,5.7a11.26,11.26,0,0,1-10.69.06l-3.43-1.77-4,5.86,4.08,2.43a.84.84,0,0,1,.43.77h0v1.82a3.85,3.85,0,0,1,.53,1.91c0,3.27-4.41,5.93-9.84,5.93s-9.85-2.66-9.85-5.93a4.06,4.06,0,0,1,.79-2.33V297a.87.87,0,0,1,.43-.82l.61-.36-40.89-41.18a11.17,11.17,0,0,1-3.19.46c-4.08,0-7.39-2.07-7.39-4.62a3.06,3.06,0,0,1,.35-1.41l-1.28.74a1.27,1.27,0,0,1-1.29,0l-5.57-3.31a.63.63,0,0,1-.3-.62v-2.42h0v-.09a.81.81,0,0,1,0-.22v-2.42h0a.6.6,0,0,1,.3-.59l5.68-3.28a1.29,1.29,0,0,1,1.29,0l3.87,2.3,1.56-10.59-1.95-1.2v-9.66l2.44-1.5,1,.64V222l.16-.08V206.47h.29v15.24l.46-.25h.31l.33.2v-10h.31v10.18l.33.14v-1.14l.08-.12V216.9s0-.1.05-.1.83-.49.85-.49h.08l2.31,1.44s.06.11.06.11l-.06,9.64-2,1.25,2.9,18.45c.16.1.31.21.46.32l.86-.46-1-.56a.64.64,0,0,1-.3-.63v-2.42h0a.6.6,0,0,1,.3-.59l5.68-3.28a1.29,1.29,0,0,1,1.29,0l5.57,3.31a.61.61,0,0,1,.3.55h0v2.48a.61.61,0,0,1-.3.6l-5.69,3.28a1.27,1.27,0,0,1-1.29,0l-2.42-1.44-1.62.85a3,3,0,0,1,.28,1.26,4,4,0,0,1-2.25,3.32l41,40.83,5.25-3a1.78,1.78,0,0,1,1.81,0l1.82,1.08,3.88-5.75-4.68-2.4c-4.29-2-4.6-5.82-1-7.91V273.9l.06,0,0,0-.08,0,8.43-7.25,8-2,16.09,8.55v2.49l.26.14C1093.09,277.93,1093.84,281.53,1088.81,284Z",
        ]
    },
    accessOpenRAN5GNR : {
        paths : [
            "M1291.84,527.77v2.47a.62.62,0,0,1-.31.6l-5.68,3.28a1.27,1.27,0,0,1-1.29,0L1281,532l-2,1.3a2.93,2.93,0,0,1,.62,1.74c0,2.28-3,4.13-6.61,4.13a9.62,9.62,0,0,1-3.68-.7l-32.67,21.41,4.51,4.13V565c7.79,4.83,9.05,12.74-2.12,18.11l-24.13,13.06a25.73,25.73,0,0,1-24.48.13l-17.17-8.92c-10.14-4.79-10.61-13.9-1.42-18.58.46-.23,25.39-14.07,25.39-14.07a33.47,33.47,0,0,1,4.56-2.23l8.77-4.9,15.89,2.88,8.53,7.81,32.36-21.2a3,3,0,0,1-1-2.12,3.12,3.12,0,0,1,1-2.19l-2.25,1.3a1.27,1.27,0,0,1-1.29,0l-5.56-3.31a.6.6,0,0,1-.3-.62v-2.42h0s0-.06,0-.09a.54.54,0,0,1,0-.22V525h0a.63.63,0,0,1,.3-.59l5.68-3.28a1.29,1.29,0,0,1,1.29,0l3.88,2.31,1.55-10.6-1.93-1.23v-9.51l0-.07,2.4-1.47.91.52,0,5.21.19-.09v-15.4h.3v15.22l.48-.29h.19l.43.24V496h.33v10.19l.35.19v-1.22l0-.1v-3.83l0-.05,1-.56,2.33,1.37v7.7l-.06.1v2l-2,1.25L1278.5,531l.38-.25a.59.59,0,0,1-.18-.53v-2.41h0a.6.6,0,0,1,.29-.59l5.68-3.28a1.27,1.27,0,0,1,1.29,0l5.57,3.31a.63.63,0,0,1,.3.55Z",
        ]
    },
    accessWiFi : {
        paths : [
            "M1293.8,322.28l-24.93-14.71c2.07-1.48,2.67-5.2,1.3-8.89-1.53-4.1-4.94-6.6-7.61-5.56l-2.39,1.37h0c-2.67,1-3.6,5.2-2.07,9.31a11.22,11.22,0,0,0,2,3.44l-13.93,8.3a2.64,2.64,0,0,0,0,4.58l25,14.77a9.14,9.14,0,0,0,9.07,0l13.54-8.07A2.64,2.64,0,0,0,1293.8,322.28Z",
            // "M1281.05,300.88a2,2,0,1,1,2,2A2,2,0,0,1,1281.05,300.88Z",
            // "M1283.06,292.73a8.15,8.15,0,0,1,8.15,8.15",
            // "M1283.06,286.22a14.66,14.66,0,0,1,14.66,14.66"
        ],
        // postFill : [
        //     "M1281.05,300.88a2,2,0,1,1,2,2A2,2,0,0,1,1281.05,300.88Z",
        //     "M1283.06,292.73a8.15,8.15,0,0,1,8.15,8.15z",
        //     "M1283.06,286.22a14.66,14.66,0,0,1,14.66,14.66z"
        // ]

    },
    backhaulMillimeter : {
        paths : [
            "M1420.63,577.34a8.67,8.67,0,0,1-8.8,0L1224.49,469.1V458.65l-8.94-5.18v-5.11l-.34.22-.84-.46v-2.64l.13-.14v-.53l.85-.42.31.17v.27h.8v-.29l.39-.15.12.1,13.83-7.42V406.16l15.4-8.81,9.76,5.53v-40.1l14.81-8.63.61-.4.61.39v-.79l.57-.33.57.3v1.32l.31.19v-.94l.79-.5.6.35v2l1,.5v-2.44l.6-.34.79.52v3l10,5.84,0,40.77,10.78-6.12,6.39,3.54V363.56l15.38-8.81,9.78,5.51V319.85l15.29-8.91,16,9.24v40.68l10.7-6.11,1.62.83,1.92-1.12v-2.4l5.76-3.23,2,1.13,2.13-1.25,16,9.24v31.56l9.52-5.39,9.7,5.61v-.6l15.86-9.27,3.1,2.48,12.3,7.11v45.2l.45-.26,10,5.57V416.37l15.28-8.91,16,9.24v27.79l9.78-5.7,16,9.24v27.66l14,8.14,3.1,1.79,2.26,1.38c2.24,1.34,3.28,4.16,3.68,5.58a5.83,5.83,0,0,1,.2,1.5v7a8.41,8.41,0,0,1-.24,2.06,9.67,9.67,0,0,1-4.43,5.86Z",
        ]
    },
    backhaulOpen : {
        paths : [
            "M970.15,377.74v-1.45a.89.89,0,0,0-.42-.85l-7.66-4.56a1.82,1.82,0,0,0-1.86,0l-7.62,4.41a.88.88,0,0,0-.61.92v1.9a3.43,3.43,0,0,0-.31.78l-5-.08v-2.23a1,1,0,0,0-.36-.86l-7.65-4.55a1.87,1.87,0,0,0-1.91,0l-7.64,4.43a1,1,0,0,0-.51.95v1.94l-9.09-.16v-9l-8.36,5.46-.64,1.89v1.5l-9-.17V372l-9.77,5.69v0l-17.87-.37h-.5a5.25,5.25,0,1,0-9.49-3.09,5.2,5.2,0,0,0,.9,2.93l-18.91-.34-9.5-5.52-.5,1.5,0,3.84-8.44-.16v-2.7L819,368.38l-.63,1.92v6.06l-11.9-.23v-2.38l-9-5.34s-8.38,4.8-8.77,5.07a.9.9,0,0,0-.35.76v1.55l-6.14-.11v-1.07a.92.92,0,0,0-.42-.91l-7.66-4.57a1.82,1.82,0,0,0-1.83,0l-7.7,4.45-.21.12a1,1,0,0,0-.36.86v1.8a3.76,3.76,0,0,0-.54,1.91c0,3.2,4.3,5.79,9.61,5.79s9.61-2.59,9.61-5.79a2.73,2.73,0,0,0,0-.41l5.71.1a1,1,0,0,0,.38.47l7.68,4.56a1.73,1.73,0,0,0,1.73,0L806,378.5a.9.9,0,0,0,.27-.21l12.11.13v8.38l8.33-5.65.66-1.85v-.71l8.44.16v5.44l8.89-5.28,23,.44a5.19,5.19,0,0,0,2.59,0l22.22.42,8.49,5,.48-1.45V380l9.06.17V382l8.35,5.64.64-1.89v-5.42l9.5.16,7.56,4.5a1.94,1.94,0,0,0,2,0l7.25-4.2,5.9.1c.82,2.71,4.75,4.77,9.49,4.77,5.34,0,9.67-2.6,9.67-5.82A3.88,3.88,0,0,0,970.15,377.74ZM866,374.29a3.1,3.1,0,1,1,3.76,3l-1.4,0A3.11,3.11,0,0,1,866,374.29Z",
        ]
    },
    backhaulMicrowave : {
        paths : [
            // "M658.75,286.75l-3.24-21-.6-.28V257h-.29v8.52l-.09,0a4.68,4.68,0,0,0-.3-1.56,4.81,4.81,0,0,0-.49-1V252.73h-.28v9.88a2.26,2.26,0,0,0-2.42-1l-1.62.93c-.18.07.67-.81.53-.68-27.29-14.5-64.5-24.48-110.64-29.67l-4.22-.46c.11-1-1.24-.9-1.92-1.17l-1.31-.75-.15,0-.51-3.08-.65-.35V217h-.33l0,9.14-.35-.18h-.21l-.26.14v-1.57l-.16-.09v-12.2h-.33v12l-1.58-.92-.83.47,0,6.73,1.25.76-2.88,19a3.51,3.51,0,0,0-1.47,2.73c0,2.34,2.94,4.25,6.56,4.25.92,0,8.23,39.61,8.23,39.61l2,6.36,1.22.69-2.48,16.49a3.14,3.14,0,0,0-1.6,2.53c0,2.07,2.67,3.74,6,3.74s6-1.67,6-3.74a3.06,3.06,0,0,0-1.41-2.4l-2-10.81,104.46-35.09v.52l1.1.68-1.58,10.68a3.2,3.2,0,0,0-1.67,2.59c0,2.06,2.68,3.74,6,3.74s6-1.68,6-3.74A3.06,3.06,0,0,0,658.75,286.75Zm-114.2,18.91.54-3v0a1.6,1.6,0,0,0,1.1,0l1.62-.93c1.12-.43,1.5-2.17.86-3.89s-2.06-2.77-3.18-2.33l-1.42.81,0-.07v-5.4h-.31v5.66a1.83,1.83,0,0,0-.87,1.09v-11h-.35V297.4l1.86-.08-7.89-41.22c1.23-.77,0-1.85,0-3a3.41,3.41,0,0,0-1.14-2.46l-.3-.25-2.31-15c.19-.14,1.37,1.67,1.54,1.47,2.89.3,3.77.4,4.81.52,46,5.18,83.1,15.14,110.18,29.6,0,.49-.9-1-.7-.45a4,4,0,0,0,1.78,2.2l-1,1.81Z",
            "M884.35,321.75c0,1.88-1.89,3.47-4.54,4.1h0l-.7.13-.13,0-.66.08h-.15c-.26,0-.53,0-.8,0h0c-.27,0-.54,0-.8,0h-.15l-.66-.08-.13,0-.7-.13h0c-2.65-.63-4.54-2.22-4.54-4.1,0-1.42,1.09-2.67,2.76-3.47l2.46-16.49-1.28-.79-1-2.31-96.9-23.26c.61-.81.2-.76-.17-.9l.1.5a1.21,1.21,0,0,1,.5.07c.34.13,1.23.74,1.44.82,1,.39,1.33,1.93.77,3.46a3.49,3.49,0,0,1-1.69,2L779.4,295a2.87,2.87,0,0,1,.89,2c0,2.14-2.77,3.87-6.18,3.87s-6.19-1.73-6.19-3.87a2.91,2.91,0,0,1,1-2.06l1.43-9.72a1.78,1.78,0,0,1,.22-1.49l0-.27.81-5.38,0-.08-.15.08c-1,.38-2.19-.52-2.74-2s-.22-3,.74-3.35l1.39-.8a1.8,1.8,0,0,1,1.88.62l.21-1-.47.27c-.59.23-1.35-.32-1.69-1.23s-.13-1.83.46-2.06l.86-.49a1.15,1.15,0,0,1,1.24.48V255h.39v14.1a.54.54,0,0,1,.05.11,2,2,0,0,1,.14.58h.45a4.17,4.17,0,0,1,.28-.47v-9.5h.43l0,8.27.11.56a1.75,1.75,0,0,1,1.75-.48l1.44.83c1,.38,1.33,1.92.77,3.45,0,.05,0,.09.93-.86l95.33,22.88-1-.27.86-.45,1.73,1.08V283.17h.29V295l.12.07v1.57l.3-.17h.15l.37.2v-8.91h.29v9.05l.63.33.45,3,.18,0,1.27.72c.87.34,1.17,1.7.67,3a3.17,3.17,0,0,1-1.27,1.63l0,.22,2,12.93A3.79,3.79,0,0,1,884.35,321.75Z",
            "M869.2,602.71c0,2.08-2.7,3.77-6,3.77s-6-1.69-6-3.77a3.08,3.08,0,0,1,1.35-2.39l1.79-9.81-187-45c-.38.7-.92-.79-1.48-.61l2.19,14.25.27.23a2.88,2.88,0,0,1,1.06,2.12c0,2.09-2.71,3.78-6,3.78s-6-1.69-6-3.78a3.06,3.06,0,0,1,1.36-2.38l2.65-17.48-1.15-.7.05-6.51.76-.44,1.6.94s0-11.08,0-11.08h.38v11.38l.06,0v.56a1.54,1.54,0,0,1,.78-.6v-7h.29v6.85l.8-.43c.86-.31,2,.49,2.45,1.82s.19,2.68-.67,3l-.14.08.72.39.17.19a1.86,1.86,0,0,1,.34.69L860.68,585.7l.33-2.15-1.26.72c-1.14.44-2.6-.62-3.25-2.38s-.26-3.54.88-4L859,577c1-.37,2.16.35,2.9,1.64l.42-.16c0-1.92,0-13.39,0-13.39h.37v13.13l.84-.5v-8.25h.29v8.28l1.63-1,.84.43v6.4l-1,.61,2.48,16.18.27.23A2.9,2.9,0,0,1,869.2,602.71Z",
            // "M658.75,286.75l-3.24-21-.6-.28V257h-.29v8.52l-.09,0a4.68,4.68,0,0,0-.3-1.56,4.81,4.81,0,0,0-.49-1V252.73h-.28v9.88a2.26,2.26,0,0,0-2.42-1l-1.62.93c-.18.07.67-.81.53-.68-27.29-14.5-64.5-24.48-110.64-29.67l-4.22-.46c.11-1-1.24-.9-1.92-1.17l-1.31-.75-.15,0-.51-3.08-.65-.35V217h-.33l0,9.14-.35-.18h-.21l-.26.14v-1.57l-.16-.09v-12.2h-.33v12l-1.58-.92-.83.47,0,6.73,1.25.76-2.88,19a3.51,3.51,0,0,0-1.47,2.73c0,2.34,2.94,4.25,6.56,4.25.92,0,8.23,39.61,8.23,39.61l2,6.36,1.22.69-2.48,16.49a3.14,3.14,0,0,0-1.6,2.53c0,2.07,2.67,3.74,6,3.74s6-1.67,6-3.74a3.06,3.06,0,0,0-1.41-2.4l-2-10.81,104.46-35.09v.52l1.1.68-1.58,10.68a3.2,3.2,0,0,0-1.67,2.59c0,2.06,2.68,3.74,6,3.74s6-1.68,6-3.74A3.06,3.06,0,0,0,658.75,286.75Zm-114.2,18.91.54-3v0a1.6,1.6,0,0,0,1.1,0l1.62-.93c1.12-.43,1.5-2.17.86-3.89s-2.06-2.77-3.18-2.33l-1.42.81,0-.07v-5.4h-.31v5.66a1.83,1.83,0,0,0-.87,1.09v-11h-.35V297.4l1.86-.08-7.89-41.22c1.23-.77,0-1.85,0-3a3.41,3.41,0,0,0-1.14-2.46l-.3-.25-2.31-15c.19-.14,1.37,1.67,1.54,1.47,2.89.3,3.77.4,4.81.52,46,5.18,83.1,15.14,110.18,29.6,0,.49-.9-1-.7-.45a4,4,0,0,0,1.78,2.2l-1,1.81Z"
            // "M658.75,286.75l-3.24-21-.6-.28V257h-.29v8.52l-.09,0a4.68,4.68,0,0,0-.3-1.56,4.81,4.81,0,0,0-.49-1V252.73h-.28v9.88a2.26,2.26,0,0,0-2.42-1l-1.62.93a1.51,1.51,0,0,0-.47.32c-27.29-14.5-64.5-24.48-110.64-29.67l-4.22-.46a2,2,0,0,0-.92-2.17l-1.31-.75-.15,0-.51-3.08-.65-.35V217h-.33l0,9.14-.35-.18h-.21l-.26.14v-1.57l-.16-.09v-12.2h-.33v12l-1.58-.92-.83.47,0,6.73,1.25.76-2.88,19a3.51,3.51,0,0,0-1.47,2.73c0,2.34,2.94,4.25,6.56,4.25a9.76,9.76,0,0,0,2.59-.35l7.64,40,0,6.36,1.22.69-2.48,16.49a3.14,3.14,0,0,0-1.6,2.53c0,2.07,2.67,3.74,6,3.74s6-1.67,6-3.74a3.06,3.06,0,0,0-1.41-2.4l-2-12.81,104.46-35.09v2.52l1.1.68-1.58,10.68a3.2,3.2,0,0,0-1.67,2.59c0,2.06,2.68,3.74,6,3.74s6-1.68,6-3.74A3.06,3.06,0,0,0,658.75,286.75Zm-113.2,18.91-.46-3v0a1.6,1.6,0,0,0,1.1,0l1.62-.93c1.12-.43,1.5-2.17.86-3.89s-2.06-2.77-3.18-2.33l-1.42.81,0-.07v-5.4h-.31v5.66a1.83,1.83,0,0,0-.87,1.09v-11h-.35V297.4l-.14-.08-7.89-41.22a3.72,3.72,0,0,0,2-3,3.41,3.41,0,0,0-1.14-2.46l-.3-.25-2.31-15a3.37,3.37,0,0,0,.54-.53c2.89.3,3.77.4,4.81.52,46,5.18,83.1,15.14,110.18,29.6a4.67,4.67,0,0,0,.3,1.55,4,4,0,0,0,1.78,2.2v1.81Z",
            // "M884.35,321.75c0,1.88-1.89,3.47-4.54,4.1h0l-.7.13-.13,0-.66.08h-.15c-.26,0-.53,0-.8,0h0c-.27,0-.54,0-.8,0h-.15l-.66-.08-.13,0-.7-.13h0c-2.65-.63-4.54-2.22-4.54-4.1,0-1.42,1.09-2.67,2.76-3.47l2.46-16.49-1.28-.79v-3.31l-96.9-23.26a1.47,1.47,0,0,1-1.17.1l.1.5a1.21,1.21,0,0,1,.5.07c.34.13,1.23.74,1.44.82,1,.39,1.33,1.93.77,3.46a3.49,3.49,0,0,1-1.69,2L779.4,295a2.87,2.87,0,0,1,.89,2c0,2.14-2.77,3.87-6.18,3.87s-6.19-1.73-6.19-3.87a2.91,2.91,0,0,1,1-2.06l1.43-9.72a1.78,1.78,0,0,1,.22-1.49l0-.27.81-5.38,0-.08-.15.08c-1,.38-2.19-.52-2.74-2s-.22-3,.74-3.35l1.39-.8a1.8,1.8,0,0,1,1.88.62l.21-1-.47.27c-.59.23-1.35-.32-1.69-1.23s-.13-1.83.46-2.06l.86-.49a1.15,1.15,0,0,1,1.24.48V255h.39v14.1a.54.54,0,0,1,.05.11,2,2,0,0,1,.14.58h.45a4.17,4.17,0,0,1,.28-.47v-9.5h.43l0,8.27.11.56a1.75,1.75,0,0,1,1.75-.48l1.44.83c1,.38,1.33,1.92.77,3.45,0,.05,0,.09-.07.14l95.33,22.88v-1.27l.86-.45,1.73,1.08V283.17h.29V295l.12.07v1.57l.3-.17h.15l.37.2v-8.91h.29v9.05l.63.33.45,3,.18,0,1.27.72c.87.34,1.17,1.7.67,3a3.17,3.17,0,0,1-1.27,1.63l0,.22,2,12.93A3.79,3.79,0,0,1,884.35,321.75Z",
            // "M869.2,602.71c0,2.08-2.7,3.77-6,3.77s-6-1.69-6-3.77a3.08,3.08,0,0,1,1.35-2.39l1.79-11.81-187-45a2.81,2.81,0,0,1-1.48,1.39l2.19,14.25.27.23a2.88,2.88,0,0,1,1.06,2.12c0,2.09-2.71,3.78-6,3.78s-6-1.69-6-3.78a3.06,3.06,0,0,1,1.36-2.38l2.65-17.48-1.15-.7.05-6.51.76-.44,1.6.94s0-11.08,0-11.08h.38v11.38l.06,0v.56a1.54,1.54,0,0,1,.78-.6v-7h.29v6.85l.8-.43c.86-.31,2,.49,2.45,1.82s.19,2.68-.67,3l-.14.08.72.39.17.19a1.86,1.86,0,0,1,.34.69L860.68,585.7l.33-2.15-1.26.72c-1.14.44-2.6-.62-3.25-2.38s-.26-3.54.88-4L859,577c1-.37,2.16.35,2.9,1.64l.42-.16c0-1.92,0-13.39,0-13.39h.37v13.13l.84-.5v-8.25h.29v8.28l1.63-1,.84.43v6.4l-1,.61,2.48,16.18.27.23A2.9,2.9,0,0,1,869.2,602.71Z"
            "M658.75,286.75l-3.24-21-.6-.28V257h-.29v8.52l-.09,0a4.68,4.68,0,0,0-.3-1.56,4.81,4.81,0,0,0-.49-1V252.73h-.28v9.88a2.26,2.26,0,0,0-2.42-1l-1.62.93c-.18.07.67-.81.53-.68-27.29-14.5-64.5-24.48-110.64-29.67l-4.22-.46c.11-1-1.24-.9-1.92-1.17l-1.31-.75-.15,0-.51-3.08-.65-.35V217h-.33l0,9.14-.35-.18h-.21l-.26.14v-1.57l-.16-.09v-12.2h-.33v12l-1.58-.92-.83.47,0,6.73,1.25.76-2.88,19a3.51,3.51,0,0,0-1.47,2.73c0,2.34,2.94,4.25,6.56,4.25.92,0,8.23,39.61,8.23,39.61l2,6.36,1.22.69-2.48,16.49a3.14,3.14,0,0,0-1.6,2.53c0,2.07,2.67,3.74,6,3.74s6-1.67,6-3.74a3.06,3.06,0,0,0-1.41-2.4l-2-10.81,104.46-35.09v.52l1.1.68-1.58,10.68a3.2,3.2,0,0,0-1.67,2.59c0,2.06,2.68,3.74,6,3.74s6-1.68,6-3.74A3.06,3.06,0,0,0,658.75,286.75Zm-9.41-16.29,1-1.81a4,4,0,0,1-1.78-2.2c-.2-.53.71.94.7.45-27.08-14.46-64.14-24.42-110.18-29.6-1-.12-1.92-.22-4.81-.52-.17.2-1.35-1.61-1.54-1.47l2.31,15,.3.25a3.41,3.41,0,0,1,1.14,2.46c0,1.19,1.24,2.27,0,3l7.89,41.22-1.86.08V286.59h.35v11a1.83,1.83,0,0,1,.87-1.09v-5.66h.31v5.4l0,.07,1.42-.81c1.12-.44,2.54.61,3.18,2.33s.26,3.46-.86,3.89l-1.62.93a1.6,1.6,0,0,1-1.1,0v0l-.54,3Z"
        ],
        postFill : [
            "M544.55,305.66l.54-3v0a1.6,1.6,0,0,0,1.1,0l1.62-.93c1.12-.43,1.5-2.17.86-3.89s-2.06-2.77-3.18-2.33l-1.42.81,0-.07v-5.4h-.31v5.66a1.83,1.83,0,0,0-.87,1.09v-11h-.35V297.4l1.86-.08-7.89-41.22c1.23-.77,0-1.85,0-3a3.41,3.41,0,0,0-1.14-2.46l-.3-.25-2.31-15c.19-.14,1.37,1.67,1.54,1.47,2.89.3,3.77.4,4.81.52,46,5.18,83.1,15.14,110.18,29.6,0,.49-.9-1-.7-.45a4,4,0,0,0,1.78,2.2l-1,1.81Z"
        ]
        
    },
    core : {
        paths : [
            "M1270.1,576.45v5.36a6.81,6.81,0,0,1-3.44,5.64l-51.6,29.16a11.61,11.61,0,0,1-11.55,0l-51.81-30a6.82,6.82,0,0,1-3.45-5.94l.06-4.37a6.39,6.39,0,0,1,0-1.8h0a6.81,6.81,0,0,1,3.34-6.91l51.69-29.33a11.62,11.62,0,0,1,11.72,0l51.64,30.12a6.78,6.78,0,0,1,3.33,6.73h0v1.27Z",
            "M790.32,422l-16.73,9a17.83,17.83,0,0,1-17,.1l-16-8.2c-7-3.32-7.36-9.64-1-12.88.33-.17,17.61-9.76,17.61-9.76,6.08-3.53,11.21-3.55,17-.09l17,8.91C797.12,412.49,798.3,418.2,790.32,422Z",
            "M1097.67,284.33,1081,293.39a17.83,17.83,0,0,1-17,.09l-16-8.2c-7-3.32-7.36-9.64-1-12.88.32-.16,17.6-9.76,17.6-9.76,6.08-3.52,11.22-3.55,17-.09,0,0,16.72,8.75,17,8.92C1104.48,274.78,1105.66,280.5,1097.67,284.33Z"
        ]
    },
    startups : {
        paths : [
            "M1492.13,383.26l3,1.71,20.42,11.62,21.64,12.33a5.19,5.19,0,0,1,.82.37,9.55,9.55,0,0,1,2.26,1.76c2.55,2.67,5.29,8.37,2.09,19.94-.1.34-.19.69-.3,1l-25.48,15.55-12.5-7.23-9.73,6.3-.16-28.55-16.08-9.3-15.39,9-.23,23.75-9.8-5.6-.4-37,29.5-17.31S1486.38,378.44,1492.13,383.26Z"
        ]
    },
    labs : {
        paths : [
            "M1441.2,322.8l37-19.63s4.34-2.15,13.77,2.19,48.65,27.25,48.65,27.25,4.37,5.26,3.3,11.95-.58,8-4.85,10.52-50.41,30.11-50.41,30.11-16.49-8.67-21.05-11.81-20.73-11.87-20.73-11.87l.4-34.33Z"
        ],
        fills : [
            "M1488.66,386.69a1.45,1.45,0,0,1-.7-.17c-.67-.35-16.62-8.75-21.2-11.9s-20.43-11.7-20.59-11.79a1.5,1.5,0,0,1-.79-1.34l.39-33.55-5.45-3.93a1.5,1.5,0,0,1,.17-2.54l37-19.63c.55-.27,5.3-2.35,15.1,2.16,9.39,4.32,47.17,26.38,48.78,27.32a1.55,1.55,0,0,1,.39.33c.2.24,4.79,5.87,3.63,13.14-.16,1-.28,1.84-.39,2.61-.65,4.58-.92,6.48-5.18,9s-49.94,29.84-50.4,30.11A1.51,1.51,0,0,1,1488.66,386.69Zm-40.27-26.07c3.35,1.82,16,8.75,20.07,11.53,3.89,2.67,16.9,9.6,20.15,11.32,5.9-3.53,45.76-27.38,49.7-29.69,3-1.76,3.11-2.43,3.73-6.8.11-.78.24-1.66.4-2.66.84-5.24-2.12-9.61-2.83-10.56-3.38-2-39.4-22.95-48.26-27-8.5-3.91-12.47-2.22-12.51-2.21L1444,323l4.13,3a1.49,1.49,0,0,1,.62,1.24Z"
        ]
    },
    coreEdge : {
        paths : [
            "M845.55,360l35.88-21.56a2.52,2.52,0,0,1,2.57,0l9.26,5.56a2.48,2.48,0,0,1,1.22,2.14v15.27a2.5,2.5,0,0,1-1.2,2.13l-27,16.49a2.51,2.51,0,0,1-3-.35l-10.16-9.9-.12-.12-5.28-4.48a2.49,2.49,0,0,0-1.23-.57h0A2.5,2.5,0,0,1,845.55,360Z"
        ]
    },
    coreEnd : {
        paths : [
            "M927.81,343.79l45.05-26.54a3.52,3.52,0,0,1,3.71.09l7.33,4.82a3.51,3.51,0,0,1,1.57,2.93v19.8a3.48,3.48,0,0,1-1.7,3L940.26,374a3.5,3.5,0,0,1-3.64,0l-8.88-5.49a3.52,3.52,0,0,1-1.66-3V346.81A3.51,3.51,0,0,1,927.81,343.79Z"
        ]
    },
    startupsHighlight : {
        paths : [
            "M873.17,386.73,1018,304.43l53.4,27.44v-4l3.5-1.32,56.91,28.65V395.5l-3.57.93v30.63l-104.57,57.83-27.07-13.2-48.32,27.2-.13,31.69a6.88,6.88,0,0,1-3.3.52,15.85,15.85,0,0,1-3.57-.66l.4-30.5-34.46-19.27-8.85-4.23-.4,26.94s-.66,1.19-2.9,1.05a4.84,4.84,0,0,1-3-.92l-.53-30.24-2.24-1.58.26-8.72-18.75-11.35.93-63.77Z"
        ]
    },
    labsTip : {
        paths : [
            "M745.4,459.93m0,0-5.2-2.8-27.87-14.71V403.2l159.23-89.3,3.62,2.84-.26,37.42L885,348.49l-1-40,6.82-5.35.66-.84,69.42,37.42V381l6.2,4.13V372l4.13-1.81V350.81l3.35-3.61,50.33,25.54v41L846,514.17l-66.89-36.06"
        ]
    }


    
    
}





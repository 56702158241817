import { h, Component } from 'preact';
import Map from './map/map';
import Menu from './menu';
import ToolTip from './tooltip';
import MediaQuery from 'react-responsive';
import style from './app.css';
import MobileMap from './mobileMap/mobileMap';
import QueryString from 'query-string';


export default class App extends Component {
	
	constructor(){
		super();
		this.selectState = this.selectState.bind(this);
		this.initialised = this.initialised.bind(this);
		this.isAnimating = this.isAnimating.bind(this);
		this.state = {
			selectedState : null,
			hoverRef : null,
			// anim : 'mobile7',
			progress: 0,
			loaded : false,
		}
		this.isAnimatingTimestamp = 0;
	}

	componentWillMount(){
	
	}
	initialised(){
		this.setState({loaded : true}, ()=>{
			
			setTimeout(()=>{
				const urlParams = QueryString.parseUrl(window.location.href);
				if(urlParams && urlParams.query.section){
					this.selectState(urlParams.query.section)
					this.tooltip.setRef(null);
				} else {
					if(this.map){
						this.map.runAnimation()
					}
				}
			}, 1000)
		})
	
		
	}
	componentDidMount(){
		

	}	


	selectState(selection){
		if(MAP_ANALYTICS_CALL){
			MAP_ANALYTICS_CALL(selection || 'home')
		}
		
		if(!this.state.loaded) return;
		if (window.history.pushState) {
			let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
			if(selection){
			  newUrl+='?section='+selection;
			}
			window.history.pushState({path:newUrl},'',newUrl);
		}
		document.getElementById("TechInfraProjectMap").classList.remove('dimmer')
		if(this.map && this.map.dimmerHandler){
			this.map.dimmerHandler('');
		}

		if(this.tooltip && this.tooltip.setRef){
			this.tooltip.setRef('')
		}
		
		

		
		this.setState({
			selectedState : selection
		})
	}

	isAnimating(){
		return ((new Date).getTime() -  this.isAnimatingTimestamp < 400);
	}
	
	render() {

		
		const renderController = ()=>{
			if(!this.state.anim) return ;

			return (
				<div style={{position: "fixed", zIndex:1000, left: 10, top: 10,  right:10, backgroundColor: "orange", padding: 10}}>
					<input type="text" value={this.state.anim}/>
					<input type="range"
                           value={this.state.progress}
                           style={{height: 20, width:500}}
                           min={0}
                           max={1}
						   step={0.001}
						   onInput={(e)=>{
							//    console.log(typeof parseFloat(e.target.value));
							   this.setState({
								   progress : parseFloat(e.target.value)
							   })
						   }}

                        />
                        <input type="text" value={this.state.progress}/>

				</div>
			)
		}
		
		return (
			<div id="app" class={style.mainWrapper} ref={(node)=>this.wrapper=node}>
				

				<MediaQuery minWidth={769} >
					<div ref={(node)=>this.menuPositioner} class={style.menuPositioner}>
						<Menu selectState={this.selectState} 
							selectedState={this.state.selectedState} 
							onHover={(trigger)=>{
								if(this.isAnimating()) return;
								// if(window.matchMedia("(hover: none)").matches) {return; }
								this.map.runAnimation(trigger);
								this.tooltip.setRef(trigger);
								
							}}
							onSubHover={(ref, hideDimmer)=>{
								if(this.isAnimating()) return;
								// if(window.matchMedia("(hover: none)").matches) {return; }
								this.map.dimmerHandler(ref, hideDimmer);
								this.tooltip.setRef(ref)
								if(ref && !hideDimmer){
									document.getElementById("TechInfraProjectMap").classList.add('dimmer')
								} else {
									document.getElementById("TechInfraProjectMap").classList.remove('dimmer')
								}
							
							}}
							active={this.state.loaded}
						/>
					</div>
					<div class={style.mapPositioner}>
						<Map ref={(node)=>{this.map=node}} 
							selectedState={this.state.selectedState} 
							anim={this.state.anim} 
							progress={this.state.progress}
							onInitialised={this.initialised}
							onAnimationStatusChange={(isAnimating)=>{
								
								if(isAnimating){
									this.isAnimatingTimestamp = (new Date).getTime();
								}
								// console.log('this.isAnimating', this.isAnimating);
							}}
						></Map>
					</div>

					<ToolTip ref={(node)=>{this.tooltip=node}} focus={this.state.focus}
										active={this.state.active}
										animating={this.state.animating}
								></ToolTip>
					
					{renderController()}

				</MediaQuery>

				<MediaQuery maxWidth={768}>
						<MobileMap 
							selectState={this.selectState}
							selectedState={this.state.selectedState} 
							anim={this.state.anim} 
							progress={this.state.progress}
							onInitialised={this.initialised}
						></MobileMap>	
							{renderController()}
				</MediaQuery>
				
					
			</div>
		);
	}
}

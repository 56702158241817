import { h, Component } from 'preact';
import style from './style.css';
import * as PIXI from 'pixi.js-legacy';
import gsap from 'gsap';
import PixiPlugin from 'gsap/PixiPlugin';
import MotionPathPlugin from 'gsap/MotionPathPlugin'
gsap.registerPlugin(PixiPlugin, MotionPathPlugin);
window.PIXI = PIXI

import {enterAccess, exitAccess, enterBackhaul, exitBackhaul, enterStartups, exitStartups, enterCore, exitCore, enterLabs, exitLabs} from './mapActions';
import {drawPath, createElement, drawPathHole, drawSmoothPath, drawPathCanvas} from './routeHelpers';

import {routePaths, towerSequences, highlights, startupsPaths} from './../data';


let ppi = window.devicePixelRatio || 1;
if(ppi > 2){ ppi = 2 };
// ppi = 1


PIXI.settings.FILTER_RESOLUTION = 6;

export default class Map extends Component {
	
	constructor(){
        super();
        this.runRoute = this.runRoute.bind(this);
        this.runAnimation = this.runAnimation.bind(this);
        this.runTowersAnimation = this.runTowersAnimation.bind(this);
        this.runTowersHoverAnimation = this.runTowersHoverAnimation.bind(this);
        this.runBackaulHoverAnimation - this.runBackaulHoverAnimation.bind(this);
        this.stopAnimation = this.stopAnimation.bind(this);
        this.dimmerHandler = this.dimmerHandler.bind(this);
        this.fallbackDimmerHandler = this.fallbackDimmerHandler.bind(this);
		this.state = {
            count : 0,
            current : null
        }
        this.app = null;
        this.elements = {};
        this.controls = {};
        this.towerElements = {}

        this.routeAnimation = null;
        this.routeSequence = [];
        this.towersAnimation = null;

	}

	componentWillMount(){
		
    }
    



    shouldComponentUpdate(nextProps){
        
        
        if(!this.props.anim){
            if(nextProps.selectedState!==this.state.current){
                this.runAnimation(nextProps.selectedState);
                this.setState({
                    current : nextProps.selectedState
                })
            } else {
                // console.log('nextProps', 'no need to change')
            }
        }
        //handle all changes manually 
		return false;
    }
    

    

	componentDidMount(){
	
		const app = new PIXI.Application({
			width: 1660,
			height: 780,
            transparent : true,
            antialias : true,
            resolution : ppi,
            forceCanvas : false
        });
        app.renderer.plugins.interaction.autoPreventDefault = false;
        app.renderer.view.style.touchAction = 'auto';
        this.app = app;
        

        //setup a simple loading spinner
        this.percent = 0;
        this.spinner = new PIXI.Graphics();
        this.spinner.x =985;
        this.spinner.y = 400;
        this.spinner.alpha = 0.5;
        this.spinner.scale.x = 0.5;
        this.spinner.scale.y = 0.5;
        app.stage.addChild(this.spinner);
        
        const spin = ()=>{
            this.spinner.rotation += 0.12;
            this.spinner
              .clear()
              .lineStyle(4, 0xffffff, 1)
              .moveTo(40, 0)
              .arc(0, 0, 40, 0, Math.PI * 2 * this.percent, false);
            this.percent = Math.abs(Math.sin(Date.now() / 1000));
        }
        this.app.ticker.add(spin);
        

        

        this.wrapper.appendChild(app.view)
        const isWebGl = app.renderer.type == 1;
        if(ppi==2){
            app.loader
            .add('allModules', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-allModules@2x.png')
            .add('allModulesMask', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-allModulesMask@2x.png')
            .add('backhaul', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-backhaul@2x.png')
            .add('access', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-access@2x.png')
            .add('core', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-core@2x.png')
            .add('startups', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-startups-background@2x.png')
            .add('startupsForeground', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-startups-foreground@2x.png')
            .add('labs', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-labs@2x.png')
            .add('labsNetwork', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-labs-network@2x.png')
            .add('labsForeground', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-labs-foreground@2x.png')
            .add('backhaulNetwork', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-backhaul-network@2x.png')
            .add('accessNetwork', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-access-network@2x.png')
        } else {
            app.loader
            .add('allModules', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-allModules.png')
            .add('allModulesMask', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-allModulesMask@2x.png')
            .add('backhaul', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-backhaul.png')
            .add('access', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-access.png')
            .add('core', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-core.png')
            .add('startups', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-startups-background.png')
            .add('startupsForeground', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-startups-foreground.png')
            .add('labs', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-labs.png')
            .add('labsNetwork', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-labs-network.png')
            .add('labsForeground', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-labs-foreground.png')
            .add('backhaulNetwork', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-backhaul-network@2x.png')
            .add('accessNetwork', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-access-network@2x.png')
            
        }
       
        if (navigator.userAgent.indexOf("Firefox") != -1 ) {
            app.loader.add('grid', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-grid-ff.svg')
        } else {
            app.loader.add('grid', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-grid.svg')
        }
        
        app.loader
        .add('globalMask', '/wp-content/fb-interactive-25-sept-2023/assets/img/global-mask.png')
        .add('alphaMask', '/wp-content/fb-interactive-25-sept-2023/assets/img/alpha-mask.svg')
        .add('gradientFade', '/wp-content/fb-interactive-25-sept-2023/assets/img/gradient-fade.svg')
        .add('gradientFull', '/wp-content/fb-interactive-25-sept-2023/assets/img/gradient-full.svg')
        .add('network', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-network@2x.png')
        
        // .add('startups', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-startups.png')
        
        .add( '/wp-content/fb-interactive-25-sept-2023/assets/img/sprites/networkSprites.json')
        .add('zoomSprites', '/wp-content/fb-interactive-25-sept-2023/assets/img/sprites/zoomSectionSprites.json')
        
        .add('tower1', '/wp-content/fb-interactive-25-sept-2023/assets/img/tower-1@4x.png')
        .add('coreStNode', '/wp-content/fb-interactive-25-sept-2023/assets/img/stacked-node@4x.png')
        .add('coreSNode', '/wp-content/fb-interactive-25-sept-2023/assets/img/single-node@4x.png')
        .add('coreTower9', '/wp-content/fb-interactive-25-sept-2023/assets/img/tower9@4x.png')
        .add('coreRadioMast', '/wp-content/fb-interactive-25-sept-2023/assets/img/RadioMast@4x.png')
        
        .add('coreNetwork', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-core-network@2x.png')
        .add('coreBuilding', '/wp-content/fb-interactive-25-sept-2023/assets/img/viewport-core-building@2x.png')

        .add('accessWiFiHl', '/wp-content/fb-interactive-25-sept-2023/assets/img/accessWiFi-highlights.png')
        .add('labsHl', '/wp-content/fb-interactive-25-sept-2023/assets/img/labs-highlights.png')
        .add('accessCrowdcellHl', '/wp-content/fb-interactive-25-sept-2023/assets/img/accessCrowdcell-highlights.png')
        .add('accessOpenCellularHl', '/wp-content/fb-interactive-25-sept-2023/assets/img/accessOpenCellular-highlights.png')
        .add('accessOpenRANHl', '/wp-content/fb-interactive-25-sept-2023/assets/img/accessOpenRAN-highlights.png')
        .add('accessOpenRAN5GNRHl', '/wp-content/fb-interactive-25-sept-2023/assets/img/accessOpenRAN5GNR-highlights.png')
        .add('backhaulOpenHl', '/wp-content/fb-interactive-25-sept-2023/assets/img/backhaulOpen-highlights.png')
        .add('accessFronthaulHl', '/wp-content/fb-interactive-25-sept-2023/assets/img/accessFronthaul-highlights.png')
        .add('backhaulMicrowaveHl', '/wp-content/fb-interactive-25-sept-2023/assets/img/backhaulMicrowave-highlights.png')
        .add('backhaulMillimeterHl', '/wp-content/fb-interactive-25-sept-2023/assets/img/backhaulMillimeter-highlights.png')
        .add('coreHl', '/wp-content/fb-interactive-25-sept-2023/assets/img/core-highlights.png')
        .add('startupsHl', '/wp-content/fb-interactive-25-sept-2023/assets/img/startups-highlights.png')

        

        app.loader.load((loader, resources) => {

            this.app.ticker.remove(spin);
            app.stage.removeChild(this.spinner);

            this.resources = resources;
            
            const globalMask = new PIXI.Sprite(resources.globalMask.texture);
            globalMask.width = app.renderer.width / ppi;
            globalMask.height = app.renderer.height / ppi;

            const globalBg = new PIXI.Sprite(resources.gradientFull.texture);
            globalBg.width = app.renderer.width / ppi;
            globalBg.height = app.renderer.height / ppi;

            app.stage.addChild(globalBg);
            
            app.stage.mask = globalMask;

            //setup some containers
            const mapElements = new PIXI.Container();
            app.stage.addChild(mapElements);



           

            const routeHighlights = new PIXI.Container();
            app.stage.addChild(routeHighlights);
           
            
            
            const routeElementsRear = new PIXI.Container();
            app.stage.addChild(routeElementsRear);


            const routeElements = new PIXI.Container();
            app.stage.addChild(routeElements);
           
            

            const towers = new PIXI.Container();
            app.stage.addChild(towers);
            // towers.scale.x = towers.scale.y = 1660 / 1920;

            // towers.width = app.renderer.width / ppi;
            // towers.height = app.renderer.height / ppi;

         
            
            
            const networkGridMask = new PIXI.Sprite(resources.alphaMask.texture);
            
            const grid = new PIXI.Sprite(resources.grid.texture);
            grid.cacheAsBitmap = true;
            
            grid.width = app.renderer.width / ppi;
            grid.height = app.renderer.height / ppi;
            grid.alpha = 1;

            if (navigator.userAgent.indexOf("Firefox") != -1 ){
                grid.alpha = 0.5;
            }
            
               //clouds 
               const cloud = new PIXI.Sprite(resources['zoomSprites'].spritesheet.textures['fluffycloud.png']);
               cloud.x = 700
               cloud.y = 50
               cloud.width = 120
               cloud.height = 60;
               cloud.scale.x = 0.2;
               cloud.scale.y = 0.2;
               mapElements.addChild(cloud);


               const cloud2 = new PIXI.Sprite(resources['zoomSprites'].spritesheet.textures['fluffycloud.png']);
               cloud2.x = 500
               cloud2.y = 80
               cloud2.width = 120
               cloud2.height = 60;
               cloud2.scale.x = 0.4;
               cloud2.scale.y = 0.4;
               mapElements.addChild(cloud2);

               const cloud3 = new PIXI.Sprite(resources['zoomSprites'].spritesheet.textures['fluffycloud.png']);
               cloud3.x = 1300;
               cloud3.y = 70
               cloud3.width = 120
               cloud3.height = 60;
               cloud3.scale.x = 0.5;
               cloud3.scale.y = 0.5;
               mapElements.addChild(cloud3);

               const cloud4 = new PIXI.Sprite(resources['zoomSprites'].spritesheet.textures['fluffycloud.png']);
               cloud4.x = 1250;
               cloud4.y = 120
               cloud4.width = 120
               cloud4.height = 60;
               cloud4.scale.x = 0.2;
               cloud4.scale.y = 0.2;
               mapElements.addChild(cloud4);


               app.ticker.add((delta)=>{
                   cloud.x +=delta * 0.03 * 1;
                   cloud2.x += delta * 0.08 * 1;
                   cloud3.x += delta * 0.1 * 1;
                   cloud4.x += delta * 0.13 * 1;
                   if(cloud.x > 1800) { cloud.x = -120;}
                   if(cloud2.x > 1800) { cloud2.x = -120;}
                   if(cloud3.x > 1800) { cloud3.x = -120;}
                   if(cloud4.x > 1800) { cloud4.x = -120;}
               })


			const allModules = new PIXI.Sprite(resources.allModules.texture);
            mapElements.addChild(allModules);
            grid.cacheAsBitmap = true;
            allModules.width = app.renderer.width / ppi;
            allModules.height = app.renderer.height / ppi;

            mapElements.addChild(grid);

            const backhaul =  new PIXI.Sprite(resources.backhaul.texture);
            mapElements.addChild(backhaul);
            backhaul.width = app.renderer.width / ppi;
            backhaul.height = app.renderer.height / ppi;
            backhaul.alpha = 0;
            
            const access =  new PIXI.Sprite(resources.access.texture);
            mapElements.addChild(access);
            access.width = app.renderer.width / ppi;
            access.height = app.renderer.height / ppi;
            access.alpha = 0;

            

            const network = new PIXI.Sprite(resources.network.texture);
            routeElements.addChild(network);
            network.width = app.renderer.width / ppi;
            network.height = app.renderer.height / ppi;
            

            const backhaulNetwork = new PIXI.Sprite(resources.backhaulNetwork.texture);
            routeElements.addChild(backhaulNetwork);
            backhaulNetwork.width = app.renderer.width / ppi;
            backhaulNetwork.height = app.renderer.height / ppi;
            backhaulNetwork.alpha = 0;

            const accessNetwork = new PIXI.Sprite(resources.accessNetwork.texture);
            routeElements.addChild(accessNetwork);
            accessNetwork.width = app.renderer.width / ppi;
            accessNetwork.height = app.renderer.height / ppi;
            accessNetwork.alpha = 0;


            const allModulesMask =  new PIXI.Sprite(resources.allModulesMask.texture);
            // app.stage.addChild(allModulesMask);
            // allModulesMask.width = app.renderer.width / ppi;
            // allModulesMask.height = app.renderer.height / ppi;



            const startups = new PIXI.Container();
            app.stage.addChild(startups);
            startups.alpha = 0;

            const startupsBg = new PIXI.Sprite(resources.startups.texture);
            startups.addChild(startupsBg);
            startupsBg.width = app.renderer.width / ppi;
            startupsBg.height = app.renderer.height / ppi;

            const startupsRoutes =  new PIXI.Graphics();
            startupsRoutes.width = app.renderer.width / ppi;
            startupsRoutes.height = app.renderer.height / ppi;

            const startupsTowers = new PIXI.Container();
            
            startups.addChild(startupsTowers);
            const startupsBubbles = new PIXI.Container();
            startupsBubbles.alpha = 0;

            // startupsTowers.alpha = 1;
            startups.addChild(startupsRoutes);
            
            const startupsDimmer =  new PIXI.Graphics();
            startupsDimmer.width = app.renderer.width / ppi;
            startupsDimmer.height = app.renderer.height / ppi;
            startups.addChild(startupsDimmer);

            const startupsFg = new PIXI.Sprite(resources.startupsForeground.texture);
            startups.addChild(startupsFg);
            startupsFg.width = app.renderer.width / ppi;
            startupsFg.height = app.renderer.height / ppi;

            


            
            
           


            //create startup animations...

            const createBubble = (name, x, y)=>{
                const node = new PIXI.AnimatedSprite(resources.zoomSprites.spritesheet.animations[name])
                node.width = 100
                node.height = 100
                node.x = x
                node.y = y
                node.anchor.set(0.5)
                node.scale = 0;
                return node;
            }

            
            
            const bubble = (node)=>{
                return  gsap.timeline()
                .set(node, { pixi : { scale : 0 }})
                .add(()=>{node.gotoAndStop(0)}, 0)
                .to(node, { duration: 3, pixi : { scale : 0.5 }, ease : 'elastic.out'})
                .add(()=>{node.gotoAndStop(1)}, 4)
                .add(()=>{node.gotoAndStop(2)}, 5)
                .add(()=>{node.gotoAndStop(3)}, 6)
                .to(node, { duration: 1, pixi : { scale : 0 }, ease : 'power3.out'}, 8)
                .timeScale(3);
            }

            const pingStartupRoute = (inputState)=>{
                
                return gsap.timeline()
                .set(inputState, { duration: 1, start : 0, end : 0, ease :'none' })
                .to(inputState, { duration: 1, start : 0, end : 0.1, ease :'none' })
                .to(inputState, { duration: 9, start : 0.9, end : 1, ease :'none' })
                .to(inputState, { duration: 1, start : 1, end : 1, ease :'none' })
                .to(inputState, { duration: 1, start : 1, end : 1, ease :'none' })
                .set(inputState, { duration: 1, start : 0, end : 0, ease :'none' })
                .timeScale(20);
            }

          

            const startupsB1 = createBubble('bubble1', 936, 351);
            startupsBubbles.addChild(startupsB1)
            const startupsB2 = createBubble('bubble2', 1007, 325);
            startupsBubbles.addChild(startupsB2)

            const startupsB3 = createBubble('bubble3', 956, 431);
            startupsBubbles.addChild(startupsB3)

            const startupsB4 = createBubble('bubble4', 1057, 366);
            startups.addChild(startupsB4)

         
            let leftRoute = { x : 699.14, y : 506.22}
            let leftRoute2 = { x :  869.8, y : 415.15}
            let rightRoute = { x :  1129.0447 , y : 405.9085}
            let rightRoute2 = { x :  1310.0968 , y : 508.0486}

            const animateStartupRoute = (route, startX, startY, endX, endY)=>{
                return gsap.timeline()
                .set(route, { x : startX, y : startY })
                .to(route, { x : endX, y : endY, duration : 0.4 })
                .set(route, { x : startX, y : startY })
            }

            let startupsTl = gsap.timeline({repeat : -1, onUpdate : ()=>{
                this.elements.startupsRoutes.clear();
                
                this.elements.startupsRoutes.beginFill(PIXI.utils.rgb2hex([0/255, 249/255, 255/255]), 1);
                if(leftRoute.x > 699.14 && leftRoute.x < 869.8){
                    this.elements.startupsRoutes.drawCircle(leftRoute.x,leftRoute.y, 5);
                }
                if(leftRoute2.x > 699.14 && leftRoute2.x < 869.8){
                    this.elements.startupsRoutes.drawCircle(leftRoute2.x,leftRoute2.y, 5);
                }

                if(rightRoute.x > 1129.0447 && rightRoute.x < 1310.0968){
                    this.elements.startupsRoutes.drawCircle(rightRoute.x,rightRoute.y, 5);
                }
                if(rightRoute2.x > 1129.0447 && rightRoute2.x < 1310.0968){
                    this.elements.startupsRoutes.drawCircle(rightRoute2.x,rightRoute2.y, 5);
                }
                
                this.elements.startupsRoutes.endFill();

                
            } })


            .add(bubble(startupsB1), 0)
            .add(bubble(startupsB3), 3)
            .add(bubble(startupsB4), 5)
            .add(bubble(startupsB2), 7)
            .to({}, {duration : 1}) ;


            for(let i = 0; i < 10; i++){
                startupsTl.add(animateStartupRoute(leftRoute,699.14,  506.22,  869.8, 415.15), gsap.utils.random(i, i+1))
                startupsTl.add(animateStartupRoute(leftRoute2, 869.8, 415.15, 699.14,  506.22  ), gsap.utils.random(i, i+1))
                startupsTl.add(animateStartupRoute(rightRoute, 1129.0447 , 405.9085,  1310.0968 , 508.0486  ), gsap.utils.random(i, i+1) )
                startupsTl.add(animateStartupRoute(rightRoute2, 1310.0968 , 508.0486 , 1129.0447 , 405.9085 ), gsap.utils.random(i, i+1) )
            }

          



            const core = new PIXI.Container();
            app.stage.addChild(core);
            core.alpha = 0;
            
            const coreBackground = new PIXI.Sprite(resources.core.texture);
            core.addChild(coreBackground);
            coreBackground.width = app.renderer.width / ppi;
            coreBackground.height = app.renderer.height / ppi;


            
            const coreRoutes =  new PIXI.Graphics();
            coreRoutes.width = app.renderer.width / ppi;
            coreRoutes.height = app.renderer.height / ppi;
            
            
          
           
            if(!isWebGl){
                core.addChild(coreRoutes);
            }

            const coreNetwork = new PIXI.Sprite(resources.coreNetwork.texture);
            core.addChild(coreNetwork);
            coreNetwork.width = app.renderer.width / ppi;
            coreNetwork.height = app.renderer.height / ppi;
            // coreNetwork.tint = PIXI.utils.rgb2hex([0/255, 249/255, 255/255]);
            coreNetwork.tint = PIXI.utils.rgb2hex([229/255, 0/255, 126/255]);


            if(isWebGl){
                const coreNetworkHighlight = new PIXI.Sprite(resources.coreNetwork.texture);
                core.addChild(coreNetworkHighlight);
                coreNetworkHighlight.width = app.renderer.width / ppi;
                coreNetworkHighlight.height = app.renderer.height / ppi;
                coreNetworkHighlight.tint = PIXI.utils.rgb2hex([0/255, 249/255, 255/255]);
                coreNetworkHighlight.mask = coreRoutes;
            }

           

            // if((app.renderer.type == PIXI.WEBGL_RENDERER))

            
            


            
            
            const coreTowers = new PIXI.Container();
            core.addChild(coreTowers);
            coreTowers.alpha = 1;


            const coreBuilding = new PIXI.Sprite(resources.coreBuilding.texture);
            core.addChild(coreBuilding);
            coreBuilding.width = app.renderer.width / ppi;
            coreBuilding.height = app.renderer.height / ppi;
            

            const  coreAnim = new PIXI.AnimatedSprite(resources.zoomSprites.spritesheet.animations.core);
            coreAnim.loop = true;
            coreAnim.animationSpeed = 0.05
            coreAnim.width = 1660
            coreAnim.height = 780
            // coreAnim.scale.y =0.3
            coreAnim.play(0);
            core.addChild(coreAnim)



            const labs = new PIXI.Container();
            app.stage.addChild(labs);
            labs.alpha = 0;
          
                

            const labsBackground = new PIXI.Sprite(resources.labs.texture);
            labs.addChild(labsBackground);
            labsBackground.width = app.renderer.width / ppi;
            labsBackground.height = app.renderer.height / ppi;
            

            const labRoutes =  new PIXI.Graphics();
           
            labRoutes.width = app.renderer.width / ppi;
            labRoutes.height = app.renderer.height / ppi;

            if(!isWebGl){
                labs.addChild(labRoutes);
            }
            const labsNetwork = new PIXI.Sprite(resources.labsNetwork.texture);
            labs.addChild(labsNetwork);
            labsNetwork.width = app.renderer.width / ppi;
            labsNetwork.height = app.renderer.height / ppi;
            // coreNetwork.tint = PIXI.utils.rgb2hex([0/255, 249/255, 255/255]);
            labsNetwork.tint = PIXI.utils.rgb2hex([229/255, 0/255, 126/255]);
            

            if(isWebGl){
                const labsNetworkHighlight = new PIXI.Sprite(resources.labsNetwork.texture);
                labs.addChild(labsNetworkHighlight);
                labsNetworkHighlight.width = app.renderer.width / ppi;
                labsNetworkHighlight.height = app.renderer.height / ppi;
                labsNetworkHighlight.tint = PIXI.utils.rgb2hex([0/255, 249/255, 255/255]);
                labsNetworkHighlight.mask = labRoutes;
            }
           

            const labsTowersRear= new PIXI.Container();
            labs.addChild(labsTowersRear);
            labsTowersRear.width = app.renderer.width / ppi;
            labsTowersRear.height = app.renderer.height / ppi;

            const labsForeground = new PIXI.Sprite(resources.labsForeground.texture);
            labs.addChild(labsForeground);
            labsForeground.width = app.renderer.width / ppi;
            labsForeground.height = app.renderer.height / ppi;


            const labsTowers = new PIXI.Container();
            labs.addChild(labsTowers);
            labsTowers.width = app.renderer.width / ppi;
            labsTowers.height = app.renderer.height / ppi;
            // labsTowers.alpha = 0;


            

            const  labsAnim = new PIXI.AnimatedSprite(resources.zoomSprites.spritesheet.animations.serverRoom);
            labsAnim.loop = true;
            labsAnim.anchor.set(0.5)
            labsAnim.x = 839+33
            labsAnim.animationSpeed = 0.03
            labsAnim.y = 360+31
            labsAnim.width = 160
            labsAnim.height = 56
            labsAnim.play(0);
            labs.addChild(labsAnim)
            



            //create the routes graphic object
            const routes = new PIXI.Graphics();
            routeHighlights.addChild(routes);
            routes.mask = networkGridMask;
            
            
            //add blue fades
            const gradientFadeL = new PIXI.Sprite(resources.gradientFade.texture);
            // app.stage.addChild(gradientFadeL);
            gradientFadeL.width = 200;
            gradientFadeL.height = 780;
            gradientFadeL.alpha = 1;
            gradientFadeL.scale.x = 8;

            const gradientFadeR = new PIXI.Sprite(resources.gradientFade.texture);
            // app.stage.addChild(gradientFadeR);
            gradientFadeR.width = 200;
            gradientFadeR.height = 780;
            gradientFadeR.alpha = 1;
            gradientFadeR.scale.x = -8;
            gradientFadeR.x = 1660;
            gradientFadeR.alpha = 0;


            const dimmer = new PIXI.Graphics();
            app.stage.addChild(dimmer);
            
            
            const labsTowersFore= new PIXI.Container();
            app.stage.addChild(labsTowersFore);
            labsTowersFore.width = app.renderer.width / ppi;
            labsTowersFore.height = app.renderer.height / ppi;
            labsTowersFore.alpha = 0;


            app.stage.addChild(startupsBubbles);
            
        

            let sheet = resources['/wp-content/fb-interactive-25-sept-2023/assets/img/sprites/networkSprites.json'].spritesheet;

            this.towerElements.bTwo1 = createElement(sheet, 'bTwo', 1490, 370);            
            towers.addChild(this.towerElements.bTwo1);
           

            this.towerElements.tOne1 = createElement(sheet, 'tower1', 1412, 265);            
            towers.addChild(this.towerElements.tOne1);

           


            this.towerElements.tOne2 = createElement(sheet, 'tower1', 1460, 381);            
            towers.addChild(this.towerElements.tOne2);

            this.towerElements.tOne3 = createElement(sheet, 'tower1', 1274, 539);            
            towers.addChild(this.towerElements.tOne3);


            this.towerElements.tOne5 = createElement(sheet, 'tower1', 1172, 324);            
            towers.addChild(this.towerElements.tOne5);

            this.towerElements.tOne6 = createElement(sheet, 'tower1', 1139, 412-2);            
            towers.addChild(this.towerElements.tOne6);

            this.towerElements.tOne7 = createElement(sheet, 'tower1', 1005, 255);            
            towers.addChild(this.towerElements.tOne7);

            this.towerElements.tOne8 = createElement(sheet, 'tower1', 1074-2, 609);            
            towers.addChild(this.towerElements.tOne8);

            this.towerElements.tOne9 = createElement(sheet, 'tower1', 976, 511);            
            towers.addChild(this.towerElements.tOne9);

            this.towerElements.tOne10 = createElement(sheet, 'tower1', 785, 491);            
            towers.addChild(this.towerElements.tOne10);



            this.towerElements.tTwo1 = createElement(sheet, 'tower2', 863, 607);            
            towers.addChild(this.towerElements.tTwo1);
            
           
            this.towerElements.tThree1 = createElement(sheet, 'tower3', 669, 566);            
            towers.addChild(this.towerElements.tThree1);
            
           

            this.towerElements.tFour1 = createElement(sheet, 'tower4', 536-5, 567);            
            towers.addChild(this.towerElements.tFour1);

           

            this.towerElements.tFive1 = createElement(sheet, 'tower5', 769, 300);            
            towers.addChild(this.towerElements.tFive1);

           


            this.towerElements.tSix1 = createElement(sheet, 'tower6', 529, 257);            
            towers.addChild(this.towerElements.tSix1);

           

            
            this.towerElements.tSix2 = createElement(sheet, 'tower6', 877, 326);            
            towers.addChild(this.towerElements.tSix2);

            this.towerElements.tSix3 = createElement(sheet, 'tower6', 671, 371);            
            towers.addChild(this.towerElements.tSix3);


            this.towerElements.tSeven1 = createElement(sheet, 'tower7', 660, 293);            
            towers.addChild(this.towerElements.tSeven1);

           

            this.towerElements.tEight1 = createElement(sheet, 'tower8', 543, 328);            
            towers.addChild(this.towerElements.tEight1);

            


            this.towerElements.tNine1 = createElement(sheet, 'tower9', 1346, 607);            
            towers.addChild(this.towerElements.tNine1);

           

            this.towerElements.tNine2 = createElement(sheet, 'tower9', 1290, 578);            
            towers.addChild(this.towerElements.tNine2);
            
            this.towerElements.tNine3 = createElement(sheet, 'tower9', 1398, 574);            
            towers.addChild(this.towerElements.tNine3);

            this.towerElements.tNine4 = createElement(sheet, 'tower9', 1347, 542);            
            towers.addChild(this.towerElements.tNine4);


            this.towerElements.tNine5 = createElement(sheet, 'tower9', 1417, 570);            
            towers.addChild(this.towerElements.tNine5);

            this.towerElements.tNine6 = createElement(sheet, 'tower9', 1350, 533);            
            towers.addChild(this.towerElements.tNine6);

           

            this.towerElements.tNine7 = createElement(sheet, 'tower9', 1284, 498);            
            towers.addChild(this.towerElements.tNine7);

            this.towerElements.tNine8 = createElement(sheet, 'tower9', 1214, 460);            
            towers.addChild(this.towerElements.tNine8);


            this.towerElements.tNine9 = createElement(sheet, 'tower9', 1480, 533);            
            towers.addChild(this.towerElements.tNine9);

            this.towerElements.tNine10 = createElement(sheet, 'tower9', 1416, 494);            
            towers.addChild(this.towerElements.tNine10);

            this.towerElements.tNine11 = createElement(sheet, 'tower9', 1354, 458);            
            towers.addChild(this.towerElements.tNine11);

            this.towerElements.tNine12 = createElement(sheet, 'tower9', 1286, 419);            
            towers.addChild(this.towerElements.tNine12);

            this.towerElements.tNine13 = createElement(sheet, 'tower9', 1536, 497);            
            towers.addChild(this.towerElements.tNine13);

            this.towerElements.tNine14 = createElement(sheet, 'tower9', 1474, 461);            
            towers.addChild(this.towerElements.tNine14);

            this.towerElements.tNine15 = createElement(sheet, 'tower9', 1412, 425);            
            towers.addChild(this.towerElements.tNine15);

            this.towerElements.tNine16 = createElement(sheet, 'tower9', 1345, 386);            
            towers.addChild(this.towerElements.tNine16);



            this.towerElements.sNode1 = createElement(sheet, 'sNode', 1361, 304);            
            towers.addChild(this.towerElements.sNode1);

            this.towerElements.sNode2 = createElement(sheet, 'sNode', 1260, 488);            
            towers.addChild(this.towerElements.sNode2);

            this.towerElements.sNode3 = createElement(sheet, 'sNode', 1074, 457);            
            towers.addChild(this.towerElements.sNode3);

            this.towerElements.sNode4 = createElement(sheet, 'sNode', 1075, 549);            
            towers.addChild(this.towerElements.sNode4);

            this.towerElements.sNode5 = createElement(sheet, 'sNode', 961, 385);            
            towers.addChild(this.towerElements.sNode5);

            this.towerElements.sNode6 = createElement(sheet, 'sNode', 1057, 305);            
            towers.addChild(this.towerElements.sNode6);

            this.towerElements.sNode7 = createElement(sheet, 'sNode', 912, 460);            
            towers.addChild(this.towerElements.sNode7);

            this.towerElements.sNode8 = createElement(sheet, 'sNode', 777, 384);            
            towers.addChild(this.towerElements.sNode8);

           
            this.towerElements.stNode1 = createElement(sheet, 'stNode', 1233, 384);            
            towers.addChild(this.towerElements.stNode1);
            

            this.towerElements.stNode2 = createElement(sheet, 'stNode', 1420-15, 349);            
            towers.addChild(this.towerElements.stNode2);
            this.towerElements.stNode2.alpha = 0.5;
            
            this.towerElements.stNode3 = createElement(sheet, 'stNode', 1161, 502);            
            towers.addChild(this.towerElements.stNode3);


            this.towerElements.cShop1 = createElement(sheet, 'cShop', 1270, 336);            
            towers.addChild(this.towerElements.cShop1);

            // setInterval(()=>{
            //     this.towerElements.cShop1.ping(true);
            // }, 1000)


            this.towerElements.bOne1 = createElement(sheet, 'bOne', 1078, 289+2);            
            towers.addChild(this.towerElements.bOne1);

            this.towerElements.bOne2 = createElement(sheet, 'bOne', 770, 427);            
            towers.addChild(this.towerElements.bOne2);



            this.towerElements.bThree1 = createElement(sheet, 'bThree', 1215, 596);            
            towers.addChild(this.towerElements.bThree1);
            
            



            this.towerElements.satellite = createElement(sheet, 'satellite', 429+10, 136+5);            
            towers.addChild(this.towerElements.satellite);
            

            this.towerElements.cloud = createElement(sheet, 'cloud', 1564, 667);            
            routeElementsRear.addChild(this.towerElements.cloud);

            
            this.towerElements.satelliteDish = createElement(sheet, 'satelliteDish', 1346, 371);            
            towers.addChild(this.towerElements.satelliteDish);
            
            this.towerElements.radioMast = createElement(sheet, 'radioMast', 1271-2, 368+5);            
            towers.addChild(this.towerElements.radioMast);


          

            // Core Elements

            this.towerElements.coreTower1 = createElement(sheet, 'coreTower', 1074, 609, resources.tower1.texture)
            coreTowers.addChild(this.towerElements.coreTower1 )


            this.towerElements.coreTower2 = createElement(sheet, 'coreTower', 1274, 539, resources.tower1.texture)
            coreTowers.addChild(this.towerElements.coreTower2 )

            this.towerElements.coreTower3 = createElement(sheet, 'coreTower', 976, 511, resources.tower1.texture)
            coreTowers.addChild(this.towerElements.coreTower3 )




            this.towerElements.coreSNode1 = createElement(sheet, 'coreSNode', 1075-1, 549-5, resources.coreSNode.texture)
            coreTowers.addChild(this.towerElements.coreSNode1)

            this.towerElements.coreSNode2 = createElement(sheet, 'coreSNode', 1260-4, 488-2, resources.coreSNode.texture)
            coreTowers.addChild(this.towerElements.coreSNode2)

            
            this.towerElements.coreStNode1 = createElement(sheet, 'coreStNode', 1161-12, 501, resources.coreStNode.texture)
            coreTowers.addChild(this.towerElements.coreStNode1)


            this.towerElements.coreTowerNine1 = createElement(sheet, 'coreTower9', 1346+3, 607+10, resources.coreTower9.texture)
            coreTowers.addChild(this.towerElements.coreTowerNine1)

            this.towerElements.coreTowerNine2 = createElement(sheet, 'coreTower9', 1290+1, 578+7, resources.coreTower9.texture)
            coreTowers.addChild(this.towerElements.coreTowerNine2)

            this.towerElements.coreTowerNine3 = createElement(sheet, 'coreTower9', 1398+1, 574+4, resources.coreTower9.texture)
            coreTowers.addChild(this.towerElements.coreTowerNine3)

            this.towerElements.coreTowerNine4 = createElement(sheet, 'coreTower9', 1347+2, 542+8, resources.coreTower9.texture)
            coreTowers.addChild(this.towerElements.coreTowerNine4)

            this.towerElements.coreTowerNine5 = createElement(sheet, 'coreTower9', 1351+3, 533+8, resources.coreTower9.texture)
            coreTowers.addChild(this.towerElements.coreTowerNine5)

            this.towerElements.coreTowerNine6 = createElement(sheet, 'coreTower9', 1284+3, 498+8, resources.coreTower9.texture)
            coreTowers.addChild(this.towerElements.coreTowerNine6)

            this.towerElements.coreTowerNine7 = createElement(sheet, 'coreTower9', 1593+3, 317+8, resources.coreTower9.texture)
            coreTowers.addChild(this.towerElements.coreTowerNine7)

            this.towerElements.corePing1 = createElement(sheet, 'ping', 1210, 565)
            coreTowers.addChild(this.towerElements.corePing1)

            this.towerElements.corePing2 = createElement(sheet, 'ping', 1230, 570)
            coreTowers.addChild(this.towerElements.corePing2)

            this.towerElements.corePing3 = createElement(sheet, 'ping', 1220, 600)
            coreTowers.addChild(this.towerElements.corePing3)

            //Startup Elements
            this.towerElements.startupsRadioMast = createElement(sheet, 'coreRadioMast', 1382, 353+6, resources.coreRadioMast.texture)
            startupsTowers.addChild(this.towerElements.startupsRadioMast )
            this.towerElements.startupsRadioMast.ping();
            //  setInterval(()=>{
            //     this.towerElements.startupsRadioMast.ping();
            // }, 1000)


            // Lab Elements

            this.towerElements.labTower1 = createElement(sheet, 'coreTower', 1460-10, 381, resources.tower1.texture)
            labsTowersFore.addChild(this.towerElements.labTower1 )

            this.towerElements.labTower2 = createElement(sheet, 'coreTower', 1412+3, 265-5, resources.tower1.texture)
            labsTowers.addChild(this.towerElements.labTower2 )

            this.towerElements.labSNode1 = createElement(sheet, 'coreSNode', 1361+4, 304-6, resources.coreSNode.texture)
            labsTowers.addChild(this.towerElements.labSNode1)

            this.towerElements.labStNode1 = createElement(sheet, 'coreStNode', 1402-3, 348, resources.coreStNode.texture);            
            labsTowers.addChild(this.towerElements.labStNode1);

            this.towerElements.labStNode2 = createElement(sheet, 'coreStNode', 1233-10, 383+10, resources.coreStNode.texture);            
            labsTowers.addChild(this.towerElements.labStNode2);

            this.towerElements.labPing1 = createElement(sheet, 'ping', 1434+15, 350)
            labsTowersRear.addChild(this.towerElements.labPing1)

            this.towerElements.labPing2 = createElement(sheet, 'ping', 1510, 355)
            labsTowersRear.addChild(this.towerElements.labPing2)

           
            this.routeAnimation = null;
            this.routeSequence = routePaths.sequences.main.slice();



            

            //create some class level references for later manipulation
            this.elements.grid = grid;

            this.elements.allModules = allModules;
            this.elements.allModulesMask = allModulesMask;
            this.elements.backhaul = backhaul;
            this.elements.access = access;
            this.elements.startups = startups;
            this.elements.routeHighlights = routeHighlights;
            this.elements.mapElements = mapElements;
            this.elements.routeElements = routeElements;
            this.elements.towers = towers;
            this.elements.routeElementsRear = routeElementsRear;
            this.elements.core = core;
            this.elements.coreRoutes = coreRoutes;
            this.elements.coreTowers = coreTowers;
            this.elements.labs = labs;
            this.elements.labsTowers = labsTowers;
            this.elements.labsTowersRear = labsTowersRear;
            this.elements.labsTowersFore = labsTowersFore;
            this.elements.labRoutes = labRoutes;
            this.elements.gradientFadeR = gradientFadeR;
            this.elements.dimmer = dimmer;
            this.controls.routes = routes;
            this.elements.app = app;
            this.elements.backhaulNetwork = backhaulNetwork;
            this.elements.network = network;
            this.elements.accessNetwork = accessNetwork;
            this.elements.startupsTowers = startupsTowers;
            this.elements.startupsRoutes = startupsRoutes;
            this.elements.startupsBubbles = startupsBubbles;
            this.elements.startupsDimmer = startupsDimmer;
            this.elements.towerElements = this.towerElements;
            this.elements.homeDimmerRefs = [
                this.towerElements.tNine12,
                this.towerElements.tNine11,
                this.towerElements.tNine14,
                this.towerElements.tNine15,
                this.towerElements.tNine16,
            ];
            this.elements.accessDimmerRefs = [
                this.towerElements.bTwo1, 
                this.towerElements.sNode1, 
                this.towerElements.sNode2, 
                this.towerElements.sNode3, 
                this.towerElements.sNode4, 
                this.towerElements.sNode5, 
                this.towerElements.sNode6, 
                this.towerElements.sNode7, 
                this.towerElements.sNode8, 
                this.towerElements.stNode1,
                this.towerElements.stNode3,
                this.towerElements.bOne1,
                this.towerElements.bOne2,
                this.towerElements.bThree1,
                this.towerElements.satellite,
                this.towerElements.cloud,
                this.towerElements.satelliteDish,
                this.towerElements.radioMast
            ];
            this.elements.backhaulDimmerRefs = [
                this.towerElements.bTwo1, 
                this.towerElements.tOne1,
                this.towerElements.tOne2,
                this.towerElements.tOne3,
                this.towerElements.tOne4,
                this.towerElements.tOne5,
                this.towerElements.tOne6,
                this.towerElements.tOne7,
                this.towerElements.tOne8,
                this.towerElements.tOne9,
                this.towerElements.tOne10,
                this.towerElements.bOne1,
                this.towerElements.bOne2,
                this.towerElements.bThree1,
                this.towerElements.cloud,
                this.towerElements.satelliteDish,
                this.towerElements.radioMast,
                this.towerElements.cShop1
            ];
            // this.runBackaulHoverAnimation();
            gsap.set(this.elements.homeDimmerRefs, { pixi  : { alpha : 0} })
           

            if(this.props.onInitialised){
                this.props.onInitialised()
            }
            // this.runStartupsAnimation();
		});
    }
    


    runRoute(grahpicObj){


        let localRoutes =  grahpicObj || this.controls.routes;
                
        let name = this.routeSequence.shift();
        this.routeSequence.push(name);
        let animatable = {...routePaths.paths[name]};
        animatable.name = name;
        animatable.progress  = 0;
        animatable.duration = animatable.length / 300;
        animatable.alpha = 1;
        this.routeAnimation = gsap.timeline({
            onUpdate : ()=>{
                let blue = PIXI.utils.rgb2hex([0/255, 249/255, 255/255]);
                if(localRoutes){
                    localRoutes.clear();
                }
                drawPath(animatable.path, localRoutes ,blue, animatable.width, 0, animatable.progress, animatable.reverse, this.app );

                //extra width for final leg path to cloud
                if(animatable.name=='main3' && animatable.progress >  0.798){
                    drawPath(animatable.path, localRoutes ,blue, animatable.width+2, 0.798, animatable.progress, animatable.reverse, this.app );
                }


            },
            onComplete : ()=>{
                if(localRoutes){
                    localRoutes.clear();
                }
                // console.log('completed');
                this.runRoute(grahpicObj);
            }
        })
        
        if(animatable.pings && animatable.pings.length){
            for(let i = 0; i < animatable.pings.length; i++){
                let t = animatable.pings[i];
                let end = (i==0 || i === animatable.pings.length-1) ;
                this.routeAnimation.add(()=>{
                    this.towerElements[t.tower].ping(end);
                }, animatable.duration * t.progress)
            }
        }
        this.routeAnimation.to(animatable, { duration : animatable.duration, progress : 1, ease : 'none' }, 0.01)
        this.routeAnimation.to(animatable, { duration : 0.5, alpha : 0, ease : 'none' })
      
        
    }

// { n : 'tOne1', params :  { mark : 'start' , side : 'both', size : "big" }},
    
// 
    runTowersAnimation(name){
        this.stopAnimation();
        let towers = towerSequences[name];
        if(towers && towers.length){
            this.towersAnimation = gsap.timeline({repeat :-1});
            towers.map((t, idx)=>{
                let ele = this.towerElements[t.n];
                if(ele){
                    this.towersAnimation.add(()=>{
                        ele.ping((t.params.mark))
                    }, 1 * idx);
                } else {
                    // console.log(`cant find element for ${t.n}`);
                }
            });
            this.towersAnimation.timeScale(2);
        }
    }
    
    runTowersHoverAnimation(name){
        let towers = towerSequences[name];
        if(towers && towers.length){
            this.towersAnimation = gsap.timeline({repeat :-1});
            towers.map((t, idx)=>{
                let ele = this.towerElements[t.n];
                if(ele){
                    this.towersAnimation.add(()=>{
                        ele.accessPing((t.params.mark && t.params.mark=='start'))
                    }, 0);
                } else {
                    // console.log(`cant find element for ${t.n}`);
                }
            });
            this.towersAnimation.to({}, {duration : 4});
            this.towersAnimation.timeScale(2);
        }
    }

    runBackaulHoverAnimation(name){

        let paths = routePaths.sequences.backhaulPing.slice();

        let animatables = [];
        


        paths.map((path)=>{
            let animatable = {...routePaths.paths[path]};
            animatable.progress  = 0;
            animatable.duration = animatable.length / 300;
            animatables.push(animatable);
        });


        this.routeAnimation = gsap.timeline({
            repeat : -1,
            onUpdate : ()=>{
                if(this.controls && this.controls.routes){
                    this.controls.routes.clear();
                }
                
                animatables.map((animatable)=>{
                    drawPath(animatable.path, this.controls.routes ,PIXI.utils.rgb2hex([0/255, 249/255, 255/255]), animatable.width, 0, animatable.progress, animatable.reverse );
                })
                
            },
            onComplete : ()=>{
                this.controls.routes.clear();
            }
        })

        this.routeAnimation.add(()=>{
            this.towerElements.sNode3.ping(true);
        },0)
        
      
        animatables.map((animatable)=>{
            this.routeAnimation.to(animatable, { duration :animatable.duration, progress : 1, ease : 'none' }, 0.01);
                if(animatable.pings && animatable.pings.length){
                    for(let i = 0; i < animatable.pings.length; i++){
                        let t = animatable.pings[i];                    
                        this.routeAnimation.add(()=>{
                            if(!this.towerElements[t.tower]){
                                // console.log('unable to find', t.tower)
                            }
                            this.towerElements[t.tower].ping();
                        }, animatable.duration * t.progress)
                    }
                }
        })
        this.routeAnimation.to(animatables, { duration : 0.5, width : 0, ease : 'none' })
        this.routeAnimation.to({}, {duration : 1});
    }


    
    dimmerHandler(ref, noDimmer, position){
        
        
        if(this.dimmerTexture){
            this.app.stage.removeChild(this.dimmerTexture);
           
        }

      


        const renderDimmer = (resourceName, settingOverides)=>{
            
            let settings = {
                width : this.app.renderer.width / ppi,
                height : this.app.renderer.height / ppi,
                x : 0,
                y : 0,
                ...settingOverides
            }
            this.app.stage.removeChild(this.dimmerTexture);

            this.dimmerTexture = new PIXI.Sprite(this.resources[resourceName].texture);
            this.dimmerTexture.width = settings.width;
            this.dimmerTexture.height = settings.height;
            this.dimmerTexture.x= settings.x;
            this.dimmerTexture.y = settings.y;
            this.app.stage.addChild(this.dimmerTexture);
        }
        
        
        switch(ref){
            case 'accessCrowdcell':
                return renderDimmer('accessCrowdcellHl')
            case 'accessWiFi':
                return renderDimmer('accessWiFiHl')
            case 'accessOpenCellular':
                return renderDimmer('accessOpenCellularHl')
            case 'accessOpenRAN':
                return renderDimmer('accessOpenRANHl')
            case 'accessOpenRAN5GNR':
                return renderDimmer('accessOpenRAN5GNRHl')
            case 'backhaulOpen':
                return renderDimmer('backhaulOpenHl')
            case 'accessFronthaul':
                return renderDimmer('accessFronthaulHl')
            case 'backhaulMicrowave':
                return renderDimmer('backhaulMicrowaveHl')
            case 'labs':
                return renderDimmer('labsHl')
            case 'core':
                return renderDimmer('coreHl')
            case 'startups':
                return renderDimmer('startupsHl')
            case 'backhaulMillimeter':
                return renderDimmer('backhaulMillimeterHl')
        }
        
        this.fallbackDimmerHandler(ref, noDimmer, position)
    }

    fallbackDimmerHandler(ref, noDimmer, position){

        let linePos = position || 0.5;

        if(highlights[ref]){
            this.elements.dimmer.clear();
            this.elements.startupsDimmer.clear();
            let width = 3;
            
            let localDimmer = this.elements.dimmer;
            if(ref=='startupsHighlight'){
                localDimmer = this.elements.startupsDimmer;
                width = 12;
            }
            if(ref=='labsTip'){
                width = 6;
            }
            
            if(!noDimmer){
                localDimmer.beginFill(PIXI.utils.rgb2hex([0/255, 0/255, 0/255]), 0.5);
                localDimmer.drawRect(0, 0, this.elements.app.renderer.width, this.elements.app.renderer.height);
                localDimmer.beginHole();
                highlights[ref].paths.map((path)=>{
                    drawPathHole(path, localDimmer, 0, 1 )
                })

                
                localDimmer.endHole();
                localDimmer.endFill();

                if(highlights[ref].postFill){
                    localDimmer.beginFill(PIXI.utils.rgb2hex([0/255, 0/255, 0/255]), 0.5);
                    highlights[ref].postFill.map((path)=>{
                        drawPathHole(path, localDimmer, 0, 1 )
                    })
                    localDimmer.endFill();
                }
                

                

                
            }
           
            if(highlights[ref].fills){
                highlights[ref].fills.map((path)=>{
                    drawSmoothPath( path, localDimmer ,PIXI.utils.rgb2hex([0/255, 249/255, 255/255]), width, 0, 1, true, linePos);
                })
            } else {
                highlights[ref].paths.map((path)=>{
                    drawSmoothPath( path, localDimmer ,PIXI.utils.rgb2hex([0/255, 249/255, 255/255]), width, 0, 1, true, linePos);
                })
            }

            if(highlights[ref].postFill){
                highlights[ref].postFill.map((path)=>{
                    drawSmoothPath( path, localDimmer ,PIXI.utils.rgb2hex([0/255, 249/255, 255/255]), width, 0, 1, true, linePos);
                })
            }
           
        } else {
            this.elements.dimmer.clear();
            this.elements.startupsDimmer.clear();
        }
        
    }
    

    stopAnimation(){
        if(this.routeAnimation){
            this.routeAnimation.pause();
            this.routeAnimation = null;
            if(this.controls && this.controls.routes){
                this.controls.routes.clear();
            }
        }
        if(this.towersAnimation){
            this.towersAnimation.pause();
            this.towersAnimation =null;
            
        }
        if(this.elements.dimmer){
            this.elements.dimmer.clear();
        }
        if(this.dimmerTexture){
            this.app.stage.removeChild(this.dimmerTexture);
        }

        Object.entries(this.elements.towerElements).forEach(([name, towerObj])=>{
            if(towerObj && towerObj.reset){
                towerObj.reset();
            }
        });




    }

    runAnimation(name){
        
        // this.runStartupsAnimation();
        // let ticker = PIXI.Ticker.shared;
        // this.app.ticker.add(this.runStartupsAnimation);
        // console.log('name', name);
        
        switch(name){
            case 'access':
                this.stopAnimation();
                this.runTowersAnimation('access');
                this.setState({ current : 'access' })
                break;
            case 'accessHover':
                this.stopAnimation();
                this.runTowersHoverAnimation('access');
                this.setState({ current : 'accessHover' })
                break;
            case 'backhaulHover':
                this.stopAnimation();
                this.runBackaulHoverAnimation();
                this.setState({ current : 'backhaulHover' })
                break;
            case 'backhaul':
                // console.log('rinning baclak');
                this.stopAnimation();
                this.routeSequence = routePaths.sequences.backhaul.slice();
                this.setState({ current : 'backhaul' })
                this.runRoute();
                break;
            case 'core':
                this.stopAnimation();
                this.routeSequence = routePaths.sequences.core.slice();
                this.setState({ current : 'core' })
                // drawPath(routePaths.paths.core1.path, this.elements.coreRoutes ,PIXI.utils.rgb2hex([0/255, 249/255, 255/255]),7, 0, 1 );
                this.runRoute(this.elements.coreRoutes);
                break;
            // case null:
            //     break;
            case 'coreHover':
            case 'startupsHover':
            case 'labsHover':
                if(this.state.current!=='main'){
                    this.stopAnimation();
                    this.routeSequence = routePaths.sequences.main.slice();
                    this.setState({ current : 'main' })
                    this.runRoute();
                }
                break;
            case 'labs':
                this.stopAnimation();
                this.routeSequence = routePaths.sequences.labs.slice();
                this.setState({ current : 'labs' })
                // drawPath(routePaths.paths.core1.path, this.elements.coreRoutes ,PIXI.utils.rgb2hex([0/255, 249/255, 255/255]),7, 0, 1 );
                this.runRoute(this.elements.labRoutes);
                break;
            default:
                // console.log('rinning main');
                if(this.state.current=='main')return;
                this.stopAnimation();
                this.routeSequence = routePaths.sequences.main.slice();
                this.setState({ current : 'main' })
                this.runRoute();
                break;
        }
    }

    
    componentWillReceiveProps(newProps){
        
        this.stopAnimation();

        // console.log('anim', newProps.anim, newProps.progress);
        
        this.props.onAnimationStatusChange(true);
        //Handle map animation switches
        let e = this.elements;
        let tl = gsap.timeline({ onComplete : ()=>{
            this.props.onAnimationStatusChange(false);
        } });

        switch(newProps.selectedState){

            case 'access':
                enterAccess(e, tl);
                this.runTowersAnimation('access')
                break;
            case 'backhaul':
                enterBackhaul(e, tl)
                this.runAnimation('backhaul')
                break;
            case 'startups':
                enterStartups(e, tl);
                break;
            case 'core':
                enterCore(e, tl);
                this.runAnimation('core')
                break;
            case 'labs':
                enterLabs(e, tl);
                this.runAnimation('labs')
                break;
            
            default:   
                //find correct exit
                switch(this.props.selectedState){
                    case 'access':
                        exitAccess(e, tl);
                        break;
                    case 'backhaul':
                        exitBackhaul(e, tl);
                        break;
                    case 'startups':
                        exitStartups(e, tl);
                        break;
                    case 'core':
                        exitCore(e, tl);
                        break;
                    case 'labs':
                        exitLabs(e, tl);
                        break;
                    default:
                        // console.error('no exit defined');
                        break;
                }
                break;
        }



        if(newProps.anim){
            let animatedRoutes = this.controls.routes;

            if( /^core/.test(newProps.anim)){
                animatedRoutes = this.elements.coreRoutes;
            }

            if( /^labs/.test(newProps.anim)){
                animatedRoutes = this.elements.labRoutes;
            }

            
            
            animatedRoutes.clear();
            let animatable = {...routePaths.paths[newProps.anim]};
            animatable.progress  = newProps.progress;
            animatable.duration = animatable.length / 500;
            drawPath(animatable.path, animatedRoutes , PIXI.utils.rgb2hex([0/255, 249/255, 255/255]), animatable.width, 0, animatable.progress );
        }
       

    }



	
	render() {
		return (
			<div class={style.Map} ref={(node)=>this.wrapper=node}>
			</div>
		);
	}
}

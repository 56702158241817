import * as PIXI from 'pixi.js';
import gsap from 'gsap';
import PixiPlugin from 'gsap/PixiPlugin';
import MotionPathPlugin from 'gsap/MotionPathPlugin'
gsap.registerPlugin(PixiPlugin, MotionPathPlugin);
window.PIXI = PIXI


const killTweens = (elements)=>{
    gsap.killTweensOf([
        elements.allModules,
        elements.access,
        elements.backhaul,
        elements.startups,
        elements.towers,
        elements.mapElements,
        elements.routeElements, 
        elements.routeElementsRear, 
        elements.routeHighlights,
        elements.coreTowers,
        elements.labsTowers,
        elements.allModulesMask
    ]);

    Object.entries(elements.towerElements).forEach(([name, towerObj])=>{
        if(towerObj && towerObj.reset){
            towerObj.reset();
        }
    });

}

export const enterAccess = (elements, timeline)=>{
    
    let towers = [

    ]

    killTweens(elements);
    timeline.to([
        elements.backhaul,
        elements.startups,
        elements.routeElements, 
        elements.routeElementsRear,
        elements.towers,
        elements.routeHighlights,
        elements.allModulesMask
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    .set(elements.network, { pixi : {alpha : 0}})
    .set(elements.accessNetwork, { pixi : {alpha : 1 }})
    .set(elements.accessDimmerRefs, { pixi : { alpha : 0.5 } })
    .set(elements.homeDimmerRefs, { pixi  : { alpha : 1} })
    .to(elements.allModules, { duration : 0.3, pixi : { alpha : 0.2 }}, 0)
    .to(elements.access, { duration : 1, pixi : { alpha : 1 }}, 0);
    timeline.to([elements.routeHighlights, elements.routeElements, elements.routeElementsRear, elements.towers], { duration : 1, pixi : { alpha : 1 }, delay : -0.2 });
    return timeline;
}




export const exitAccess = (elements, timeline)=>{
    
    killTweens(elements);

    timeline.to([
        elements.backhaul,
        elements.access,
        elements.startups,
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    timeline.to([
        elements.routeElements, 
        elements.routeElementsRear,
        elements.towers,
        elements.routeHighlights,
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    .set(elements.network, { pixi : {alpha : 1}})
    .set(elements.accessNetwork, { pixi : {alpha : 0 }})
    .set(elements.accessDimmerRefs, { pixi : { alpha : 1 } })
    .set(elements.homeDimmerRefs, { pixi  : { alpha : 0} })
    timeline.to(elements.allModules, { duration : 0.7, pixi : { alpha : 1,  ease : 'power3.out' }, delay : -0.5})
    timeline.to([ elements.routeElements, elements.routeElementsRear,elements.routeHighlights, elements.towers, elements.allModulesMask], { duration : 1, pixi : { alpha : 1 }, delay : -0.2 })
    timeline.timeScale(1);
    return timeline;
}


export const enterBackhaul = (elements, timeline)=>{
    
    killTweens(elements);

    timeline.to([
        elements.access,
        elements.startups,
        elements.routeElements, 
        elements.routeElementsRear,
        elements.towers,
        elements.routeHighlights,
        elements.allModulesMask,
        
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    .set(elements.network, { pixi : {alpha : 0}})
    .set(elements.backhaulNetwork, { pixi : {alpha : 1 }})
    .set(elements.backhaulDimmerRefs, { pixi : { alpha : 0.4 } })
    .set(elements.homeDimmerRefs, { pixi  : { alpha : 0} })
    .to(elements.allModules, { duration : 0.3, pixi : { alpha : 0.2 }}, 0)
    .to(elements.backhaul, { duration : 1, pixi : { alpha : 1 }}, 0);
    timeline.to([elements.routeHighlights, elements.routeElements, elements.routeElementsRear,elements.towers], { duration : 1, pixi : { alpha : 1 }, delay : -0.2 })
    return timeline;
}


export const exitBackhaul = (elements, timeline)=>{
    
    killTweens(elements);

    timeline.to([
        elements.backhaul,
        elements.access,
        elements.startups,
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    timeline.to([
        elements.routeElements, 
        elements.routeElementsRear,
        elements.routeHighlights,
        elements.towers,
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    .set(elements.network, { pixi : {alpha : 1}})
    .set(elements.backhaulNetwork, { pixi : {alpha : 0 }})
    .set(elements.backhaulDimmerRefs, { pixi : { alpha : 1} })
    .set(elements.homeDimmerRefs, { pixi  : { alpha : 0} })
    timeline.to(elements.allModules, { duration : 0.7, pixi : { alpha : 1 , ease : 'power3.out' }, delay : -0.5})
    timeline.to([ elements.routeElements, elements.routeElementsRear, elements.routeHighlights, elements.towers, elements.allModulesMask], { duration : 1, pixi : { alpha : 1 }, delay : -0.2 })
    timeline.timeScale(1);
    return timeline;
}


export const enterStartups = (elements, timeline)=>{

    killTweens(elements);

    let scale = 1660 / 447;
    timeline.to([
        elements.routeElements, 
        elements.routeElementsRear,
        elements.towers,
        elements.routeHighlights,
        elements.allModulesMask,
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    timeline.to([
        elements.access,
        elements.backhaul,
        elements.startups,
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    .to(elements.allModules, { duration : 0.3, pixi : { alpha : 0.1 }}, 0)
    .to([
        elements.mapElements,
        elements.routeElements, elements.routeElementsRear,
        elements.routeHighlights, 
        elements.towers,
        elements.startupsTowers
    ], {  duration :1, pixi : { scale : scale, x : (-1214)*(scale) , y : (-297)*(scale) }, ease : 'power3.in' }, 0)
    .to([elements.startups, elements.startupsBubbles], { duration : 0.3, pixi : { alpha : 1 } })
    .timeScale(2);
    return timeline;
}


export const exitStartups = (elements, timeline)=>{

    killTweens(elements);
    
    timeline.to([
        elements.backhaul,
        elements.access,
        elements.startups,
        elements.startupsBubbles
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    timeline.to([
        elements.routeElements, 
        elements.routeElementsRear,
        elements.towers,
        elements.routeHighlights,
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    timeline.to(elements.allModules, { duration : 0.3, pixi : { alpha : 0.1}}, 0)
    timeline.to([
        elements.mapElements,
        elements.routeElements, 
        elements.routeElementsRear, 
        elements.routeHighlights,
        elements.startupsTowers
    ], {  duration :1.2, pixi : { scale : 1, x : 0 , y : 0 }, ease : 'power3.inOut' }, 0) 

    timeline.to([
        elements.towers,
    ], {  duration :1.2, pixi : { scale : 1, x : 0 , y : 0 }, ease : 'power3.inOut' }, 0) 
    



    
    timeline.to(elements.allModules, { duration : 0.7, pixi : { alpha : 1,  ease : 'power3.out' }, delay : -0.5})
    timeline.to([elements.routeHighlights, elements.routeElements, elements.routeElementsRear ,elements.towers, elements.allModulesMask], { duration : 1, pixi : { alpha : 1 }, delay : -0.2 })
    timeline.timeScale(2);
    return timeline;
}



export const enterCore = (elements, timeline)=>{

    killTweens(elements);
    let scale = 1660 / 510;
    timeline.to(elements.gradientFadeR, { duration : 1.2, pixi : { alpha : 1 } }, 0)

    timeline.to([
        elements.routeElements, 
        elements.routeElementsRear,
        elements.towers,
        elements.routeHighlights,
        elements.coreTowers,
        elements.allModulesMask
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    timeline.to([
        elements.access,
        elements.backhaul,
        elements.core,
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    .to(elements.allModules, { duration : 0.3, pixi : { alpha : 0.1 }}, 0)
    .to([
        elements.mapElements,
        elements.routeElements, elements.routeElementsRear,
        elements.routeHighlights, 
        elements.towers,
        elements.coreTowers
    ], {  duration :1, pixi : { scale : scale, x : -924*scale , y : -460*scale }, ease : 'power3.in' }, 0)
    .to([
        elements.core,
        elements.coreTowers,
        elements.gradientFadeR
    ], { duration : 0.3, pixi : { alpha : 1 } })
    .timeScale(2)
    return timeline;
}


export const exitCore = (elements, timeline)=>{

    killTweens(elements);

    timeline.to(elements.gradientFadeR, { duration : 1.2, pixi : { alpha : 0 } }, 0)
    
    timeline.to([
        elements.backhaul,
        elements.access,
        elements.core,
        elements.coreTowers,
        
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    timeline.to([
        elements.routeElements, 
        elements.routeElementsRear,
        elements.towers,
        elements.routeHighlights,
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    timeline.to(elements.allModules, { duration : 0.3, pixi : { alpha : 0.1 }}, 0)
    timeline.to([
        elements.mapElements,
        elements.routeElements, 
        elements.routeElementsRear, 
        elements.towers,
        elements.routeHighlights,
    ], {  duration :1.2, pixi : { scale : 1, x : 0 , y : 0 }, ease : 'power3.inOut' }, 0)
    
    timeline.to(elements.allModules, { duration : 0.7, pixi : { alpha : 1, ease : 'power3.out' }, delay : -0.5})
    timeline.to([elements.routeHighlights, elements.routeElements, elements.routeElementsRear ,elements.towers, elements.allModulesMask], { duration : 1, pixi : { alpha : 1 }, delay : -0.2 })
    timeline.timeScale(2);
    return timeline;
}



export const enterLabs = (elements, timeline)=>{

    let scale = 1660/445;
    killTweens(elements);
    // console.log('elements', elements.labs);
    timeline.to([
        elements.routeElements, 
        elements.routeElementsRear,
        elements.towers,
        elements.routeHighlights,
        elements.labsTowers,
        elements.labsTowersRear,
        elements.allModulesMask
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    timeline.to([
        elements.access,
        elements.backhaul,
        elements.labs,
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    .to(elements.allModules, { duration : 0.3, pixi : { alpha : 0.1, blur : 3 }}, 0)
    .to([
        elements.mapElements,
        elements.routeElements, elements.routeElementsRear,
        elements.routeHighlights, 
        elements.towers,
        elements.labsTowers,
        elements.labsTowersRear,
        elements.labsTowersFore,
    ], {  duration :1, pixi : { scale : scale, x : -1245*scale , y : -224*scale }, ease : 'power3.in' }, 0)
    .to([
        elements.labs,
        elements.labsTowers,
        elements.labsTowersRear,
        elements.labsTowersFore,
    ], { duration : 0.3, pixi : { alpha : 1 } })
    .timeScale(2);
    return timeline;
}


export const exitLabs = (elements, timeline)=>{

    killTweens(elements);

    
    timeline.to([
        elements.backhaul,
        elements.access,
        elements.labsTowers,
        elements.labsTowersRear,
        elements.labsTowersFore,
        elements.labs
    ], { duration : 0.2, pixi : { alpha : 0, blur : 0 }}, 0)
  
    timeline.to([
        elements.routeElements, 
        elements.routeElementsRear,
        elements.towers,
        elements.routeHighlights,
    ], { duration : 0.3, pixi : { alpha : 0 }}, 0)
    timeline.to(elements.allModules, { duration : 0.3, pixi : { alpha : 0.1, blur : 3 }}, 0)
    timeline.to([
        elements.mapElements,
        elements.routeElements, 
        elements.routeElementsRear, 
        elements.towers,
        elements.routeHighlights,
        elements.labsTowersRear,
        elements.labsTowersFore,
        
    ], {  duration :1.2, pixi : { scale : 1, x : 0 , y : 0 }, ease : 'power3.inOut' }, 0)
    
    timeline.to(elements.allModules, { duration : 0.7, pixi : { alpha : 1, blur : 0, ease : 'power3.out' }, delay : -0.5})
    timeline.to([elements.routeHighlights, elements.routeElements, elements.routeElementsRear ,elements.towers, elements.allModulesMask], { duration : 1, pixi : { alpha : 1 }, delay : -0.2 })
    timeline.timeScale(2)
    return timeline;
}
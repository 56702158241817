import { h, Component } from 'preact';
import style from './MobileMap.css';


import * as PIXI from 'pixi.js-legacy';

import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin'
import PixiPlugin from 'gsap/PixiPlugin';

import {drawPath, createElement, drawPathHole, drawSmoothPath} from './routeHelpers';
import { routePaths } from '../data';

window.PIXI = PIXI;

gsap.registerPlugin(PixiPlugin, MotionPathPlugin);
let ppi = window.devicePixelRatio || 1;
if(ppi > 2){ ppi = 2 };
// ppi = 1;



export default class MobileInteractive extends Component {
	
	constructor(){
        super();
        this.runAnimation = this.runAnimation.bind(this);
		this.state = {
			
        }
        this.towerElements = {}
	}

	componentWillMount(){
	
    }
    


	componentDidMount(){
        const app = new PIXI.Application({
			width: 1620,
			height: 420,
            transparent : true,
            antialias : true,
            resolution : ppi,
            forceCanvas : false
        });

        this.app = app;
        app.renderer.plugins.interaction.autoPreventDefault = false;
        app.renderer.view.style.touchAction = 'auto';
        this.wrapper.appendChild(app.view)
        if(ppi==2){
            app.loader
            .add('background', '/wp-content/fb-interactive-25-sept-2023/assets/img/mobile-viewport-background@2x.png')
            .add('network', '/wp-content/fb-interactive-25-sept-2023/assets/img/mobile-viewport-network@2x.png')
            .add('routeMask', '/wp-content/fb-interactive-25-sept-2023/assets/img/mobile-map-mask.png')
        } else {
            app.loader
            .add('background', '/wp-content/fb-interactive-25-sept-2023/assets/img/mobile-viewport-background.png')
            .add('network', '/wp-content/fb-interactive-25-sept-2023/assets/img/mobile-viewport-network.png')
            .add('routeMask', '/wp-content/fb-interactive-25-sept-2023/assets/img/mobile-map-mask.png')
        }
        
        app.loader
        .add('networkSprites', '/wp-content/fb-interactive-25-sept-2023/assets/img/sprites/networkSprites.json')
        .add('zoomSprites', '/wp-content/fb-interactive-25-sept-2023/assets/img/sprites/zoomSectionSprites.json')
        
        
        app.loader.load((loader, resources) => {

            let sheet = resources['networkSprites'].spritesheet;
           
            const routeMask = new PIXI.Sprite(resources.routeMask.texture);
            routeMask.width = app.renderer.width / ppi;
            routeMask.height = app.renderer.height / ppi;


            const globalBg = new PIXI.Sprite(resources.background.texture);
            globalBg.width = app.renderer.width / ppi;
            globalBg.height = app.renderer.height / ppi;
            app.stage.addChild(globalBg);

            this.animatedRoutes =  new PIXI.Graphics();
            this.animatedRoutes.width = app.renderer.width / ppi;
            this.animatedRoutes.height = app.renderer.height / ppi;
            app.stage.addChild(this.animatedRoutes)
            gsap.set(this.animatedRoutes, { pixi : { blur : 2 }})
            this.animatedRoutes.mask = routeMask;
            
            const rearTowers = new PIXI.Container();
            app.stage.addChild(rearTowers);

            const network = new PIXI.Sprite(resources.network.texture);
            network.width = app.renderer.width / ppi;
            network.height = app.renderer.height / ppi;
            app.stage.addChild(network);
            network.mask=routeMask;
            
            const towers = new PIXI.Container();
            app.stage.addChild(towers);

            const startupsBubbles = new PIXI.Container();
            app.stage.addChild(startupsBubbles);
           

            this.towerElements.tSix1 = createElement(sheet, 'tower6', 143+2, 176+3);            
            towers.addChild(this.towerElements.tSix1);

          
            this.towerElements.tEight1 = createElement(sheet, 'tower8', 166+5, 260+2);            
            towers.addChild(this.towerElements.tEight1);

           

            this.towerElements.tSeven1 = createElement(sheet, 'tower7', 294+10, 219+2);            
            towers.addChild(this.towerElements.tSeven1);

           

            this.towerElements.tThree1 = createElement(sheet, 'tower3', 250+4, 331-5);            
            towers.addChild(this.towerElements.tThree1);

            
            this.towerElements.tSix2 = createElement(sheet, 'tower6', 299+8, 287-5);            
            towers.addChild(this.towerElements.tSix2);

            this.towerElements.sNode1 = createElement(sheet, 'sNode', 353+4, 206-3);            
            towers.addChild(this.towerElements.sNode1);

            this.towerElements.bOne1 = createElement(sheet, 'bOne', 368+3, 234);            
            towers.addChild(this.towerElements.bOne1);

            this.towerElements.tFive1 = createElement(sheet, 'tower5', 404+4, 141);            
            towers.addChild(this.towerElements.tFive1);

            this.towerElements.sNode2 = createElement(sheet, 'sNode', 488+2, 162-2);            
            towers.addChild(this.towerElements.sNode2);

            this.towerElements.sNode3 = createElement(sheet, 'sNode', 533, 280-5);            
            towers.addChild(this.towerElements.sNode3);

            this.towerElements.tOne1 = createElement(sheet, 'tower1', 488, 303-15);            
            towers.addChild(this.towerElements.tOne1);

            // setInterval(()=>{
            //     this.towerElements.tOne1.ping(true)
            // }, 1000)


            this.towerElements.tTen1 = createElement(sheet, 'tower10', 504, 216);            
            towers.addChild(this.towerElements.tTen1);
            
            this.towerElements.tOne2 = createElement(sheet, 'tower1', 594-3, 188);            
            towers.addChild(this.towerElements.tOne2);

            this.towerElements.stNode1 = createElement(sheet, 'stNode', 634, 229-2);            
            towers.addChild(this.towerElements.stNode1);

            this.towerElements.stNode2 = createElement(sheet, 'stNode', 704+2, 191-2);            
            towers.addChild(this.towerElements.stNode2);

            this.towerElements.stNode3 = createElement(sheet, 'stNode', 816, 217-2);            
            towers.addChild(this.towerElements.stNode3);

            this.towerElements.cloud = createElement(sheet, 'cloud', 731, 366);            
            rearTowers.addChild(this.towerElements.cloud);

            this.towerElements.bThree1 = createElement(sheet, 'bThree', 813+5, 297-2);            
            towers.addChild(this.towerElements.bThree1);

            this.towerElements.tOne3 = createElement(sheet, 'tower1', 888, 318);            
            towers.addChild(this.towerElements.tOne3);

            this.towerElements.tNine1 = createElement(sheet, 'tower9', 819, 225);            
            towers.addChild(this.towerElements.tNine1);

            this.towerElements.tNine2 = createElement(sheet, 'tower9', 889, 265-2);            
            towers.addChild(this.towerElements.tNine2);

            this.towerElements.tNine3 = createElement(sheet, 'tower9', 968, 307-2);            
            towers.addChild(this.towerElements.tNine3);

            this.towerElements.tNine4 = createElement(sheet, 'tower9', 909, 177);            
            towers.addChild(this.towerElements.tNine4);
            
            this.towerElements.tNine5 = createElement(sheet, 'tower9', 976, 216);            
            towers.addChild(this.towerElements.tNine5);

            this.towerElements.tNine6 = createElement(sheet, 'tower9', 1054-5, 259);            
            towers.addChild(this.towerElements.tNine6);

            this.towerElements.tNine7 = createElement(sheet, 'tower9', 1121-4, 220);            
            towers.addChild(this.towerElements.tNine7);

            this.towerElements.stNode4 = createElement(sheet, 'stNode', 1141-2, 302-3);            
            towers.addChild(this.towerElements.stNode4);

            this.towerElements.sNode4 = createElement(sheet, 'sNode', 1263-10, 230);            
            towers.addChild(this.towerElements.sNode4);

            this.towerElements.radioMast = createElement(sheet, 'radioMast', 1078-2, 126+2);            
            towers.addChild(this.towerElements.radioMast);

            this.towerElements.tOne4 = createElement(sheet, 'tower1', 1273-10, 173-2);            
            towers.addChild(this.towerElements.tOne4);

            this.towerElements.tNine8 = createElement(sheet, 'tower9', 1310-5, 229);            
            towers.addChild(this.towerElements.tNine8);

            this.towerElements.tNine9 = createElement(sheet, 'tower9', 1376-5, 264-1);            
            towers.addChild(this.towerElements.tNine9);

            this.towerElements.tNine10 = createElement(sheet, 'tower9', 1439-8, 228-1);            
            towers.addChild(this.towerElements.tNine10);

            this.towerElements.tOne5 = createElement(sheet, 'tower1', 1462-5, 212);            
            towers.addChild(this.towerElements.tOne5);

            this.towerElements.bTwo1 = createElement(sheet, 'bTwo', 1450+15, 296+5);            
            towers.addChild(this.towerElements.bTwo1);
            

            const createBubble = (name, x, y)=>{
                const node = new PIXI.AnimatedSprite(resources.zoomSprites.spritesheet.animations[name])
                node.width = 100
                node.height = 100
                node.x = x
                node.y = y
                node.anchor.set(0.5)
                node.scale = 0;
                return node;
            }
            
            const bubble = (node)=>{
                return  gsap.timeline()
                .set(node, { pixi : { scale : 0 }})
                .add(()=>{node.gotoAndStop(0)}, 0)
                .to(node, { duration: 3, pixi : { scale : 0.2 }, ease : 'elastic.out'})
                .add(()=>{node.gotoAndStop(1)}, 4)
                .add(()=>{node.gotoAndStop(2)}, 5)
                .add(()=>{node.gotoAndStop(3)}, 6)
                .to(node, { duration: 1, pixi : { scale : 0 }, ease : 'power3.out'}, 8)
                .timeScale(3);
            }
            
            const startupsB1 = createBubble('bubble1', 1137-20, 222+10);
            startupsBubbles.addChild(startupsB1)
            const startupsB2 = createBubble('bubble2', 1109, 228);
            startupsBubbles.addChild(startupsB2)
            const startupsB3 = createBubble('bubble3', 1114, 256);
            startupsBubbles.addChild(startupsB3)
            const startupsB4 = createBubble('bubble4', 1154-10, 228);
            startupsBubbles.addChild(startupsB4)

            let startupsTl = gsap.timeline({repeat : -1})
            .add(bubble(startupsB1), 0)
            .add(bubble(startupsB3), 2)
            .add(bubble(startupsB4), 4)
            .add(bubble(startupsB2), 7)
            
            this.props.onInitialised();
            
            this.runAnimation();

            

            // if(this.props.anim){
                
                
            //     this.animatedRoutes.clear();
            //     let animatable = {...routePaths.paths[this.props.anim]};
            //     console.log(animatable);
            //     animatable.progress  = this.props.progress;
            //     animatable.duration = animatable.length / 500;
            //     console.log(animatable);
            //     drawPath(animatable.path, this.animatedRoutes , PIXI.utils.rgb2hex([0/255, 249/255, 255/255]), animatable.width, 0, animatable.progress );
            // }

        })
	}	

    shouldComponentUpdate(newProps){
       
        return false;
    }
    
    
    runAnimation(){
        let a1 = {...routePaths.paths['mobile1']};
        let a2 = {...routePaths.paths['mobile2']};
        let a3 = {...routePaths.paths['mobile3']};
        let a4 = {...routePaths.paths['mobile4']};
        let a5 = {...routePaths.paths['mobile5']};
        let a6 = {...routePaths.paths['mobile6']};
        let a7 = {...routePaths.paths['mobile7']};
     
        const makeAnimation = (anim)=>{
            anim.duration = anim.length / 300;
            anim.progress = 0;
            anim.width = 0;

            let tl = new gsap.timeline();
            tl.set(anim, { width : 7, progress : 0 })
            tl.to(anim, { duration : anim.length / 300, progress : 1, ease : 'none'});
            if(anim.pings && anim.pings.length){
                for(let i = 0; i < anim.pings.length; i++){
                    let t = anim.pings[i];
                    let end = (i==0 || i === anim.pings.length-1) ;
                    tl.add(()=>{
                        this.towerElements[t.tower].ping(end);
                    }, anim.duration * t.progress)
                }
            }
            tl.to(anim, { duration : 1, width : 0});
            return tl;
        }


        
        this.tl = gsap.timeline({
            onUpdate : ()=>{
                let blue = PIXI.utils.rgb2hex([0/255, 249/255, 255/255]);
                if(this.animatedRoutes){
                    this.animatedRoutes.clear();
                }
                drawPath(a1.path, this.animatedRoutes ,blue, a1.width, 0, a1.progress);
                drawPath(a2.path, this.animatedRoutes ,blue, a2.width, 0, a2.progress);
                drawPath(a3.path, this.animatedRoutes ,blue, a3.width, 0, a3.progress);
                drawPath(a4.path, this.animatedRoutes ,blue, a4.width, 0, a4.progress);
                drawPath(a5.path, this.animatedRoutes ,blue, a5.width, 0, a5.progress);
                drawPath(a6.path, this.animatedRoutes ,blue, a6.width, 0, a6.progress);
                drawPath(a7.path, this.animatedRoutes ,blue, a7.width, 0, a7.progress);
                
            },
            onComplete : ()=>{
           
            }, repeat : -1
        })
        .add(makeAnimation(a1), 0)
        .add(makeAnimation(a2), 1)
        .add(makeAnimation(a3), 2)
        .add(makeAnimation(a4), 1)
        .add(makeAnimation(a5), 0)
        .add(makeAnimation(a6), 1)
        .add(makeAnimation(a7), 2)

    }
   
	render() {
        


    
		return (
            <div ref={(node)=>this.wrapper=node} class={style.MapCenter}></div>
		);
	}
}

import { h, Component } from 'preact';
import style from './MobileMap.css';
import MobileMenu from './MobileMenu';


import gsap from 'gsap';
import { Draggable } from 'gsap/Draggable.js';
import { InertiaPlugin } from 'gsap/all';
import MobileInteractive from './MobileInteractive';

gsap.registerPlugin(Draggable, InertiaPlugin);


export default class MobileMap extends Component {
	
	constructor(){
        super();
        this.navigateTo = this.navigateTo.bind(this);
        this.setupDraggable = this.setupDraggable.bind(this);
		this.state = {
			currentStep : 0
		}
	}

	componentWillMount(){
	
	}

    
	componentDidMount(){

        this.setupDraggable();
        
        // setTimeout(() => {
        //     this.navigateTo(1);
        // }, 500);
        
	}	
    setupDraggable(){
        this.draggable = Draggable.create(this.wrapper, {
            type: "x",
            zIndexBoost:false,
            edgeResistance: 0.9,
            snap: (value)=>{
                let division = this.wrapper.clientWidth / 5;
                let factor = Math.abs(value) / division;
                
                //impose limits
                if(factor<0){ factor =0; }
                if(factor>4){ factor =4; }
                
                //keep track of current page
                this.setState({ currentStep : Math.round(factor) })

                return 0-Math.round(factor)* division;
            },
            inertia : true,
            bounds: this.global,
            onDragStart : ()=>{this.props.selectState()},
            minimumMovement : 20,
            allowNativeTouchScrolling: false
        });

    }
    navigateTo(step){

        let division = this.wrapper.clientWidth / 5;
        step = Math.abs(step) || 0;
        //impose limits
        if(step<0){ step =0; }
        if(step>4){ step =4; }
    
        //keep track of current page
        this.setState({ currentStep :step })
        gsap.to(this.wrapper, 0.3, { x : 0-Math.round(step)* division})
          
      }

    componentWillUnmount(){
        if(this.draggable && this.draggable.kill){
          this.draggable.kill();
        }
    }

	render() {

        const getLeftClasses = ()=>{
            let classes = [style.scrollerControlLeft];
            if(this.state.currentStep===0){
                classes.push(style.scrollerControlLeftOut);
            }
            if(this.props.selectedState){
                classes.push(style.scrollerControlLeftOut);
            }
            return classes.join(" ");
        }

        const getRightClasses = ()=>{
            let classes = [style.scrollerControlRight];
            if(this.state.currentStep>3){
                classes.push(style.scrollerControlRightOut);
            }

            if(this.props.selectedState){
                classes.push(style.scrollerControlRightOut);
            }
            return classes.join(" ");
        }
	
		return (
            
            <div class={style.MobileMap} ref={(node)=>{this.global=node}}>
                <div class={style.MobileMapContainer} ref={(node)=>this.wrapper = node}>
                    <MobileInteractive
                        selectState={this.props.selectState}
                        selectedState={this.props.selectedState} 
                        anim={this.props.anim}
                        progress={this.props.progress}
                        onInitialised={this.props.onInitialised}
                    ></MobileInteractive>
                    <MobileMenu
                         selectState={this.props.selectState}
                         selectedState={this.props.selectedState} 
                    ></MobileMenu>
                </div>
                <div class="jsx-1954982671 scrollerControls">
                    <div class={getLeftClasses()}
                        onClick={()=>{
                            if(this.state.currentStep>0){
                                this.navigateTo(this.state.currentStep-1);
                              }
                            }
                            }
                        >
                        <div class={style.scrollerControlLeftArrow}>
                            <svg viewbox="0 0 12 20">
                            <path d="M11.37,9.52,2.55.7a.7.7,0,0,0-1,0l-.95,1a.67.67,0,0,0,0,1L8,10,.63,17.38a.69.69,0,0,0,0,1l1,.95a.67.67,0,0,0,.49.2.65.65,0,0,0,.48-.2l8.82-8.81a.7.7,0,0,0,.2-.49A.67.67,0,0,0,11.37,9.52Z" fill="#fff"></path></svg>
                        </div>
                    </div>
                    <div class={getRightClasses()} 
                    
                        onClick={()=>{
                            if(this.state.currentStep<4){
                            this.navigateTo(this.state.currentStep+1);
                            }
                      }}>
                        <div class={style.scrollerControlRightArrow}>
                            <svg viewbox="0 0 12 20">
                            <path d="M11.37,9.52,2.55.7a.7.7,0,0,0-1,0l-.95,1a.67.67,0,0,0,0,1L8,10,.63,17.38a.69.69,0,0,0,0,1l1,.95a.67.67,0,0,0,.49.2.65.65,0,0,0,.48-.2l8.82-8.81a.7.7,0,0,0,.2-.49A.67.67,0,0,0,11.37,9.52Z" fill="#fff"></path></svg>
                        </div>
                    </div>
                </div>
            </div>


		);
	}
}
